import { createStackNavigator, TransitionPresets } from '@react-navigation/stack'
import React from 'react'
import { Dimensions } from 'react-native'
import Header from '../../components/Header'
import HeaderHome from '../../components/HeaderHome'
import GroupCarrierNavigationStackParamList from './GroupCarrierNavigation.model'
import CarrierGroupScreen from '../../../screens/Carrier/CarrierGroup'
import CarrierAddClientScreen from '../../../screens/Carrier/CarrierAddClient'
import CarrierAddProducerScreen from '../../../screens/Carrier/CarrierAddProducer'
import CarrierUpdateProducerScreen from '../../../screens/Carrier/CarrierUpdateProducer'
import CarrierUpdateClientScreen from '../../../screens/Carrier/CarrierUpdateClient'
import CarrierAddUserScreen from '../../../screens/Carrier/CarrierAddUser'
import CarrierGroupProducersScreen from '../../../screens/Carrier/CarrierGroupProducers'
import CarrierGroupClientsScreen from '../../../screens/Carrier/CarrierGroupClients'
import CarrierGroupAdminsScreen from '../../../screens/Carrier/CarrierGroupAdmins'
import CarrierAddAdminUserScreen from '../../../screens/Carrier/CarrierAddAdminUser'
import CarrierUpdateAdminUserScreen from '../../../screens/Carrier/CarrierUpdateAdminUser'
import CarrierUpdateUserScreen from '../../../screens/Carrier/CarrierUpdateUser'
import CarrierUpdateGroupScreen from '../../../screens/Carrier/CarrierUpdateGroup'
import CarrierGroupModeExplainerScreen from '../../../screens/Carrier/CarrierGroupModeExplainer'
import CarrierGroupCarriersScreen from '../../../screens/Carrier/CarrierGroupCarriers'
import CarrierAddCarrierScreen from '../../../screens/Carrier/CarrierAddCarrier'
import CarrierUpdateCarrierScreen from '../../../screens/Carrier/CarrierUpdateCarrier'
import CarrierUpdateGroupSettingsScreen from '../../../screens/Carrier/CarrierUpdateGroupSettings'
import CarrierGroupRequestsScreen from '../../../screens/Carrier/CarrierGroupRequests'

const Stack = createStackNavigator<GroupCarrierNavigationStackParamList>()

const GroupCarrierNavigation = () => {
  return (
    <Stack.Navigator
      initialRouteName="CarrierGroup"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="CarrierGroup"
        component={CarrierGroupScreen}
        options={{
          headerShown: true,
          header: () => <HeaderHome />,
          title: 'Groupe',
        }}
      />
      <Stack.Screen
        name="CarrierGroupRequests"
        component={CarrierGroupRequestsScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierGroup" />,
          title: 'Requêtes',
        }}
      />
      <Stack.Screen
        name="CarrierGroupProducers"
        component={CarrierGroupProducersScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierGroup" />,
          title: 'Producteurs',
        }}
      />
      <Stack.Screen
        name="CarrierGroupClients"
        component={CarrierGroupClientsScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierGroup" />,
          title: 'Clients',
        }}
      />
      <Stack.Screen
        name="CarrierGroupCarriers"
        component={CarrierGroupCarriersScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierGroup" />,
          title: 'Clients',
        }}
      />
      <Stack.Screen
        name="CarrierGroupAdmins"
        component={CarrierGroupAdminsScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierGroup" />,
          title: 'Admins',
        }}
      />
      <Stack.Screen
        name="CarrierAddClient"
        component={CarrierAddClientScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierGroup" />,
        }}
      />
      <Stack.Screen
        name="CarrierAddUser"
        component={CarrierAddUserScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierGroup" />,
          title: 'Ajouter un utilisateur',
        }}
      />
      <Stack.Screen
        name="CarrierAddAdminUser"
        component={CarrierAddAdminUserScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierGroupAdmins" />,
          title: 'Ajouter un utilisateur',
        }}
      />
      <Stack.Screen
        name="CarrierUpdateAdminUser"
        component={CarrierUpdateAdminUserScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierGroupAdmins" />,
          title: 'Modifier un utilisateur',
        }}
      />
      <Stack.Screen
        name="CarrierUpdateUser"
        component={CarrierUpdateUserScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierGroupAdmins" />,
          title: 'Modifier un utilisateur',
        }}
      />
      <Stack.Screen
        name="CarrierUpdateClient"
        component={CarrierUpdateClientScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierDashboard" />,
          title: 'Modifier un client',
        }}
      />
      <Stack.Screen
        name="CarrierAddProducer"
        component={CarrierAddProducerScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierDashboard" />,
          title: 'Ajouter un producteur',
        }}
      />
      <Stack.Screen
        name="CarrierUpdateProducer"
        component={CarrierUpdateProducerScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierDashboard" />,
          title: 'Modifier un gestionnaire',
        }}
      />
      <Stack.Screen
        name="CarrierAddCarrier"
        component={CarrierAddCarrierScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierDashboard" />,
          title: 'Ajouter un gestionnaire',
        }}
      />
      <Stack.Screen
        name="CarrierUpdateCarrier"
        component={CarrierUpdateCarrierScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierGroupCarriers" />,
          title: 'Modifier un producteur',
        }}
      />
      <Stack.Screen
        name="CarrierUpdateGroup"
        component={CarrierUpdateGroupScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierGroupAdmins" />,
          title: 'Modifier le groupe',
        }}
      />
      <Stack.Screen
        name="CarrierUpdateGroupSettings"
        component={CarrierUpdateGroupSettingsScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierGroupAdmins" />,
          title: 'Modifier les paramètres du groupe',
        }}
      />

      <Stack.Screen
        name="CarrierGroupModeExplainer"
        component={CarrierGroupModeExplainerScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierGroupAdmins" />,
          title: 'Les modes du groupe',
        }}
      />
    </Stack.Navigator>
  )
}

export default GroupCarrierNavigation
