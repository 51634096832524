import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useTheme } from 'styled-components/native'
import { Icon, Icons, Typography } from '../../../components'
import { ProducerStackParamList } from '../../ProducerNavigationStack/ProducerNavigationStack.model'
import { CarrierStackParamList } from '../../CarrierAppNavigationStack/CarrierNavigationStack.model'
import UserStackParamList from '../../ProducerNavigationStack/UserProducerNavigation/UserProducerConfigurationNavigation.model'
import {
  CenterContainer,
  HeaderTopWrapper,
  HeaderWrapper,
  LeftContainer,
  RightContainer,
  StyledTouchableOpacity,
} from './HeaderHome.styles'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { ClientStackParamList } from '../../ClientNavigationStack/ClientNavigationStack.model'
import { UserEnums } from '../../../../enums'
import DeviceUtil from '../../../utilities/utils/device'
import { queryClient } from '../../../utilities/queryClient'
import ProfileSwitcherCard from '../ProfileSwitcherCard'
import { AdminStackParamList } from '../../AdminNavigationStack/AdminNavigationStack.model'

const Header = () => {
  const navigation = useNavigation<StackNavigationProp<UserStackParamList>>()
  const navigationProducer = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const navigationCarrier = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const navigationClient = useNavigation<StackNavigationProp<ClientStackParamList>>()
  const navigationAdmin = useNavigation<StackNavigationProp<AdminStackParamList>>()

  const { viewType, authenticationData, changeViewType, getAccessInfos } =
    useAuthenticationContext()
  const access = authenticationData?.access
  const insets = useSafeAreaInsets()
  const theme = useTheme()

  const handlePressNotification = () => {
    // navigation.navigate('Notifications', {})
  }

  const handlePressProfile = () => {
    if (viewType === 10) {
      navigationAdmin.navigate('UserAdminConfigurationNav', {
        screen: 'UserMenu',
        params: {},
      })
    }
    if (viewType === 1) {
      navigationCarrier.navigate('UserCarrierConfigurationNav', {
        screen: 'UserMenu',
        params: {},
      })
    }
    if (viewType === 2) {
      navigationProducer.navigate('UserProducerConfigurationNav', {
        screen: 'UserMenu',
        params: {},
      })
    }
    if (viewType === 3) {
      navigationClient.navigate('UserClientConfigurationNav', {
        screen: 'UserMenu',
        params: {},
      })
    }
  }

  return (
    <>
      <HeaderWrapper>
        <HeaderTopWrapper isDesktop={!DeviceUtil.isMobileApp()} insets={insets}>
          <LeftContainer isDesktop={!DeviceUtil.isMobileApp()}>
            <ProfileSwitcherCard />
          </LeftContainer>
          <CenterContainer></CenterContainer>
          <RightContainer>
            <StyledTouchableOpacity onPress={handlePressNotification}>
              {/*
            <>
              <Icon
                type={Icons.Ionicons}
                name="notifications-outline"
                color={theme.colors['color-primary']}
                style={{ alignSelf: 'center' }}
              />
            </>
          */}
            </StyledTouchableOpacity>
            <StyledTouchableOpacity onPress={handlePressProfile}>
              <Icon
                type={Icons.Ionicons}
                name="ios-person"
                color={theme.colors['text-light-1']}
                style={{ alignSelf: 'center' }}
                size={22}
              />
              <Typography.Body colorName="text-light-1" style={{ alignSelf: 'center' }}>
                Mon compte
              </Typography.Body>
            </StyledTouchableOpacity>
          </RightContainer>
        </HeaderTopWrapper>
        {/* <HeaderBottomWrapper></HeaderBottomWrapper> */}
      </HeaderWrapper>
    </>
  )
}

export default Header
