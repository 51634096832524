import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { useTheme } from 'styled-components/native'
import { Icon, Icons, Spacer, TextInput, TouchableCard, Typography } from '../../../components'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import FormatUtils from '../../../utilities/utils/format'
import { TourClientCardProps } from './TourClientCard.model'
import {
  StyledCardContent,
  StyledCardWrapper,
  StyledLeftBox,
  StyledMiddleBox,
  StyledRankingBox,
  StyledRightBox,
} from './TourClientCard.styles'
import AccountUtil from '../../../utilities/utils/account'

const TourClientCard = ({
  stop,
  tour,
  changeRankingMode,
  onChangeRanking,
}: TourClientCardProps) => {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()

  const OnClickClient = () => {
    if (!changeRankingMode) {
      navigation.navigate('CarrierTourClient', { id: stop.id, tourId: tour.id })
    }
  }

  const client = stop.client

  const onChangeRankingPressed = (value: number) => {
    if (onChangeRanking) {
      onChangeRanking(stop, value)
    }
  }

  const [newRanking, setNewRanking] = useState<number | undefined>(stop.ranking)

  useEffect(() => {
    setNewRanking(stop.ranking)
  }, [stop])

  const onChangeRankingInput = (valueText: string) => {
    const valueNumber = parseInt(valueText)
    setNewRanking(valueNumber)
  }

  const onBlurInputRanking = () => {
    if (onChangeRanking && newRanking) {
      onChangeRanking(stop, 0, newRanking)
    }
    // setNewRanking(undefined)
  }

  return (
    <StyledCardWrapper onPress={OnClickClient} activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent>
          {changeRankingMode ? (
            <StyledRankingBox>
              {stop.ranking > 1 ? (
                <TouchableOpacity onPress={() => onChangeRankingPressed(-1)}>
                  <Icon
                    type={Icons.Ionicons}
                    name="chevron-up-outline"
                    size={theme.spacingUnit * 2}
                    color={theme.colors['text-dark-3']}
                  />
                </TouchableOpacity>
              ) : null}

              <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
              <TextInput
                value={(newRanking || '').toString()}
                label=""
                field="text"
                onChangeText={(text) => onChangeRankingInput(text)}
                onBlurCallBack={onBlurInputRanking}
                small
              />
              <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
              <TouchableOpacity onPress={() => onChangeRankingPressed(1)}>
                <Icon
                  type={Icons.Ionicons}
                  name="chevron-down-outline"
                  size={theme.spacingUnit * 2}
                  color={theme.colors['text-dark-3']}
                />
              </TouchableOpacity>
            </StyledRankingBox>
          ) : (
            <StyledRankingBox>
              <Typography.CardTitle>{stop.ranking}</Typography.CardTitle>
            </StyledRankingBox>
          )}
          <StyledLeftBox>
            <Typography.CardTitle ellipsis>{client.label}</Typography.CardTitle>
            <Typography.BodySmall colorName="text-dark-3">
              {client.deliveryAddress?.city || client.city}
            </Typography.BodySmall>
            {!AccountUtil.hasPaymentMethodAndLegalInfos(client.account).valid && (
              <View>
                <Spacer size={0.2} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.BodySmall colorName="color-danger" bold>
                  {AccountUtil.hasPaymentMethodAndLegalInfos(client.account).message}
                </Typography.BodySmall>
              </View>
            )}
            <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
            {stop.hasCartWithNoOrder ? (
              <Typography.Body colorName="color-danger" bold>
                {FormatUtils.pluralizeAmountLabel('panier', stop.nbCarts || 0)} -{' '}
                {FormatUtils.pluralizeAmountLabel('commande', stop.nbOrders || 0)}
              </Typography.Body>
            ) : (
              <Typography.Body
                bold
                colorName={
                  stop?.totalIncome && parseFloat(stop?.totalIncome) > 0
                    ? 'color-primary'
                    : 'text-dark-3'
                }
              >
                {FormatUtils.formatPrice(stop.totalIncome)}HT - {stop.nbCrates || '0'} colis
              </Typography.Body>
            )}
          </StyledLeftBox>
          <StyledMiddleBox>
            {stop?.totalIncome && parseFloat(stop?.totalIncome) > 0 ? (
              <Icon
                type={Icons.Ionicons}
                name="checkmark-circle"
                size={theme.spacingUnit * 3}
                color={theme.colors['color-primary']}
              />
            ) : undefined}
          </StyledMiddleBox>
          <StyledRightBox>
            <Icon
              type={Icons.Ionicons}
              name="chevron-forward-outline"
              size={theme.spacingUnit * 2}
              color={theme.colors['text-dark-3']}
            />
          </StyledRightBox>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default TourClientCard
