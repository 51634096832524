import { useNavigation, useRoute, ParamListBase } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { startOfMonth, endOfMonth, addMonths, parse, isValid, format } from 'date-fns'
import { useCallback } from 'react'

const DEFAULT_PAGE_SIZE = 10
const DEFAULT_PAGE = 1

interface BaseFilterParams {
  page?: number | string
  pageSize?: number | string
  mode?: number | string
  producer?: string
  client?: string
  start?: string
  end?: string
  type?: number | string
  status?: number | string
  search?: string
}

interface FilterParamList extends ParamListBase {
  [key: string]: BaseFilterParams | undefined
}

export default () => {
  const navigation = useNavigation<StackNavigationProp<FilterParamList>>()
  const route = useRoute()

  const getDefaultDates = useCallback(() => {
    const now = new Date()
    return {
      start: startOfMonth(now),
      end: endOfMonth(now),
    }
  }, [])

  const params = (route.params as BaseFilterParams) || {}

  const filters = {
    producer: params?.producer,
    client: params?.client,
    start: params?.start,
    end: params?.end,
    page: params?.page ? Number(params.page) : DEFAULT_PAGE,
    pageSize: params?.pageSize ? Number(params.pageSize) : DEFAULT_PAGE_SIZE,
    mode: params?.mode ? Number(params.mode) : 1,
    type: params?.type ? Number(params.type) : undefined,
    status: params?.status ? Number(params.status) : undefined,
    search: params?.search || '',
  }

  const setFilters = useCallback(
    (newFilters: Partial<BaseFilterParams>) => {
      const currentParams = route.params || {}
      const updatedFilters = {
        ...currentParams,
        ...newFilters,
      }
      const cleanFilters = Object.fromEntries(Object.entries(updatedFilters)) as BaseFilterParams

      navigation.setParams(cleanFilters)
    },
    [navigation, route.params],
  )

  const setPage = useCallback(
    (pageNumber: number) => {
      setFilters({ page: pageNumber })
    },
    [setFilters],
  )

  const setPageSize = useCallback(
    (pageSize: number) => {
      setFilters({ pageSize })
    },
    [setFilters],
  )

  const setSearch = useCallback(
    (search: string) => {
      setFilters({
        search,
        page: 1, // Reset to first page on new search
      })
    },
    [setFilters],
  )

  const setMode = useCallback(
    (mode: number) => {
      setFilters({
        mode: mode === 1 || mode === 2 ? mode : 1,
        page: 1,
      })
    },
    [setFilters],
  )

  /*
  const setDates = useCallback(
    (monthOffset?: number, exactDate?: Date, dateType?: 'start' | 'end') => {
      if (exactDate && dateType && isValid(exactDate)) {
        setFilters({
          [dateType]: format(exactDate, 'yyyy-MM-dd'),
          page: 1,
        })
      } else if (monthOffset !== undefined) {
        const currentStartDate = filters.start
          ? parse(filters.start, 'yyyy-MM-dd', new Date())
          : new Date()
        const newDate = addMonths(currentStartDate, monthOffset)
        const newStartDate = startOfMonth(newDate)
        const newEndDate = endOfMonth(newDate)

        setFilters({
          start: format(newStartDate, 'yyyy-MM-dd'),
          end: format(newEndDate, 'yyyy-MM-dd'),
          page: 1,
        })
      }
    },
    [setFilters, filters.start],
  )*/

  const setDates = useCallback(
    (monthOffset?: number, exactDate?: Date, dateType?: 'start' | 'end') => {
      if (exactDate && dateType && isValid(exactDate)) {
        const formattedDate = format(
          dateType === 'end' ? new Date(exactDate.setHours(23, 59, 59, 999)) : exactDate,
          "yyyy-MM-dd'T'HH:mm:ss", // Remplace l'espace par 'T'
        )

        setFilters({
          [dateType]: formattedDate,
          page: 1,
        })
      } else if (monthOffset !== undefined) {
        const currentStartDate = filters.start
          ? parse(filters.start, "yyyy-MM-dd'T'HH:mm:ss", new Date())
          : new Date()
        const newDate = addMonths(currentStartDate, monthOffset)
        const newStartDate = startOfMonth(newDate)
        const newEndDate = new Date(endOfMonth(newDate).setHours(23, 59, 59, 999))

        setFilters({
          start: format(newStartDate, "yyyy-MM-dd'T'HH:mm:ss"),
          end: format(newEndDate, "yyyy-MM-dd'T'HH:mm:ss"),
          page: 1,
        })
      }
    },
    [setFilters, filters.start],
  )

  const getDates = useCallback(() => {
    const defaultDates = getDefaultDates()

    if (!filters.start || !filters.end) {
      return defaultDates
    }

    const start = parse(filters.start, "yyyy-MM-dd'T'HH:mm:ss", new Date())
    const end = parse(filters.end, "yyyy-MM-dd'T'HH:mm:ss", new Date())

    if (isValid(start) && isValid(end)) {
      return { start, end }
    }

    return defaultDates
  }, [filters.start, filters.end, getDefaultDates])

  const setProducer = useCallback(
    (producerId: string | undefined) => {
      setFilters({
        producer: producerId,
        page: 1,
      })
    },
    [setFilters],
  )

  const setClient = useCallback(
    (clientId: string | undefined) => {
      setFilters({
        client: clientId,
        page: 1,
      })
    },
    [setFilters],
  )

  const resetClientProducerFilters = useCallback(() => {
    const { client, producer, ...restParams } = (route.params as BaseFilterParams) || {}

    setFilters({
      ...restParams,
      page: 1,
      producer: undefined,
      client: undefined,
    } as BaseFilterParams)
  }, [navigation, route.params, setFilters])

  return {
    filters,
    setFilters,
    resetClientProducerFilters,
    setDates,
    getDates,
    setPage,
    setMode,
    setClient,
    setProducer,
    setPageSize,
    setSearch,
    getDefaultDates,
  }
}
