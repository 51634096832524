import styled from 'styled-components/native'
import { css } from 'styled-components/native'

const StyledCardWrapper = styled.View`
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-bottom: ${({ theme }) => theme.spacingUnit}px;
  width: 100%;
  max-width: ${({ theme }) => theme.spacingUnit * 32}px;
  min-height: 318px;
`

const StyledCardContent = styled.View`
  margin: ${({ theme }) => theme.spacingUnit * 1.3}px;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 0.8}px;
`

const StyledImage = styled.Image`
  width: 100%;
  height: ${({ theme }) => theme.spacingUnit * 22}px;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  border: 1px solid #f3f3f3;
  background-color: ${({ theme }) => theme.colors['background-3']};
`

const StyledValuesWrapper = styled.View`
  flex-direction: row;
  align-items: flex-start;
  min-height: 40px;
`

const StyledValuesButtonWrapper = styled.View`
  flex-direction: row;
  align-items: center;
`

const StyledValueBox = styled.View`
  max-width: ${({ theme }) => theme.spacingUnit * 15}px;
  margin-right: ${({ theme }) => theme.spacingUnit * 0.3}px;
  justify-content: center;
`

const StyleLeftQuantityWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: ${({ theme }) => theme.spacingUnit * 3}px;
`

const StyledLabelWrapper = styled.View`
  width: 100%;
  height: 26px;
  margin-top: -25px;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacingUnit * 0.5}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 1}px;
  padding-bottom: ${({ theme }) => theme.spacingUnit * 1}px;
`

const StyledLabelDescriptionWrapper = styled.View<{ isOpacity?: boolean }>`
  width: 100%;
  height: ${({ theme }) => theme.spacingUnit * 21}px;
  margin-top: ${({ theme }) => -theme.spacingUnit * 21}px;
  align-items: flex-end;
  justify-content: flex-start;
  padding-left: ${({ theme }) => theme.spacingUnit * 1}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 1}px;
  padding-bottom: ${({ theme }) => theme.spacingUnit * 1}px;
  ${({ isOpacity }) =>
    isOpacity &&
    css`
      background-color: #ffffffd4;
    `}
`

const StyledProductLabel = styled.View`
  min-height: 17px;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacingUnit * 0.3}px;
  padding-left: ${({ theme }) => theme.spacingUnit * 1}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 1}px;
  background-color: ${({ theme }) => theme.colors['color-primary']};
  border-radius: ${({ theme }) => theme.borderRadius * 0.5}px;
`

const StyledButtonWrapper = styled.View`
  width: 100%;
  height: ${({ theme }) => theme.spacingUnit * 21}px;
  margin-top: ${({ theme }) => -theme.spacingUnit * 21}px;
  align-items: flex-end;
  justify-content: flex-start;
  padding-left: ${({ theme }) => theme.spacingUnit * 1}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 1}px;
  padding-bottom: ${({ theme }) => theme.spacingUnit * 1}px;
`

const StyledLabel = styled.View<{ color?: string }>`
  height: 17px;
  border-radius: ${({ theme }) => theme.borderRadius * 0.5}px;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color || '#284036'};
  padding: ${({ theme }) => theme.spacingUnit * 0.3}px;
  padding-left: ${({ theme }) => theme.spacingUnit * 1}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 1}px;
`

const StyledLabelDescription = styled.TouchableOpacity`
  padding-top: ${({ theme }) => theme.spacingUnit * 0.5}px;
  padding-bottom: ${({ theme }) => theme.spacingUnit * 0.5}px;
  padding-left: ${({ theme }) => theme.spacingUnit * 1}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 1}px;
  height: 20px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  background-color: #e6e6e6;
  opacity: 0.7;
`

const ImageWrapper = styled.TouchableOpacity`
  height: auto;
`

export {
  StyledCardWrapper,
  StyledCardContent,
  StyledImage,
  StyledValuesWrapper,
  StyledValuesButtonWrapper,
  StyledValueBox,
  StyleLeftQuantityWrapper,
  StyledLabelWrapper,
  StyledLabelDescriptionWrapper,
  StyledLabelDescription,
  StyledLabel,
  ImageWrapper,
  StyledButtonWrapper,
  StyledProductLabel,
}
