import {
  Button,
  Card,
  CardPageLink,
  Icon,
  Icons,
  SectionTitle,
  Spacer,
  TouchableCard,
  Typography,
} from '../../../../../components'
import { useQuery } from '@tanstack/react-query'
import { CartBatch, DeliveryNote, Pagination } from '../../../../../domain'
import useAuthenticationContext from '../../../../../utilities/hook/useAuthenticationContext'
import DeliveryNoteProducerService from '../../../../../services/producer/deliveryNote'
import { CarrierTourDocumentsProps } from './CarrierTourDocuments.model'
import CardListPaginated from '../../../../../components/CardListPaginated'
import TourDeliveryNoteCard from '../../../../../modules/DeliveryNote/TourDeliveryNoteCard'
import DirectusUtil from '../../../../../utilities/utils/directus'
import { useState } from 'react'
import DeviceUtil from '../../../../../utilities/utils/device'
import DeliveryNoteCarrierService from '../../../../../services/carrier/deliveryNote'
import { DeliveryNoteTypeEnum, ProducerBillingTypeEnum, TourEnums } from '../../../../../../enums'
import CardSettings from '../../../../../components/CardSettings'
import FormatUtils from '../../../../../utilities/utils/format'
import { TouchableOpacity, View } from 'react-native'
import { LeftBoxWrapper, StyledCardWrapper, CardContentWrapper } from './CarrierTourDocuments.style'
import { useTheme } from 'styled-components/native'
import { useNavigation } from '@react-navigation/native'
import { CarrierStackParamList } from '../../../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { StackNavigationProp } from '@react-navigation/stack'
import GeneralUtils from '../../../../../utilities/utils/general'
import DeliveryNoteCard from './components/DeliveryNoteCard'
import Loader from '../../../../../components/Loader'

const CarrierTourDocuments = ({ tour }: CarrierTourDocumentsProps) => {
  const { getAccessInfos } = useAuthenticationContext()
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()

  const {
    data: deliveryNotes,
    refetch: refetchDeliveryNotes,
    isInitialLoading: deliveryNotesLoading,
  } = useQuery<DeliveryNote[], Error>(
    ['sf_tour_delivery_notes', tour.id],
    () => DeliveryNoteCarrierService.getAllTour(tour.id, DeliveryNoteTypeEnum.PRODUCER_CLIENT),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: deliveryNotesOrders,
    refetch: refetchDeliveryNotesOrders,
    isInitialLoading: deliveryNotesOrdersLoading,
  } = useQuery<DeliveryNote[], Error>(
    ['sf_tour_delivery_notes_orders', tour.id],
    () => DeliveryNoteCarrierService.getAllTour(tour.id, DeliveryNoteTypeEnum.CARRIER_CLIENT),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: deliveryNotesProducers,
    refetch: refetchDeliveryNotesProducers,
    isInitialLoading: deliveryNotesProducersLoading,
  } = useQuery<DeliveryNote[], Error>(
    ['sf_tour_delivery_notes_producers', tour.id],
    () => DeliveryNoteCarrierService.getAllTour(tour.id, DeliveryNoteTypeEnum.PRODUCER_CARRIER),
    {
      keepPreviousData: true,
    },
  )

  const producersTour = tour.producerTour || []
  const carts = tour.carts || []

  const nbPossibleDeliveryNotes = () => {
    let nbDeliveryNotes = 0
    producersTour.map((producerTour) => {
      const producerGroup = producerTour.producer.producerGroups?.find(
        (producerGroup) => producerGroup?.group?.id === tour.group?.id,
      )

      if (!producerGroup?.billingType || producerGroup?.billingType === 1) {
        nbDeliveryNotes += producerTour.nbOrders || 0
      }
    })
    return nbDeliveryNotes
  }
  const nbPossibleDeliveryNotesOrders = () => {
    let nbDeliveryNotes = 0
    carts.map((cart) => {
      let hasProducerBillingType2 = false
      const cartBatchs = cart.cartBatchs || []
      cartBatchs.map((cartBatch: CartBatch) => {
        const producerGroup = cartBatch?.batch?.product?.producer?.producerGroups?.find(
          (producerGroup) => producerGroup?.group?.id === tour.group?.id,
        )
        if (producerGroup?.billingType === ProducerBillingTypeEnum.CARRIER) {
          hasProducerBillingType2 = true
        }
      })
      if (hasProducerBillingType2) {
        nbDeliveryNotes += 1
      }
    })
    return nbDeliveryNotes
  }

  const nbPossibleDeliveryNotesProducers = () => {
    let nbDeliveryNotes = 0
    producersTour.map((producerTour) => {
      const producerGroup = producerTour.producer.producerGroups?.find(
        (producerGroup) => producerGroup?.group?.id === tour.group?.id,
      )
      if (
        producerTour?.nbOrders &&
        producerGroup?.billingType === ProducerBillingTypeEnum.CARRIER
      ) {
        nbDeliveryNotes += 1
      }
    })
    return nbDeliveryNotes
  }

  if (deliveryNotesLoading || deliveryNotesOrdersLoading || deliveryNotesProducersLoading) {
    return <Loader isLoading pageLoading noHeader />
  }

  if (tour.close && !GeneralUtils.isDateBefore(new Date(tour.close), new Date())) {
    return (
      <>
        <Typography.BodySmall colorName="color-grey">
          Les bons de livraison sont disponibles uniquement lorsque les commandes sont fermées.
        </Typography.BodySmall>
      </>
    )
  }

  if (!tour.carts || tour.carts?.length < 1) {
    return (
      <>
        <Typography.BodySmall colorName="color-grey">
          Il n'y a pas de commande pour cette tournée, aucun bon de livraison à générer.
        </Typography.BodySmall>
      </>
    )
  }

  return (
    <>
      {nbPossibleDeliveryNotes() !== 0 && (deliveryNotes || []).length >= 0 && (
        <CardPageLink
          label={'Bons de commande'}
          icon="arrow-right"
          onClick={() =>
            navigation.navigate('CarrierTourDeliveryNoteStatements', {
              id: tour.id,
            })
          }
        />
      )}
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
      {nbPossibleDeliveryNotes() !== 0 && (deliveryNotes || []).length >= 0 && (
        <DeliveryNoteCard
          title={'BL Producteur <> Magasin'}
          nbPossibleDeliveryNotes={nbPossibleDeliveryNotes()}
          deliveryNotes={deliveryNotes || []}
          navigation={navigation}
          tourId={tour.id}
          deliveryNoteType={DeliveryNoteTypeEnum.PRODUCER_CLIENT}
        />
      )}
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />

      {(nbPossibleDeliveryNotesProducers() !== 0 || (deliveryNotesProducers || []).length > 0) &&
        (deliveryNotesProducers || []).length >= 0 && (
          <DeliveryNoteCard
            title={'BL Producteur <> Super Producteur'}
            nbPossibleDeliveryNotes={nbPossibleDeliveryNotesProducers()}
            deliveryNotes={deliveryNotesProducers || []}
            navigation={navigation}
            tourId={tour.id}
            deliveryNoteType={DeliveryNoteTypeEnum.PRODUCER_CARRIER}
          />
        )}
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
      {(nbPossibleDeliveryNotesOrders() !== 0 || (deliveryNotesOrders || []).length > 0) &&
        (deliveryNotesOrders || []).length >= 0 && (
          <DeliveryNoteCard
            title={`BL Super producteur <> ${FormatUtils.capitalize(
              FormatUtils.getLabelFromClientType(1),
            )}`}
            nbPossibleDeliveryNotes={nbPossibleDeliveryNotesOrders()}
            deliveryNotes={deliveryNotesOrders || []}
            navigation={navigation}
            tourId={tour.id}
            deliveryNoteType={DeliveryNoteTypeEnum.CARRIER_CLIENT}
          />
        )}
    </>
  )
}

export default CarrierTourDocuments
