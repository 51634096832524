import { createStackNavigator, TransitionPresets } from '@react-navigation/stack'
import React from 'react'
import { Dimensions } from 'react-native'
import UserMenu from '../../../screens/User'
import UserLoginSecurity from '../../../screens/User/screens/UserLoginSecurity'
import UserEmail from '../../../screens/User/screens/UserLoginSecurity/userEmail'
import UserNotifications from '../../../screens/User/screens/UserNotifications'
import UserProfile from '../../../screens/User/screens/UserProfile'
import Header from '../../components/Header'
import UserUpdatePassword from '../../../screens/User/screens/UserUpdatePassword'
import ProfileSwitcherScreen from '../../../screens/Authentification/ProfileSwitcher'
import UpdateAccountScreen from '../../../screens/User/screens/UserUpdateAccount'
import useDeepLinks from '../../../utilities/hook/useDeepLinks'
import { DeepLinkEnum } from '../../../utilities/context/deepLinkContext'

const Stack = createStackNavigator()

const UserAdminConfigurationNavigation = () => {
  useDeepLinks([DeepLinkEnum.NAVIGATION])
  return (
    <Stack.Navigator
      initialRouteName="UserMenu"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="UserMenu"
        component={UserMenu}
        options={{
          headerShown: true,
          header: () => <Header title="" noDrawer />,
        }}
      />
      <Stack.Screen
        name="ProfileSwitcher"
        component={ProfileSwitcherScreen}
        options={{
          gestureEnabled: false,
          headerShown: true,
          presentation: 'transparentModal',
          header: () => <Header title="" noDrawer />,
          animationEnabled: false,
          title: 'Choix du compte',
        }}
      />
      <Stack.Screen name="UserProfile" component={UserProfile} />
      <Stack.Screen
        name="UserLoginSecurity"
        component={UserLoginSecurity}
        options={{
          headerShown: true,
          header: () => <Header title="Informations de connexion" goBackFallback="UserMenu" />,
        }}
      />
      <Stack.Screen
        name="UserUpdatePassword"
        component={UserUpdatePassword}
        options={{
          headerShown: true,
          header: () => <Header title="" goBackFallback="UserMenu" />,
        }}
      />
      <Stack.Screen
        name="UserNotifications"
        component={UserNotifications}
        options={{
          headerShown: true,
          header: () => <Header title="Notifications Settings" goBackFallback="UserMenu" />,
        }}
      />

      <Stack.Screen
        name="UserEmail"
        component={UserEmail}
        options={{
          presentation: 'transparentModal',
          ...TransitionPresets.ModalPresentationIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => (
            <Header title="Edit email" goBackFallback="UserLoginSecurity" withCloseButton />
          ),
        }}
      />
    </Stack.Navigator>
  )
}

export default UserAdminConfigurationNavigation
