import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { Linking, View } from 'react-native'
import { useEffect, useState } from 'react'
import { Button, Card, PageTitle, SectionTitle, Spacer, Typography } from '../../../components'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import TotalSummaryCard from '../../../components/TotalSummaryCard'
import i18n from '../../../i18n'
import {
  StyledBottomWrapper,
  StyledButtonWrapper,
  StyledInfoCardWrapper,
  StyledPageTitleWrapper,
  StyledTopWrapper,
} from './CarrierDeliveryNoteStatementScreen.styles'
import Loader from '../../../components/Loader'
import CardSettings from '../../../components/CardSettings'
import DeviceUtil from '../../../utilities/utils/device'
import DirectusUtil from '../../../utilities/utils/directus'
import TourCarrierNavigationStackParamList from '../../../navigation/CarrierAppNavigationStack/TourCarrierNavigation/TourCarrierNavigation.model'
import TourDeliveryNoteCard from '../../../modules/DeliveryNote/TourDeliveryNoteCard'
import DeliveryNoteStatementService from '../../../services/delivery-note-statement'
import { StackNavigationProp } from '@react-navigation/stack'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'

const POLLING_INTERVAL = 2000
const MAX_RETRIES = 50

function openPdf(documentId: string, directusToken: string) {
  return Linking.openURL(DirectusUtil.getDocumentDownloadUrlFromId(documentId, directusToken))
}

function CarrierDeliveryNoteStatementScreen() {
  const [isSending, setIsSending] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [pdfJobId, setPdfJobId] = useState<string | null>(null)
  const [pollingCount, setPollingCount] = useState(0)

  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const route =
    useRoute<RouteProp<TourCarrierNavigationStackParamList, 'CarrierDeliveryNoteStatement'>>()
  const { deliveryNoteStatementId } = route?.params ?? {
    id: '',
  }

  const { data: deliveryNoteStatement, isInitialLoading: deliveryNoteStatementLoading } =
    DeliveryNoteStatementService.useGetOneDeliveryNoteStatement({
      deliveryNoteStatementId,
    }).query()

  const isMobileScreen = DeviceUtil.isMobileApp()

  const onSendInvoiceEmail = async () => {
    setIsSending(true)
    if (!getEmail()) {
      return
    }
    const email = getEmail()
    const emails = email !== undefined ? [email] : undefined
    try {
      await DeliveryNoteStatementService.sendDeliveryNoteStatementEmail(
        deliveryNoteStatementId,
        emails,
      )
      navigation.goBack()
    } catch (error) {
      console.error('error', error)
      setError("Une erreur s'est produite lors de l'envoi du relevé")
    }

    setIsSending(false)
  }

  let timeoutId: any = 0

  async function checkPdfStatus() {
    if (!pdfJobId || pollingCount >= MAX_RETRIES) {
      setIsDownloading(false)
      if (pollingCount >= MAX_RETRIES) {
        setError('La génération du PDF a pris trop de temps, veuillez réessayer.')
      }
      return
    }

    try {
      const response = await DeliveryNoteStatementService.getPdfStatus(pdfJobId)

      if (response.status === 204) {
        // PDF still generating, continue polling
        setPollingCount((prev) => prev + 1)
        timeoutId = setTimeout(checkPdfStatus, POLLING_INTERVAL)
      } else if (response.data?.documentId) {
        // PDF is ready, update invoice statement data to include new documentId
        DeliveryNoteStatementService.useGetOneDeliveryNoteStatement({
          deliveryNoteStatementId: deliveryNoteStatement?.id!,
        }).invalidate()
        resetPollingPdfStatus()
      }
    } catch (error) {
      console.error('Error checking PDF status:', error)
      setError("Une erreur s'est produite lors de la génération du PDF")
      resetPollingPdfStatus()
    }
  }

  function resetPollingPdfStatus() {
    setIsDownloading(false)
    setPdfJobId(null)
    setPollingCount(0)
    clearTimeout(timeoutId)
    timeoutId = 0
  }

  useEffect(() => {
    if (pdfJobId && timeoutId === 0) {
      checkPdfStatus()
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [pdfJobId])

  const onClickDownloadDeliveryNoteStatement = async (documentId?: string) => {
    if (documentId && deliveryNoteStatement) {
      return openPdf(documentId, deliveryNoteStatement.directusToken!)
    }

    setIsDownloading(true)
    setError(null)

    try {
      const { jobId } = await DeliveryNoteStatementService.startPdfGeneration(
        deliveryNoteStatement?.id!,
      )
      setPdfJobId(jobId)
      setPollingCount(0)
    } catch (error) {
      console.error('Error generating PDF:', error)
      setError("Une erreur s'est produite lors de la génération du PDF")
      setIsDownloading(false)
    }
  }

  const canSendEmail = () => {
    return getEmail()
  }

  const getEmail = () => {
    return (
      deliveryNoteStatement?.order?.cart?.client?.mail || deliveryNoteStatement?.recipient.email
    )
  }

  const getName = () => {
    return (
      deliveryNoteStatement?.order?.cart?.client?.label ||
      deliveryNoteStatement?.recipient.legalName
    )
  }

  const getTitle = () => {
    return `Bon de commande #${deliveryNoteStatement?.displayIdentifier}`
  }

  const getSubtitle = () => {
    return `livraison du ${FormatUtils.formatDate(
      deliveryNoteStatement?.deliveryDate.toString(),
      'Date',
    )}`
  }

  if (!deliveryNoteStatement) {
    return (
      <>
        <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
        <Typography.BodySmall colorName="text-dark-1" align="center">
          Bon de commande introuvable
        </Typography.BodySmall>
      </>
    )
  }

  if (deliveryNoteStatementLoading) {
    return (
      <>
        <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
        <Loader />
      </>
    )
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <StyledTopWrapper>
          <StyledPageTitleWrapper>
            <PageTitle title={getTitle()} noFixedWidth noMarginLeft noMarginBottom />
          </StyledPageTitleWrapper>
          <StyledButtonWrapper isWrapped={isMobileScreen}>
            <Button
              label={
                deliveryNoteStatement.documentId
                  ? FormatUtils.capitalize(i18n.t('download'))
                  : 'Générer'
              }
              onPress={() =>
                onClickDownloadDeliveryNoteStatement(deliveryNoteStatement?.documentId)
              }
              loading={isDownloading}
              small
            />
          </StyledButtonWrapper>
        </StyledTopWrapper>
        <Typography.BodyExtraSmall colorName="text-dark-3" bold>
          {getSubtitle()}
        </Typography.BodyExtraSmall>
        <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
        <StyledInfoCardWrapper>
          <View style={{ width: '45%' }}>
            <Card>
              <Typography.BodySmall colorName="text-dark-1" bold>
                Émetteur
              </Typography.BodySmall>
              <Typography.BodySmall colorName="text-dark-1" ellipsis>
                Harvy
              </Typography.BodySmall>
            </Card>
          </View>
          <View style={{ width: '45%' }}>
            <Card>
              <Typography.BodySmall colorName="text-dark-1" bold>
                Destinataire
              </Typography.BodySmall>
              <Typography.BodySmall colorName="text-dark-1" ellipsis>
                {deliveryNoteStatement.recipient.legalName}
              </Typography.BodySmall>
            </Card>
          </View>
        </StyledInfoCardWrapper>
        <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
        <SectionTitle title="Bons de livraison dans ce relevé" />
        {(deliveryNoteStatement.deliveryNotes || []).map((deliveryNote) => (
          <TourDeliveryNoteCard
            key={deliveryNote?.id}
            deliveryNote={deliveryNote}
            issuer={deliveryNote.issuer}
            recipient={deliveryNote.recipient}
            onClick={() => true}
            isCarrier
          />
        ))}

        <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />

        <TotalSummaryCard items={deliveryNoteStatement.priceItems} />

        <Spacer size={5} axis={Spacer.AxisEnum.VERTICAL} />
        <CardSettings
          title="Envoyer le relevé par email"
          descriptionColor={deliveryNoteStatement.recipient.email ? undefined : 'color-warning'}
          description={
            deliveryNoteStatement.recipient.email
              ? `Ce relevé ${
                  canSendEmail() ? 'sera' : 'a été'
                } envoyé à ${getName()} (${getEmail()}).`
              : `L'email du destinaire n'est pas renseigné`
          }
        >
          <Button
            label="Envoyer"
            colorName="color-grey"
            onPress={() => onSendInvoiceEmail()}
            loading={isSending}
            disabled={!canSendEmail()}
          />
        </CardSettings>

        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        <CardSettings
          title="Relevé de bons de livraison"
          description={'Télécharger le bon de commande avec tous les bons de livraison'}
        >
          <Button
            label={
              deliveryNoteStatement?.documentIdWithDn
                ? FormatUtils.capitalize(i18n.t('download'))
                : 'Générer'
            }
            onPress={() =>
              onClickDownloadDeliveryNoteStatement(deliveryNoteStatement?.documentIdWithDn)
            }
            loading={isDownloading}
            small
          />
        </CardSettings>

        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        {error && (
          <Typography.BodySmall colorName="color-danger" align="center">
            {error}
          </Typography.BodySmall>
        )}
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierDeliveryNoteStatementScreen
