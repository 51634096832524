import { PlatformFeesRules } from '../../domain'
import { Fees } from '../../domain/Fees'
import { Invoice, InvoiceType } from '../../domain/Invoice'
import FormatUtils from './format'

const getProducerFees = (
  platformFeesRules?: PlatformFeesRules,
  price?: any,
  producerId?: string,
): number | undefined => {
  if (!platformFeesRules || !price || !producerId) return undefined
  let priceFormatted = price
  if (typeof price === 'string') {
    priceFormatted = FormatUtils.stringToFloat(price)
  }

  let priceRules = platformFeesRules.priceRules
  const foundProducerRules = platformFeesRules.producerRules.find((pr) => pr.id === producerId)

  if (foundProducerRules) {
    priceRules = foundProducerRules.producerPriceRules
  }

  let feesPercentage = undefined
  priceRules.map((priceRule) => {
    if (priceFormatted >= priceRule.min && priceFormatted <= priceRule.max) {
      feesPercentage = priceRule.fees || undefined
    }
  })

  if (typeof feesPercentage === 'string' && feesPercentage !== undefined) {
    feesPercentage = FormatUtils.stringToFloat(feesPercentage)
  }
  return feesPercentage
}

// Helper function to generate fee labels
const getFeeLabel = (type: number, producerInvoices?: Invoice[]) => {
  const carrierLabel = producerInvoices?.find((invoice) => invoice.type === InvoiceType.CARRIER_FEE)
    ?.issuer.legalName

  switch (type) {
    case 1:
      return `Frais de logistique ${carrierLabel || ''}`
    case 2:
      return 'Frais de plateforme Harvy'
    case 3:
      return `Frais commerciaux ${carrierLabel || ''}`
    default:
      return ''
  }
}

// Helper function to find fees by type
const getFeesByType = (type: number, producerFeesDetails?: Fees[], extraIndex?: number): Fees => {
  if (extraIndex) {
    const results = producerFeesDetails?.filter((fee) => fee.type === type)
    if (results && results[extraIndex]) {
      return results[extraIndex]
    }
    return {
      type,
      amount: 0,
      totalVAT: 0,
      rate: 0,
      sales: 0,
    }
  }
  return (
    producerFeesDetails?.find((fee) => fee.type === type) || {
      type,
      amount: 0,
      totalVAT: 0,
      rate: 0,
      sales: 0,
    }
  )
}

const FeesUtil = {
  getProducerFees,
  getFeeLabel,
  getFeesByType,
}

export default FeesUtil
