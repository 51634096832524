import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const StyledCardWrapper = styled.TouchableOpacity`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacingUnit}px;
`

const StyledCardContent = styled.View`
  margin: ${({ theme }) => theme.spacingUnit * 2}px;
  margin-top: ${({ theme }) => theme.spacingUnit * 1.2}px;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 1.2}px;
  flex-direction: row;
`

const StyledRankingBox = styled.View`
  justify-content: center;
  flex: 1;
  align-items: center;
`

const StyledLeftBox = styled.View`
  flex: 6;
  padding-left: ${({ theme }) => theme.spacingUnit * 1}px;
`
const StyledMiddleBox = styled.View`
  flex: 1;
  align-items: flex-end;
  justify-content: center;
`

const StyledRightBox = styled.View`
  flex: 0.5;
  align-items: flex-end;
  justify-content: center;
`

export {
  StyledCardWrapper,
  StyledCardContent,
  StyledRankingBox,
  StyledLeftBox,
  StyledMiddleBox,
  StyledRightBox,
}
