import styled from 'styled-components/native'

const BatchContentWrapper = styled.View`
  flex: 1;
  width: 92%;
  align-items: flex-start;
  margin: ${({ theme }) => theme.spacingUnit * 2}px;
`

const StyledButtonWrapper = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: row;
`
const StyledModeSwitchWrapper = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const StyledCardWrapper = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
`

const StyledCardContent = styled.View`
  width: 100%;
  align-items: center;
  text-align: center;
  // justify-content: center;
  min-height: ${({ theme }) => theme.inputHeight * 0.8}px;
`

const StyledGainBox = styled.View`
  padding: ${({ theme }) => theme.spacingUnit * 0.4}px;
  padding-left: ${({ theme }) => theme.spacingUnit * 0.7}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 0.7}px;
  background-color: ${({ theme }) => theme.colors['color-secondary']};
  border-radius: ${({ theme }) => theme.borderRadius}px;
`

export {
  BatchContentWrapper,
  StyledButtonWrapper,
  StyledModeSwitchWrapper,
  StyledCardWrapper,
  StyledCardContent,
  StyledGainBox,
}
