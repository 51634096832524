import { useFocusEffect, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useEffect, useState } from 'react'
import { InteractionManager, Linking } from 'react-native'
import { Button, PageTitle, Spacer, TextInput, Typography } from '../../../../components'
import useAuthenticationContext from '../../../../utilities/hook/useAuthenticationContext'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../../utilities/styling/wrappers'
import HelpFooter from '../../../../components/HelpFooter'
import { StyledImage, StyledLogoBox } from './Login.styles'
import StorageUtil from '../../../../utilities/storage/storage'
import PasswordForgottenButton from '../components/PasswordForgottenButton'
import * as Network from 'expo-network'
import useDeepLinks from '../../../../utilities/hook/useDeepLinks'
import { PublicStackParamList } from '../../../../navigation/PublicNavigationStack/PublicNavigationStack.model'
import DirectusUtil from '../../../../utilities/utils/directus'

function LoginScreen() {
  const navigation = useNavigation<StackNavigationProp<PublicStackParamList>>()
  const { deepLinksState } = useDeepLinks()

  const {
    login,
    resetErrorMessage,
    authenticationErrorMessage,
    authenticationLoading,
    isUserAuthenticated,
    viewType,
  } = useAuthenticationContext()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isNetworkStatusOnline, setIsNetworkStatusOnline] = useState<boolean>(true)
  const [networkCheks, setNetworkCheks] = useState<number>(0)

  useEffect(() => {
    if (isUserAuthenticated && !authenticationLoading) {
      navigation.navigate('Authentication', {
        screen: 'ProfileSwitcher',
        params: {},
      })
    }
  }, [isUserAuthenticated, viewType, authenticationLoading])

  useFocusEffect(
    React.useCallback(() => {
      // wait for the screen and navigation to be rendered
      const task = InteractionManager.runAfterInteractions(() => {
        ;(async () => {
          // await SplashScreen.hideAsync()
        })()
      })

      return () => {
        task.cancel()
        resetErrorMessage()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  )

  useEffect(() => {
    const getStoredEmail = async () => {
      const storedEmail = await StorageUtil.getItem('login_mail')
      if (storedEmail && !email) {
        setEmail(JSON.parse(storedEmail))
      }
    }
    getStoredEmail()
    checkNetworkStatus()
  }, [])

  const onLoginPressed = async () => {
    if (!email || !password) {
      setErrorMessage('Veuillez renseigner un email et un mot de passe')
      return
    }
    setErrorMessage('')
    const loginRes = await login(email, password, true).catch((e) => {
      setErrorMessage('Email ou mot de passe invalide')
      return
    })
    if (!loginRes) {
      checkNetworkStatus()
      setErrorMessage('Email ou mot de passe invalide')
      return
    }
    setEmail('')
    setPassword('')

    if (deepLinksState && deepLinksState[0]) {
      // navigation.navigate(deepLinksState[0].id)
      navigation.navigate('ProfileSwitcher', {})
    } else {
      navigation.navigate('ProfileSwitcher', {})
    }
  }

  const onMailChange = (text: any) => {
    setEmail(text)
  }

  const onRegisterPress = () => {
    navigation.navigate('Authentication', {
      screen: 'RegisterLanding',
      params: {},
    })
  }

  const checkNetworkStatus = async () => {
    const networkStatus = await Network.getNetworkStateAsync()

    const isOnline =
      (networkStatus && networkStatus.isConnected && networkStatus.isInternetReachable) || false
    setIsNetworkStatusOnline(isOnline)

    if (!isOnline && networkCheks < 5) {
      setTimeout(() => {
        setNetworkCheks(networkCheks + 1)
      }, 5000)
    }
  }

  const onOpenLegalDocument = (legalDocumentId: string) => {
    Linking.openURL(DirectusUtil.getPublicDocumentDownloadUrlFromId(legalDocumentId))
  }

  useEffect(() => {
    checkNetworkStatus()
  }, [networkCheks])

  return (
    <ScreenSafeAreaWrapper>
      <ContentWrapper alignCenter noHeader noDrawer>
        <Spacer size={5} />
        <StyledLogoBox>
          <StyledImage
            source={require('../../../../../assets/logos/logo_harvy.png')}
            resizeMode="contain"
          />
        </StyledLogoBox>
        <Spacer size={1} />
        <Typography.Body colorName="text-dark-3" align="center">
          Les producteurs font la route ensemble
        </Typography.Body>
        <Spacer size={4} />
        <PageTitle title="Se connecter"></PageTitle>
        <TextInput
          value={email}
          label="Email"
          field="email"
          keyboardType="email-address"
          onChangeText={(text) => onMailChange(text)}
        />
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        <TextInput
          value={password}
          label="Mot de passe"
          field="password"
          secureTextEntry
          onChangeText={(text) => setPassword(text)}
          hasError={!!authenticationErrorMessage}
          onKeyPressCallBack={() => onLoginPressed()}
        />
        <Spacer size={1} />
        <PasswordForgottenButton email={email} />
        <Spacer size={1} />
        {errorMessage ? (
          <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
        ) : null}

        <Spacer size={1} />
        {!isNetworkStatusOnline && (
          <>
            <Typography.BodySmall colorName="color-danger">
              Vous êtes hors ligne ! Veuillez vérifier votre connection internet.
            </Typography.BodySmall>
            <Spacer size={0.5} />
            {networkCheks >= 3 && (
              <Button
                label={'Rafraichir la page'}
                onPress={() => window.location.reload()}
                colorName="color-grey"
              />
            )}
          </>
        )}
        <Spacer size={1} />
        <Typography.BodySmall colorName="text-dark-3" align="center">
          En vous connectant, vous acceptez les{' '}
          <Typography.BodySmall
            colorName="text-dark-3"
            style={{ textDecorationLine: 'underline' }}
            onPress={() => onOpenLegalDocument('16c1b382-2fb3-4326-9906-ee004f5b9839')}
          >
            conditions générales d'utilisation (CGU)
          </Typography.BodySmall>
          , les{' '}
          <Typography.BodySmall
            colorName="text-dark-3"
            style={{ textDecorationLine: 'underline' }}
            onPress={() => onOpenLegalDocument('f68293a3-0e20-4290-b635-875b47907c1c')}
          >
            conditions générales de vente (CGV)
          </Typography.BodySmall>{' '}
          et la{' '}
          <Typography.BodySmall
            colorName="text-dark-3"
            style={{ textDecorationLine: 'underline' }}
            onPress={() => onOpenLegalDocument('debd6506-3580-4c2e-b69f-8e24aed8351c')}
          >
            politique de confidentialité
          </Typography.BodySmall>
        </Typography.BodySmall>
        <Spacer size={1} />
        <Button
          label={'Se connecter'}
          onPress={onLoginPressed}
          fullWidth
          loading={authenticationLoading}
          disabled={!isNetworkStatusOnline}
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={4} />
        <Typography.BodySmall colorName="text-dark-3">
          Vous n'êtes pas encore inscrit sur Harvy ?
        </Typography.BodySmall>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <Button.Text label={'Créer un compte'} onPress={onRegisterPress} />
        <HelpFooter short />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default LoginScreen
