import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import { CardPageLink, SectionTitle } from '../../../components'
import PageTitle from '../../../components/PageTitle'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import { AdminStackParamList } from '../../../navigation/AdminNavigationStack/AdminNavigationStack.model'
import DashboardStats from '../../../components/DashboardStats'
import FormatUtils from '../../../utilities/utils/format'
import { InterfaceColors } from '../../../utilities/styling/theme/theme'
import { useQuery } from '@tanstack/react-query'
import MetricsAdminService, { AdminDashboardBillingMetrics } from '../../../services/admin/metrics'

const currentMonth = new Date().getMonth()
const currentYear = new Date().getFullYear()
const defaultDateHour = new Date(currentYear, currentMonth, 1, 0, 0, 0)
const defaultDateHour2 = new Date(currentYear, currentMonth + 1, 1, 0, 0, 0)

const AdminBillingScreen = () => {
  const navigation = useNavigation<StackNavigationProp<AdminStackParamList>>()

  const [metricsFilters, setMetricsFilters] = useState({
    start: new Date(defaultDateHour),
    end: new Date(defaultDateHour2),
  })

  const {
    data: metrics,
    refetch: refetchMetrics,
    isInitialLoading: metricsLoading,
  } = useQuery<AdminDashboardBillingMetrics, Error>(
    ['group_metrics_dashboard_admin'],
    () => MetricsAdminService.getDashboardBillingMetrics(metricsFilters.start, metricsFilters.end),
    {
      keepPreviousData: true,
    },
  )

  useEffect(() => {
    refetchMetrics()
  }, [metricsFilters])

  const onClickMetricsChangeDate = async (metricsFilters: { start: Date; end: Date }) => {
    let currentMonthNew = metricsFilters.start.getMonth()
    let currentYear = metricsFilters.start.getFullYear()

    if (currentMonthNew < 0) {
      currentMonthNew = 11
      currentYear = currentYear - 1
    }

    if (currentMonthNew > 11) {
      currentMonthNew = 0
      currentYear = currentYear + 1
    }

    const newStartDate = new Date(currentYear, currentMonthNew, 1, 0, 0, 0)

    const newEndDate = new Date(currentYear, currentMonthNew + 1, 1, 0, 0, 0)

    const tempMetricsFilter = { start: newStartDate, end: newEndDate }

    await setMetricsFilters(tempMetricsFilter)
  }

  const selectedMetrics = [
    {
      label: 'Relevés',
      value: metrics?.nbInvoiceStatement ? metrics?.nbInvoiceStatement.toString() : '-',
    },
    {
      label: 'Non générés',
      value: metrics?.nbInvoiceStatementWithoutDocumentId
        ? metrics?.nbInvoiceStatementWithoutDocumentId.toString()
        : '-',
    },
    {
      label: 'Impayés Client',
      value: metrics?.nbInvoiceStatementClientUnpaidOutDelay
        ? metrics?.nbInvoiceStatementClientUnpaidOutDelay.toString()
        : '-',
    },
    {
      label: 'Impayés Client HT',
      value: metrics?.amountHtInvoiceStatementClientUnpaidOutDelay
        ? `${FormatUtils.formatPrice(metrics?.amountHtInvoiceStatementClientUnpaidOutDelay)}`
        : '-',
    },
    {
      label: 'Impayés Producteur',
      value: metrics?.nbInvoiceStatementProducerUnpaidOutDelay
        ? metrics?.nbInvoiceStatementProducerUnpaidOutDelay.toString()
        : '-',
    },
    {
      label: 'Impayés Producteur HT',
      value: metrics?.amountHtInvoiceStatementProducerUnpaidOutDelay
        ? `${FormatUtils.formatPrice(metrics?.amountHtInvoiceStatementProducerUnpaidOutDelay)}`
        : '-',
    },
  ]

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle title="Gestion financière" />
        <DashboardStats
          metrics={selectedMetrics}
          metricsFilters={metricsFilters}
          metricsType="MONTH"
          onChangeDates={onClickMetricsChangeDate}
          isLoading={metricsLoading}
        />
        <CardPageLink
          label="Bons de livraison (à venir)"
          icon="arrow-right"
          onClick={() => {}} // navigation.navigate('AdminBillingDeliveryNotes', {})}
        />
        <CardPageLink
          label="Factures (à venir)"
          icon="arrow-right"
          onClick={() => {}} // navigation.navigate('AdminBillingDeliveryNotes', {})}
        />

        <CardPageLink
          label="Relevés"
          icon="arrow-right"
          onClick={() => navigation.navigate('AdminBillingInvoiceStatementList')}
        />

        <SectionTitle title="Actions" />
        <CardPageLink label="Générer les relevés client" icon="arrow-right" onClick={() => {}} />
        <CardPageLink
          label="Générer les relevés producteur"
          icon="arrow-right"
          onClick={() => {}}
        />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default AdminBillingScreen
