import { ProducerVatTypeEnum } from '../../../enums/producer'
import { VatTypeEnums } from '../../../enums/vatType'
import { Account } from '../../domain/Account'

const isProfileSubjectToVAT = (account: Account): boolean =>
  account.legalInfo.vatType === VatTypeEnums.WITH_VAT

const hasPaymentMethodAndLegalInfos = (
  account?: Account,
  withPaymentMethod?: boolean,
): { valid: boolean; message: string } => {
  let valid = true
  let message = ''

  if (!account) {
    return {
      valid: false,
      message: 'Aucun compte légal trouvé',
    }
  }

  if (
    (account.defaultPaymentMethod === undefined || account.defaultPaymentMethod === null) &&
    withPaymentMethod
  ) {
    valid = false
    message = 'moyen de paiement'
  }

  if (!account.email) {
    valid = false
    message += `${message && ', '}email`
  }

  if (!account.legalInfo.siret) {
    valid = false
    message += `${message && ', '}siret`
  }

  if (!account.address || !account.address.city) {
    valid = false
    message += `${message && ', '} adresse de facturation`
  }

  return {
    valid: valid,
    message: !valid ? `Information(s) manquante(s) : ${message} ` : '',
  }
}

const AccountUtil = {
  isProfileSubjectToVAT,
  hasPaymentMethodAndLegalInfos,
}

export default AccountUtil
