import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const GoupContentWrapper = styled.View`
  flex: 1;
  width: 92%;
  margin: ${({ theme }) => theme.spacingUnit * 2}px;
`

const StyledButtonWrapper = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
`

const TextInputWrapper = styled.View`
  width: 90%;
  max-width: 300px;
  align-self: center;
`

export { GoupContentWrapper, StyledButtonWrapper, TextInputWrapper }
