import { CommonActions, useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React from 'react'
import { View } from 'react-native'
import { EdgeInsets, useSafeAreaInsets } from 'react-native-safe-area-context'
import { useTheme } from 'styled-components/native'
import { Icon, Icons, Spacer, Typography } from '../../../components'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { CarrierStackParamList } from '../../CarrierAppNavigationStack/CarrierNavigationStack.model'
import { ClientStackParamList } from '../../ClientNavigationStack/ClientNavigationStack.model'
import { ProducerStackParamList } from '../../ProducerNavigationStack/ProducerNavigationStack.model'
import {
  CenterContainer,
  HeaderBottomWrapper,
  HeaderTopWrapper,
  HeaderWrapper,
  LeftContainer,
  RightContainer,
  StyledTouchableOpacity,
} from '../common.styles'
import DeviceUtil from '../../../utilities/utils/device'

interface HeaderProps {
  title: string
  tourTitle?: string
  tourSubTitle?: string
  subTitle?: string
  goBackFallback?: string
  isSubNavigator?: boolean
  leftButton?: React.ReactElement
  rightButton?: React.ReactElement
  withCloseButton?: boolean
  noDrawer?: boolean
}

const Header = ({
  title,
  subTitle,
  goBackFallback,
  isSubNavigator,
  leftButton,
  rightButton,
  withCloseButton,
  noDrawer,
}: HeaderProps) => {
  const theme = useTheme()
  const navigation = useNavigation()
  const navigationProducer = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const navigationCarrier = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const navigationClient = useNavigation<StackNavigationProp<ClientStackParamList>>()
  const insets = useSafeAreaInsets()
  const { viewType } = useAuthenticationContext()

  const getNavigation = () => {
    if (viewType === 1) {
      return navigationCarrier
    }
    if (viewType === 2) {
      return navigationProducer
    }
    if (viewType === 3) {
      return navigationClient
    }
  }
  const goBack = () => {
    const navigationHandler = getNavigation()
    if (!navigation) return
    const lastRoute =
      navigation.getState().routes.length >= 2 &&
      navigation.getState().routes[navigation.getState().routes.length - 2]
    const lastRouteName = lastRoute && (lastRoute.name as string)
    if (lastRouteName && lastRouteName.includes('Add')) {
      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [{ name: goBackFallback || 'MainNavigation' }], // HERE YOU PUT THE ROUTE YOU WANT TO GO TO IN CASE THE GO BACK FAILED
        }),
      )
      return
    }
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      if (typeof window !== 'undefined') {
        window.location.href = '/' // Forcer un reload vers la home en Web
      }
    }
  }

  return (
    <HeaderWrapper>
      <HeaderTopWrapper
        isDesktop={!DeviceUtil.isMobileApp()}
        insets={!isSubNavigator ? insets : ({ top: 0 } as EdgeInsets)}
        noDrawer={noDrawer}
      >
        <LeftContainer isDesktop={!DeviceUtil.isMobileApp()}>
          {leftButton ?? (
            <StyledTouchableOpacity onPress={goBack}>
              <Icon
                type={Icons.Ionicons}
                name={withCloseButton ? 'close-outline' : 'chevron-back-outline'}
                color={theme.colors['text-light-1']}
                size={32}
              />
              {!withCloseButton && (
                <View>
                  <Spacer size={1.4} axis={Spacer.AxisEnum.VERTICAL} />
                  <Typography.BodySmall style={{ textAlign: 'center' }} colorName="text-light-1">
                    retour
                  </Typography.BodySmall>
                </View>
              )}
            </StyledTouchableOpacity>
          )}
        </LeftContainer>
        <CenterContainer>
          <View>
            <Typography.PageTitle style={{ textAlign: 'center' }}>{title}</Typography.PageTitle>
          </View>
          {subTitle && (
            <View>
              <Typography.BodySmall colorName="text-dark-3" style={{ textAlign: 'center' }}>
                {subTitle}
              </Typography.BodySmall>
            </View>
          )}
        </CenterContainer>
        <RightContainer>{rightButton}</RightContainer>
      </HeaderTopWrapper>
      {/* <HeaderBottomWrapper></HeaderBottomWrapper> */}
    </HeaderWrapper>
  )
}

Header.defaultProps = {
  goBackFallback: null,
  isSubNavigator: false,
  leftButton: null,
  rightButton: null,
  subTitle: null,
  withCloseButton: false,
  noDrawer: false,
}

export default Header
