import { useForm } from 'react-hook-form'
import {
  Button,
  CardPageLink,
  PageTitle,
  SectionTitle,
  Spacer,
  Typography,
} from '../../../../components'
import useAuthenticationContext from '../../../../utilities/hook/useAuthenticationContext'
import { Address, AddressType } from '../../../../domain'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../../utilities/styling/wrappers'
import { useQuery } from '@tanstack/react-query'
import AccountService from '../../../../services/billing/account'
import { Account } from '../../../../domain/Account'
import { useCallback, useEffect, useState } from 'react'
import AddressForm from '../../../../modules/Address/AddressForm'
import FormatUtils from '../../../../utilities/utils/format'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import UserProducerStackParamList from '../../../../navigation/ProducerNavigationStack/UserProducerNavigation/UserProducerConfigurationNavigation.model'
import i18n from '../../../../i18n'
import { StyledButtonWrapper } from './UpdateAccountScreen.styles'
import { View } from 'react-native'
import RhfTextInput from '../../../../components/RhfTextInput'
import { PAYMENT_METHODS, PaymentMethodEnum } from '../../../../../enums/payment'
import ActionCard from '../../../../modules/Common/ActionCard'
import CardSettings from '../../../../components/CardSettings'
import CopyPasteCard from '../../../../components/CopyPasteCard'
import CardPaymentMethod from '../../../../components/CardPaymentMethod'

const UpdateAccountScreen = () => {
  const { getAccessInfos } = useAuthenticationContext()
  const [isLoading, setIsLoading] = useState(false)
  const navigation = useNavigation<StackNavigationProp<UserProducerStackParamList>>()

  const accountId = getAccessInfos().accountId

  if (!accountId) {
    return (
      <ScreenSafeAreaWrapper withBottomNav>
        <ContentWrapper noDrawer>
          <PageTitle title="Mon compte facturation" />
          <View style={{ width: '100%' }}>
            <Typography.BodySmall align="center">
              Nous n'avons pas pu trouver votre compte facturation
            </Typography.BodySmall>
          </View>
        </ContentWrapper>
      </ScreenSafeAreaWrapper>
    )
  }

  const { data: accountData, refetch: refetchAccount } = useQuery<Account, Error>(
    ['account', getAccessInfos().accountId],
    () => AccountService.getOne(accountId),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: paymentMethods,
    refetch: refetchPaymentMethods,
    isInitialLoading: paymentMethodsLoading,
  } = useQuery<
    { count: number; stripeCustomerId: string; paymentMethods: { type: PaymentMethodEnum }[] },
    Error
  >(
    ['c_payment_method', getAccessInfos().accountId],
    () => AccountService.getPaymentMethods(getAccessInfos().accountId),
    {
      keepPreviousData: true,
      enabled: getAccessInfos()?.accountId !== null && getAccessInfos()?.clientId !== null,
      staleTime: 10000,
    },
  )

  const emailRegex = /^[^@]+@[^@]+\.[^@]{2,}$/

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      legalName: '',
      email: '',
      siret: '',
      apeCode: '',
      billingStatement: '',
      billingStatement2: '',
      IBAN: '',
      BIC: '',
    },
  })

  useEffect(() => {
    if (accountData) {
      reset({
        legalName: accountData.legalName || '',
        email: accountData.email || '',
        siret: accountData.legalInfo?.siret || '',
        apeCode: accountData.legalInfo?.apeCode || '',
        billingStatement: accountData.billingStatement?.billingStatement || '',
        billingStatement2: accountData.billingStatement?.billingStatement2 || '',
        IBAN: accountData.billingInfo?.IBAN || '',
        BIC: accountData.billingInfo?.BIC || '',
      })
    }
  }, [accountData, reset])

  useFocusEffect(
    useCallback(() => {
      refetchPaymentMethods()
    }, [refetchPaymentMethods]),
  )

  const onUpdateAccount = async (data: any) => {
    const formattedData = {
      legalName: data.legalName,
      email: data.email,
      legalInfo: {
        siret: data.siret,
        apeCode: data.apeCode,
      },
      billingStatement: {
        billingStatement: data.billingStatement,
        billingStatement2: data.billingStatement2,
      },
      billingInfo: {
        IBAN: data.IBAN,
        BIC: data.BIC,
      },
    }
    setIsLoading(true)
    try {
      await AccountService.update(accountId, formattedData)
      navigation.navigate('UserMenu')
    } catch (error) {
      console.error('error', error)
    }
    setIsLoading(false)
  }

  async function linkToAddress(address: Address) {
    if (accountData?.address) {
      return
    }
    await AccountService.update(accountId, { address: { id: address.id } })
  }

  const isClient = () => {
    return getAccessInfos().clientId !== null && getAccessInfos().clientId !== undefined
  }

  const onPressBillingPortal = async () => {
    const billingPageURL = await AccountService.getBillingPortalURL(getAccessInfos().accountId)

    if (billingPageURL) {
      window.open(billingPageURL, '_blank')
    }
  }

  const onActivateWireTransferPayment = async () => {
    await AccountService.activateWireTransferPayment(getAccessInfos().accountId)

    refetchPaymentMethods()
  }

  const handleCopy = (stringValue?: string) => {
    if (stringValue) {
      navigator.clipboard.writeText(`${stringValue}`)
    }
  }

  const onPressFavoritePaymentMethod = async (paymentMethod: PaymentMethodEnum) => {
    await AccountService.update(accountId, {
      defaultPaymentMethod: paymentMethod,
    })

    refetchAccount()
    refetchPaymentMethods()
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper noDrawer>
        <PageTitle title="Mon compte facturation" />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1.5} />
        <RhfTextInput
          control={control}
          name="legalName"
          rules={{ required: true }}
          textInputLabel="Nom légal"
        />
        {errors.legalName && (
          <>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
            <Typography.Body colorName="color-danger">Ce champ est obligatoire</Typography.Body>
          </>
        )}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <RhfTextInput
          control={control}
          name="email"
          rules={{
            pattern: { value: emailRegex, message: 'Veuillez entrer une adresse e-mail valide' },
          }}
          textInputLabel="Email"
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <AddressForm
          onChangeAddress={linkToAddress}
          address={accountData?.address}
          addressType={AddressType.BILLING}
          sectionTitle="Adresse de facturation"
          sectionTitleNoMarginLeft
          boldInputLabel
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <SectionTitle title="Données légales" noMarginLeft />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <RhfTextInput control={control} name="siret" textInputLabel="Siret" />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <RhfTextInput control={control} name="apeCode" textInputLabel="Code APE" />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        {!isClient() && (
          <>
            <SectionTitle title="Détails des factures" noMarginLeft />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            <RhfTextInput
              control={control}
              name="billingStatement"
              textInputLabel="Certification et origine"
            />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <RhfTextInput
              control={control}
              name="billingStatement2"
              textInputLabel="Autres informations"
            />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <SectionTitle title="Informations de facturation" noMarginLeft />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            <RhfTextInput control={control} name="IBAN" textInputLabel="IBAN" />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <RhfTextInput control={control} name="BIC" textInputLabel="BIC" />
          </>
        )}

        <StyledButtonWrapper>
          {errors.legalName || errors.email ? (
            <>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
              <Typography.Body colorName="color-danger">
                Un champ requis n'a pas été complété
              </Typography.Body>
            </>
          ) : null}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <Button
            label={FormatUtils.capitalize(i18n.t('update'))}
            onPress={handleSubmit(onUpdateAccount)}
            loading={isLoading}
            hasDoubleValidation
            confirmationLabel="Êtes-vous sûr de vouloir modifier vos informations de facturation ?"
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        </StyledButtonWrapper>
        {isClient() && (
          <>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
            <SectionTitle title="Vos moyen de paiement" noMarginLeft />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            {paymentMethods?.paymentMethods.map((pm) => {
              const label = PAYMENT_METHODS.find((pms) => pms.value === pm.type)?.label

              return (
                <>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                  <CardPaymentMethod
                    label={label || ''}
                    isFavorite={
                      accountData?.defaultPaymentMethod !== undefined &&
                      pm.type === accountData?.defaultPaymentMethod
                    }
                    canEdit={pm.type === PaymentMethodEnum.SEPA_DEBIT}
                    onClickEdit={() => onPressBillingPortal()}
                    onClickFavorite={() => onPressFavoritePaymentMethod(pm.type)}
                  />
                </>
              )
            })}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
            {paymentMethods?.paymentMethods &&
              !(paymentMethods?.paymentMethods || []).find(
                (pm) => pm && pm?.type === PaymentMethodEnum.BANK_TRANSFER,
              ) && (
                <CardSettings
                  title="Basculer en paiement par virement"
                  description={`Passez du paiement par virement. Ils devront être reçu avant l'échéance`}
                  children={
                    <Button
                      fullWidth={true}
                      colorName="color-grey"
                      label={'Activer'}
                      small
                      onPress={() => onActivateWireTransferPayment()}
                    />
                  }
                />
              )}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />

            {paymentMethods?.paymentMethods &&
              !(paymentMethods?.paymentMethods || []).find(
                (pm) => pm && pm?.type === PaymentMethodEnum.SEPA_DEBIT,
              ) && (
                <CardSettings
                  title="Mon RIB / Prélèvement SEPA"
                  description={`Enregistrez votre RIB et choisissez de basculer en prélèvement SEPA`}
                  children={
                    <Button
                      fullWidth={true}
                      colorName="color-grey"
                      label={'Enregistrer'}
                      small
                      onPress={() => onPressBillingPortal()}
                    />
                  }
                />
              )}
            {accountData?.billingInfoForPayment && accountData?.billingInfoForPayment.IBAN && (
              <>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
                <SectionTitle title="Instruction pour les paiement par virement" noMarginLeft />
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                <CopyPasteCard
                  data={{ label: 'Nom du compte', value: 'Harvy' }}
                  onPress={() => handleCopy('Harvy')}
                />
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                <CopyPasteCard
                  data={{ label: 'IBAN', value: accountData?.billingInfoForPayment.IBAN ?? '' }}
                  onPress={() => handleCopy(accountData?.billingInfoForPayment?.IBAN)}
                />
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                <CopyPasteCard
                  data={{ label: 'BIC', value: accountData?.billingInfoForPayment.BIC ?? '' }}
                  onPress={() => handleCopy(accountData?.billingInfoForPayment?.BIC)}
                />
              </>
            )}
          </>
        )}
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default UpdateAccountScreen
