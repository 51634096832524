import ApiService from '../../api.service'

export type AdminDashboardMetrics = {
  nbOrders: number
  nbTours: number
  totalHT: number
  totalHTProducerPrice: number
  averageCarrierFees: number
  averagePlatformFees: number
  nbActiveProducers: number
  nbActiveClients: number
}

const getDashboardMetrics = async (start: Date, end: Date): Promise<AdminDashboardMetrics> => {
  const response = await ApiService.get(`/metrics/dashboard/admin`, {
    start: start,
    end: end,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

export type AdminDashboardBillingMetrics = {
  nbInvoiceStatementWithoutDocumentId: number
  nbInvoiceStatement: number
  nbInvoiceStatementClientUnpaidOutDelay: number
  amountHtInvoiceStatementClientUnpaidOutDelay: number
  nbInvoiceStatementProducerUnpaidOutDelay: number
  amountHtInvoiceStatementProducerUnpaidOutDelay: number
}

const getDashboardBillingMetrics = async (
  start: Date,
  end: Date,
): Promise<AdminDashboardBillingMetrics> => {
  const response = await ApiService.get(`/metrics/dashboard/admin-billing`, {
    start: start,
    end: end,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const MetricsAdminService = {
  getDashboardMetrics,
  getDashboardBillingMetrics,
}

export default MetricsAdminService
