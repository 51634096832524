import { useQuery } from '@tanstack/react-query'
import { InvoiceStatusEnum } from '../../../enums/invoice'
import { InvoiceStatementTypeEnum } from '../../../enums/invoice-statement'
import { Pagination } from '../../domain'
import { InvoiceStatement } from '../../domain/InvoiceStatement'
import ApiService from '../api.service'
import { queryClient } from '../../utilities/queryClient'
import FormatUtils from '../../utilities/utils/format'

interface FindParams {
  status?: InvoiceStatusEnum
  type?: InvoiceStatementTypeEnum
  recipientId?: string
  withoutDocument?: boolean
  start?: Date
  end?: Date
  limit?: number
  pageNumber?: number
}

const find = async ({
  status,
  type,
  start,
  end,
  limit,
  pageNumber,
}: FindParams): Promise<{ data: InvoiceStatement[]; pagination?: Pagination }> => {
  const response = await ApiService.get(`/invoice-statement`, {
    status,
    type,
    after: start?.toISOString(),
    before: end?.toISOString(),
    limit,
    page: pageNumber,
  }).then((result) => result)
  return response
}

const findAdmin = async ({
  status,
  type,
  recipientId,
  withoutDocument,
  start,
  end,
  limit,
  pageNumber,
}: FindParams): Promise<{ data: InvoiceStatement[]; pagination?: Pagination }> => {
  const response = await ApiService.get(`/invoice-statement/admin`, {
    status,
    type,
    recipientId,
    withoutDocument,
    after: start?.toISOString(),
    before: end?.toISOString(),
    limit,
    page: pageNumber,
  }).then((result) => result)
  return response
}

const startAllPdfGeneration = async ({
  status,
  type,
  recipientId,
  withoutDocument,
  start,
  end,
  limit,
  pageNumber,
}: FindParams): Promise<any> => {
  return await ApiService.get(`/invoice-statement/pdf/all/admin`, {
    status,
    type,
    recipientId,
    withoutDocument,
    after: start?.toISOString(),
    before: end?.toISOString(),
    limit,
    page: pageNumber,
  })
}

const startPdfGeneration = async (invoiceStatementId: string): Promise<any> => {
  return await ApiService.get(`/invoice-statement/${invoiceStatementId}/pdf`)
}
const getOne = async (
  invoiceStatementId: string,
  detailled?: boolean,
): Promise<InvoiceStatement> => {
  return await ApiService.get(`/invoice-statement/${invoiceStatementId}`, {
    detailled: detailled,
  })
}

const update = async (invoiceStatementId: string, invoice: any): Promise<InvoiceStatement> => {
  const response = await ApiService.patch(
    `/invoice-statement/${invoiceStatementId}`,
    invoice,
  ).catch((error) => {
    throw error
  })
  return response
}

const sendInvoiceStatementEmail = async (idInvoiceStatement: string): Promise<InvoiceStatement> => {
  const response = await ApiService.post(`/invoice/${idInvoiceStatement}/email`, {})
  return response
}

// ============================ custom hook ============================

export type UseFindInvoiceStatementsArgs = FindParams

function useFindInvoiceStatements(args: UseFindInvoiceStatementsArgs) {
  function getQueryKey() {
    return [
      'invoice-statement',
      args.status ? `status_${args.status}` : '',
      args.type ? `type_${args.type}` : '',
      args.start ? `start_${args.start.toISOString()}` : '',
      args.end ? `end_${args.end.toISOString()}` : '',
      args.limit ? `limit_${args.limit}` : '',
      args.pageNumber ? `pageNumber_${args.pageNumber}` : '',
    ].filter(Boolean)
  }

  function query() {
    return useQuery<{ data: InvoiceStatement[]; pagination?: Pagination }, Error>({
      queryKey: getQueryKey(),
      queryFn: () => find(args),
      select: (data): { data: InvoiceStatement[]; pagination?: Pagination } => ({
        pagination: data.pagination,
        data: data.data.map((statement) => ({
          ...statement,
          periodStart: new Date(FormatUtils.removeUTCFromDate(statement.periodStart)),
          periodEnd: new Date(FormatUtils.removeUTCFromDate(statement.periodEnd)),
          createdDate: new Date(FormatUtils.removeUTCFromDate(statement.createdDate)),
        })),
      }),
    })
  }

  function refetch() {
    queryClient.refetchQueries(getQueryKey())
  }

  return {
    query,
    refetch,
  }
}

// ============================ useFindInvoiceStatementsAdmin hook ============================

export type UseFindInvoiceStatementsAdminArgs = FindParams

function useFindInvoiceStatementsAdmin(args: UseFindInvoiceStatementsAdminArgs) {
  function getQueryKey() {
    return [
      'invoice-statement-admin',
      args.status ? `status_${args.status}` : '',
      args.type ? `type_${args.type}` : '',
      args.recipientId ? `recipientId_${args.recipientId}` : '',
      args.withoutDocument ? `recipientId_${args.withoutDocument}` : '',
      args.start ? `start_${args.start.toISOString()}` : '',
      args.end ? `end_${args.end.toISOString()}` : '',
      args.limit ? `limit_${args.limit}` : '',
      args.pageNumber ? `pageNumber_${args.pageNumber}` : '',
    ].filter(Boolean)
  }

  function query() {
    return useQuery<{ data: InvoiceStatement[]; pagination?: Pagination }, Error>({
      queryKey: getQueryKey(),
      queryFn: () => findAdmin(args),
      select: (data): { data: InvoiceStatement[]; pagination?: Pagination } => ({
        pagination: data.pagination,
        data: data.data.map((statement) => ({
          ...statement,
          periodStart: new Date(FormatUtils.removeUTCFromDate(statement.periodStart)),
          periodEnd: new Date(FormatUtils.removeUTCFromDate(statement.periodEnd)),
          createdDate: new Date(FormatUtils.removeUTCFromDate(statement.createdDate)),
        })),
      }),
    })
  }

  function refetch() {
    queryClient.refetchQueries(getQueryKey())
  }

  function invalidate() {
    queryClient.invalidateQueries(getQueryKey())
  }

  return {
    query,
    refetch,
    invalidate,
  }
}

// ============================ useGetOneInvoiceStatement hook ============================

export type UseGetOneInvoiceStatementArgs = {
  invoiceStatementId: string
}

export interface InvoiceStatementExtended extends InvoiceStatement {
  priceItems: {
    totalHT: number
    totalVAT: number
    totalTTC?: number
  }[]
  dateInfos: string
}

function useGetOneInvoiceStatement(args: UseGetOneInvoiceStatementArgs) {
  function getQueryKey() {
    return ['sf_billing_invoice_statement', args.invoiceStatementId].filter(Boolean)
  }

  function query() {
    return useQuery<InvoiceStatement, Error, InvoiceStatementExtended>({
      queryKey: getQueryKey(),
      queryFn: () => getOne(args.invoiceStatementId),
      select: (invoiceStatement): InvoiceStatementExtended => ({
        ...invoiceStatement,
        priceItems: [
          {
            totalHT:
              invoiceStatement.producerTotalAmounts?.totalHT ||
              invoiceStatement.clientTotalAmounts?.totalHT ||
              0,
            totalVAT:
              invoiceStatement.producerTotalAmounts?.totalVAT ||
              invoiceStatement.clientTotalAmounts?.totalVAT ||
              0,
            totalTTC:
              invoiceStatement.producerTotalAmounts?.totalTTC ||
              invoiceStatement.clientTotalAmounts?.totalTTC ||
              0,
          },
        ],
        dateInfos: `du ${
          invoiceStatement.createdDate
            ? FormatUtils.formatDate(invoiceStatement.createdDate.toString(), 'DateFullYear')
            : 'N/A'
        } - échéance le ${
          invoiceStatement.dueDate
            ? FormatUtils.formatDate(invoiceStatement.dueDate.toString(), 'DateFullYear')
            : 'N/A'
        }`,
      }),
    })
  }

  function invalidate() {
    queryClient.invalidateQueries(getQueryKey())
  }

  return {
    getQueryKey,
    query,
    invalidate,
  }
}

export type PdfStatus = {
  documentId: string
}

async function getPdfStatus(jobId: string) {
  return await ApiService.getResponse<PdfStatus>(`/invoice-statement/pdf/status/${jobId}`)
}

const getOneDownload = async (invoiceStatementId: string): Promise<any> => {
  return await ApiService.get(`/invoice-statement/${invoiceStatementId}/download`)
}

const InvoiceStatementService = {
  useFindInvoiceStatements,
  useFindInvoiceStatementsAdmin,
  useGetOneInvoiceStatement,
  startPdfGeneration,
  startAllPdfGeneration,
  update,
  sendInvoiceStatementEmail,
  getPdfStatus,
  getOneDownload,
}

export default InvoiceStatementService
