import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { Linking, View } from 'react-native'
import { useEffect, useState } from 'react'
import { StackNavigationProp } from '@react-navigation/stack'
import {
  Button,
  Card,
  Dropdown,
  PageTitle,
  SectionTitle,
  Spacer,
  Typography,
} from '../../../components'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import BadgeStatus from '../../../components/BadgeStatus'
import { InvoiceStatusEnum, INVOICE_STATEMENT_STATUSES } from '../../../../enums/invoice'
import FormatUtils from '../../../utilities/utils/format'
import TotalSummaryCard from '../../../components/TotalSummaryCard'
import i18n from '../../../i18n'
import {
  StyledBottomWrapper,
  StyledButtonWrapper,
  StyledInfoCardWrapper,
  StyledPageTitleWrapper,
  StyledTopWrapper,
} from './AdminBillingInvoiceStatementScreen.styles'
import Loader from '../../../components/Loader'
import CardSettings from '../../../components/CardSettings'
import DeviceUtil from '../../../utilities/utils/device'
import InvoiceStatementService from '../../../services/invoice-statement'
import BillingClientNavigationStackParamList from '../../../navigation/ClientNavigationStack/BillingClientNavigation/BillingClientNavigation.model'
import { InvoiceStatementTypeEnum } from '../../../../enums/invoice-statement'
import AccountingDocumentCard from '../../../components/AccountingDocumentCard'
import DirectusUtil from '../../../utilities/utils/directus'
import TotalSummaryCardProducerStatement from '../../../components/TotalSummaryCardProducerStatement'
import { ClientStackParamList } from '../../../navigation/ClientNavigationStack/ClientNavigationStack.model'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import BillingAdminNavigationStackParamList from '../../../navigation/AdminNavigationStack/BillingAdminNavigation/BillingAdminNavigation.model'

const POLLING_INTERVAL = 2000
const MAX_RETRIES = 50

function openPdf(documentId: string, directusToken: string) {
  return Linking.openURL(DirectusUtil.getDocumentDownloadUrlFromId(documentId, directusToken))
}

function AdminBillingInvoiceStatementScreen() {
  const [newStatus, setNewStatus] = useState<any>(undefined)
  const [isSending, setIsSending] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [pdfJobId, setPdfJobId] = useState<string | null>(null)
  const [pollingCount, setPollingCount] = useState(0)
  const navigation =
    useNavigation<StackNavigationProp<ClientStackParamList | ProducerStackParamList>>()

  const route =
    useRoute<RouteProp<BillingAdminNavigationStackParamList, 'AdminBillingInvoiceStatement'>>()
  const { invoiceStatementId } = route?.params ?? {
    id: '',
  }

  const { data: invoiceStatement, isInitialLoading: invoiceStatementLoading } =
    InvoiceStatementService.useGetOneInvoiceStatement({
      invoiceStatementId,
    }).query()

  const isMobileScreen = DeviceUtil.isMobileApp()

  const handleStatusChange = (selectedValue: any[]) => {
    setNewStatus(selectedValue)
  }

  const onUpdateInvoiceStatement = async () => {
    if (!newStatus) {
      setError('Veuillez sélectionner un statut valide')
      return
    }
    setIsUpdating(true)
    setError(null)
    try {
      await InvoiceStatementService.update(invoiceStatementId, { status: newStatus.value })
      InvoiceStatementService.useGetOneInvoiceStatement({
        invoiceStatementId,
      }).invalidate()
      // navigation.navigate('InvoiceStatementList')
    } catch (error) {
      console.error('error', error)
      setError("Une erreur s'est produite lors de la mise à jour du relevé")
    } finally {
      setIsUpdating(false)
    }
  }

  const onSendInvoiceEmail = async () => {
    setIsSending(true)
    try {
      await InvoiceStatementService.sendInvoiceStatementEmail(invoiceStatementId)
      InvoiceStatementService.useGetOneInvoiceStatement({
        invoiceStatementId,
      }).invalidate()
    } catch (error) {
      console.error('error', error)
      setError("Une erreur s'est produite lors de l'envoi du relevé")
    }

    setIsSending(false)
  }

  let timeoutId: any = 0

  async function checkPdfStatus() {
    if (!pdfJobId || pollingCount >= MAX_RETRIES) {
      setIsDownloading(false)
      if (pollingCount >= MAX_RETRIES) {
        setError('La génération du PDF a pris trop de temps, veuillez réessayer.')
      }
      return
    }

    try {
      const response = await InvoiceStatementService.getPdfStatus(pdfJobId)

      if (response.status === 204) {
        // PDF still generating, continue polling
        setPollingCount((prev) => prev + 1)
        timeoutId = setTimeout(checkPdfStatus, POLLING_INTERVAL)
      } else if (response.data?.documentId) {
        // PDF is ready, update invoice statement data to include new documentId
        InvoiceStatementService.useGetOneInvoiceStatement({
          invoiceStatementId: invoiceStatement?.id!,
        }).invalidate()
        resetPollingPdfStatus()
      }
    } catch (error) {
      console.error('Error checking PDF status:', error)
      setError("Une erreur s'est produite lors de la génération du PDF")
      resetPollingPdfStatus()
    }
  }

  function resetPollingPdfStatus() {
    setIsDownloading(false)
    setPdfJobId(null)
    setPollingCount(0)
    clearTimeout(timeoutId)
    timeoutId = 0
  }

  useEffect(() => {
    if (pdfJobId && timeoutId === 0) {
      checkPdfStatus()
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [pdfJobId])

  const onClickDownloadInvoiceStatement = async () => {
    if (invoiceStatement?.documentId) {
      return openPdf(invoiceStatement.documentId, invoiceStatement.directusToken!)
    }

    setIsDownloading(true)
    setError(null)

    try {
      const { jobId } = await InvoiceStatementService.startPdfGeneration(invoiceStatement?.id!)
      setPdfJobId(jobId)
      setPollingCount(0)
    } catch (error) {
      console.error('Error generating PDF:', error)
      setError("Une erreur s'est produite lors de la génération du PDF")
      setIsDownloading(false)
    }
  }

  const canSendEmail = () => {
    return (
      invoiceStatement?.recipient.email && invoiceStatementStatus?.value !== InvoiceStatusEnum.SEND
    )
  }

  const getTitle = () => {
    const identifier =
      invoiceStatement?.type === InvoiceStatementTypeEnum.CLIENT
        ? invoiceStatement.displayClientIdentifier
        : invoiceStatement?.displayProducerIdentifier
    return `Relevé #${identifier}`
  }

  const getSubtitle = () => {
    return `du ${FormatUtils.formatDate(
      invoiceStatement?.periodStart.toString(),
      'Date',
    )} au ${FormatUtils.formatDate(invoiceStatement?.periodEnd.toString(), 'Date')}`
  }

  const invoiceStatementStatus = INVOICE_STATEMENT_STATUSES.find(
    (statusType) => statusType.value === invoiceStatement?.status,
  )

  if (!invoiceStatement) {
    return (
      <>
        <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
        <Typography.BodySmall colorName="text-dark-1" align="center">
          Relevé introuvable
        </Typography.BodySmall>
      </>
    )
  }

  if (invoiceStatementLoading) {
    return (
      <>
        <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
        <Loader />
      </>
    )
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <StyledTopWrapper>
          <StyledPageTitleWrapper>
            <PageTitle title={getTitle()} noFixedWidth noMarginLeft noMarginBottom />
            {invoiceStatementStatus && (
              <View style={{ bottom: '4px' }}>
                <BadgeStatus
                  label={invoiceStatementStatus?.label || ''}
                  color={invoiceStatementStatus?.color}
                  backgroundColor={invoiceStatementStatus?.backgroundColor}
                  extraSmall
                />
              </View>
            )}
          </StyledPageTitleWrapper>
          <StyledButtonWrapper isWrapped={isMobileScreen}>
            <Button
              label={
                invoiceStatement.documentId ? FormatUtils.capitalize(i18n.t('download')) : 'Générer'
              }
              onPress={onClickDownloadInvoiceStatement}
              loading={isDownloading}
              small
            />
          </StyledButtonWrapper>
        </StyledTopWrapper>
        <Typography.BodyExtraSmall colorName="text-dark-3" bold>
          {getSubtitle()}
        </Typography.BodyExtraSmall>
        <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
        <StyledInfoCardWrapper>
          <View style={{ width: '45%' }}>
            <Card>
              <Typography.BodySmall colorName="text-dark-1" bold>
                Émetteur
              </Typography.BodySmall>
              <Typography.BodySmall colorName="text-dark-1" ellipsis>
                Harvy
              </Typography.BodySmall>
            </Card>
          </View>
          <View style={{ width: '45%' }}>
            <Card>
              <Typography.BodySmall colorName="text-dark-1" bold>
                Destinataire
              </Typography.BodySmall>
              <Typography.BodySmall colorName="text-dark-1" ellipsis>
                {invoiceStatement.recipient.legalName}
              </Typography.BodySmall>
            </Card>
          </View>
        </StyledInfoCardWrapper>
        <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
        <SectionTitle title="Contenu du relevé" />
        {invoiceStatement.type === InvoiceStatementTypeEnum.CLIENT
          ? (invoiceStatement.clientInvoices || []).map((invoice) => {
              return (
                <AccountingDocumentCard
                  key={invoice.id}
                  item={invoice}
                  type="invoice"
                  displayIssuer
                  onClick={() => true}
                />
              )
            })
          : (invoiceStatement.producerInvoices || []).map((invoice) => {
              return (
                <AccountingDocumentCard
                  key={invoice.id}
                  item={invoice}
                  type="invoice"
                  displayRecipient
                  onClick={() => true}
                />
              )
            })}
        <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
        {invoiceStatement?.type === 1 ? ( // Producer type
          <TotalSummaryCardProducerStatement
            producerTotalAmounts={invoiceStatement.producerTotalAmounts}
            producerFeesDetails={invoiceStatement.producerFeesDetail}
            producerFeesTotalAmounts={invoiceStatement.producerFeesTotalAmounts}
            producerBankTransferAmount={invoiceStatement.producerBankTransferAmount}
            producerInvoices={invoiceStatement.producerInvoices}
          />
        ) : (
          <TotalSummaryCard items={invoiceStatement.priceItems} />
        )}
        <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
        <SectionTitle title="Statut du relevé" />
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        <StyledBottomWrapper>
          <View style={{ width: '50%' }}>
            <Dropdown
              label="Nouveau statut"
              boldInputLabel
              multiple={false}
              defaultValue={invoiceStatement.status}
              optionsDefault={INVOICE_STATEMENT_STATUSES.map((invoiceStatus) => ({
                value: invoiceStatus.value,
                label: invoiceStatus.label,
              }))}
              onChange={handleStatusChange}
            />
          </View>
          <Spacer size={1} axis={Spacer.AxisEnum.HORIZONTAL} />
          <View style={{ width: '50%', alignItems: 'center' }}>
            {invoiceStatement.type === InvoiceStatementTypeEnum.CLIENT && (
              <Typography.BodyExtraSmall colorName="text-dark-3" align="center">
                La modification du statut à "Payé" mettra aussi à jour le statut de toutes les
                factures de ce relevé
              </Typography.BodyExtraSmall>
            )}
            <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
            <Button
              label={FormatUtils.capitalize(i18n.t('save'))}
              onPress={() => onUpdateInvoiceStatement()}
              disabled={!newStatus}
              loading={isUpdating}
            />
          </View>
        </StyledBottomWrapper>

        <Spacer size={5} axis={Spacer.AxisEnum.VERTICAL} />
        <CardSettings
          title="Envoyer le relevé par email"
          descriptionColor={invoiceStatement.recipient.email ? undefined : 'color-warning'}
          description={
            invoiceStatement.recipient.email
              ? `Ce relevé ${canSendEmail() ? 'sera' : 'a été'} envoyé à ${
                  invoiceStatement.recipient.legalName
                }.`
              : `L'email du destinaire n'est pas renseigné`
          }
        >
          <Button
            label="Envoyer"
            colorName="color-grey"
            onPress={() => onSendInvoiceEmail()}
            loading={isSending}
            disabled={!canSendEmail()}
          />
        </CardSettings>

        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        {error && (
          <Typography.BodySmall colorName="color-danger" align="center">
            {error}
          </Typography.BodySmall>
        )}
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default AdminBillingInvoiceStatementScreen
