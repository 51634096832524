import { useTheme } from 'styled-components/native'
import { Button, Typography } from '../../../components'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import {
  ProfileSwitcherLeft,
  ProfileSwitcherRight,
  StyledProfileSwitcherCard,
} from './ProfileSwitcherCard.styles'
import { UserEnums } from '../../../../enums'
import FormatUtils from '../../../utilities/utils/format'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { ProducerStackParamList } from '../../ProducerNavigationStack/ProducerNavigationStack.model'
import { CarrierStackParamList } from '../../CarrierAppNavigationStack/CarrierNavigationStack.model'
import { ClientStackParamList } from '../../ClientNavigationStack/ClientNavigationStack.model'
import { queryClient } from '../../../utilities/queryClient'
import { useQuery } from '@tanstack/react-query'
import { ProfilesResponse } from '../../../screens/Authentification/ProfileSwitcher'
import AuthenticationService from '../../../services/authentication'
import { useCallback, useState } from 'react'
import DeviceUtil from '../../../utilities/utils/device'
import { Pagination, UserCarrier, UserProducer } from '../../../domain'
import { AdminStackParamList } from '../../AdminNavigationStack/AdminNavigationStack.model'

type profileSwitcherDataType = {
  label: string
  typeLabel: string
  switchType: 'MONO' | 'DOUBLE' | 'MULTIPLE'
  targetProfileType?: UserEnums.ViewTypeEnums.PRODUCER | UserEnums.ViewTypeEnums.CARRIER
  targetProfile?: string
}

const ProfileSwitcherCard = () => {
  const navigationProducer = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const navigationCarrier = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const navigationClient = useNavigation<StackNavigationProp<ClientStackParamList>>()
  const navigationAdmin = useNavigation<StackNavigationProp<AdminStackParamList>>()

  const { viewType, authenticationData, changeViewType, getAccessInfos, isModeAdminPlatform } =
    useAuthenticationContext()
  const access = authenticationData?.access
  const theme = useTheme()

  const isMobile = DeviceUtil.isMobileApp()

  const {
    data: carrierProfiles,
    refetch: refetchCarrierProfiles,
    isInitialLoading: carrierProfilesLoading,
  } = useQuery<{ data: UserCarrier[]; pagination: Pagination }, Error>(
    ['u_sf_profiles', getAccessInfos()?.userId],
    () => AuthenticationService.getFilteredUserProfiles(2, 1, UserEnums.ViewTypeEnums.CARRIER, ''),
    {
      keepPreviousData: true,
      staleTime: 600000, // 10min
    },
  )

  const {
    data: producerProfiles,
    refetch: refetchProducerProfiles,
    isInitialLoading: producerProfilesLoading,
  } = useQuery<{ data: UserProducer[]; pagination: Pagination }, Error>(
    ['u_p_profiles', getAccessInfos()?.userId],
    () => AuthenticationService.getFilteredUserProfiles(3, 1, UserEnums.ViewTypeEnums.PRODUCER, ''),
    {
      staleTime: 600000, // 10min
      keepPreviousData: true,
    },
  )

  const profileSwitcherData = useCallback((): profileSwitcherDataType => {
    const profileSwitcherDataTemp: profileSwitcherDataType = {
      label: '',
      typeLabel: '',
      switchType: 'MONO',
    }

    if (isModeAdminPlatform()) {
      profileSwitcherDataTemp.label = access?.user.firstName || ''
      profileSwitcherDataTemp.typeLabel = 'Admin'
      profileSwitcherDataTemp.switchType = 'MULTIPLE'
      return profileSwitcherDataTemp
    }

    if (getAccessInfos().nbProfiles > 1) {
      profileSwitcherDataTemp.switchType = 'MULTIPLE'

      if (
        getAccessInfos().nbProfiles === 2 &&
        getAccessInfos().nbCarrierProfiles === 1 &&
        getAccessInfos().nbProducerProfiles === 1 &&
        carrierProfiles?.data &&
        carrierProfiles?.data.length > 0 &&
        producerProfiles?.data &&
        producerProfiles?.data.length > 0
      ) {
        profileSwitcherDataTemp.switchType = 'DOUBLE'
        if (carrierProfiles.data[0].carrier?.id === access?.carrierId) {
          profileSwitcherDataTemp.targetProfileType = UserEnums.ViewTypeEnums.PRODUCER
          profileSwitcherDataTemp.targetProfile = producerProfiles.data[0].producer?.id
        } else {
          profileSwitcherDataTemp.targetProfileType = UserEnums.ViewTypeEnums.CARRIER
          profileSwitcherDataTemp.targetProfile = carrierProfiles.data[0].carrier?.id
        }
      }
    }

    if (viewType === UserEnums.ViewTypeEnums.CARRIER) {
      profileSwitcherDataTemp.label = access?.carrierName || ''
      profileSwitcherDataTemp.typeLabel = FormatUtils.getCarrierNamingFromGroupType(
        access?.carrierGroupType,
      )
    }
    if (viewType === UserEnums.ViewTypeEnums.PRODUCER) {
      profileSwitcherDataTemp.label = access?.producerLabel || ''
      profileSwitcherDataTemp.typeLabel = 'Producteur'
    }
    if (viewType === UserEnums.ViewTypeEnums.CLIENT) {
      profileSwitcherDataTemp.label = access?.clientLabel || ''
      profileSwitcherDataTemp.typeLabel = 'Client'
    }

    return profileSwitcherDataTemp
  }, [access, carrierProfiles, producerProfiles])

  const onSelectProfileType = async () => {
    const targetProfile = profileSwitcherData().targetProfile
    const targetProfileType = profileSwitcherData().targetProfileType

    if (!targetProfile || !targetProfileType) {
      return
    }
    await queryClient.cancelQueries()
    await queryClient.clear()
    await queryClient.resetQueries()

    changeViewType(targetProfileType, targetProfile)
  }

  if (!viewType) return null

  const onChangeViewType = async () => {
    if (viewType === 10) {
      navigationAdmin.navigate('UserAdminConfigurationNav', {
        screen: 'ProfileSwitcher',
        params: {},
      })
    }
    if (viewType === 1) {
      navigationCarrier.navigate('UserCarrierConfigurationNav', {
        screen: 'ProfileSwitcher',
        params: {},
      })
    }
    if (viewType === 2) {
      navigationProducer.navigate('UserProducerConfigurationNav', {
        screen: 'ProfileSwitcher',
        params: {},
      })
    }
    if (viewType === 3) {
      navigationClient.navigate('UserClientConfigurationNav', {
        screen: 'ProfileSwitcher',
        params: {},
      })
    }
  }

  return (
    <StyledProfileSwitcherCard>
      <ProfileSwitcherLeft isMobile={isMobile}>
        <Typography.BodySmall bold colorName="color-primary">
          {profileSwitcherData().typeLabel}
        </Typography.BodySmall>
        <Typography.Body ellipsis colorName="text-dark-3">
          {profileSwitcherData().label}
        </Typography.Body>
      </ProfileSwitcherLeft>
      {profileSwitcherData().switchType === 'MULTIPLE' && (
        <ProfileSwitcherRight>
          <Button.Icon
            onPress={onChangeViewType}
            iconName={'account-arrow-right'}
            iconType="MaterialCommunityIcons"
            iconProps={{ color: theme.colors['color-secondary'], iconSize: 16 }}
            label="Comptes"
            colorName="color-primary"
            noMargin
            small
            iconPosition="left"
          />
        </ProfileSwitcherRight>
      )}
      {profileSwitcherData().switchType === 'DOUBLE' && (
        <ProfileSwitcherRight>
          <Button.Icon
            onPress={() => onSelectProfileType()}
            noMargin
            iconName={'account-arrow-right'}
            iconType="MaterialCommunityIcons"
            iconProps={{ color: theme.colors['text-light-1'], iconSize: 16 }}
            label={
              profileSwitcherData().targetProfileType === UserEnums.ViewTypeEnums.CARRIER
                ? isMobile
                  ? 'Livreur'
                  : 'Mode Livreur'
                : isMobile
                ? 'Producteur'
                : 'Mode Producteur'
            }
            colorName={
              profileSwitcherData().targetProfileType === UserEnums.ViewTypeEnums.CARRIER
                ? 'color-primary-2'
                : 'color-primary'
            }
            small
          />
        </ProfileSwitcherRight>
      )}
    </StyledProfileSwitcherCard>
  )
}

export default ProfileSwitcherCard
