import { MailEnums, TourEnums } from '../../../enums'
import { CarrierFeesEnums } from '../../../enums/carrierFees'
import { TutorialStepData } from '../../../enums/tutorialStep'
import { Batch } from '../Batch'
import { Carrier } from '../Carrier'
import { Cart } from '../Cart'
import { City } from '../City'
import { Group, PlatformFeesRules } from '../Group'
import { PriceReporting } from '../PriceReporting'
import { Producer } from '../Producer'
import { Stop } from '../Stop'

export type MailsStatus = {
  history: {
    type: MailEnums
    date: Date
    emails: []
  }[]
}

export type DeliveryExpensesParameters = {
  truckType: CarrierFeesEnums.TruckTypeEnum
  fuelType: CarrierFeesEnums.FuelTypeEnum
  // in km
  distance: number
  // in hours
  orderPreparationTime: number
  // in hours
  deliveryTime: number
  // in euros per hour
  hourlyCost: number

  annexCosts: number
}

export type Tour = {
  id: string
  start: string
  end: string
  deposit: string
  close: string
  open?: string
  nbOrders?: number
  totalIncome?: string
  totalIncomeProducer?: string
  nbCrates?: number
  usedCapacity?: number
  carrierFees?: number
  platformFees?: number
  carrierExpenses?: number
  capacity?: number
  target: string
  dateCreation: string
  dateUpdate: string
  closing?: string
  group?: Group
  producerTour?: ProducerTour[]
  stops?: Stop[]
  totalWeight?: number
  deliveryExpensesParameters?: DeliveryExpensesParameters
  // OLD
  filling?: number
  carts?: Cart[]
  palletQuantity?: number
  batchs?: Batch[]
  areAllCartBatchIdentified?: boolean
  platformFeesRules?: PlatformFeesRules
  mailsStatus?: MailsStatus
  isPrivate?: boolean
  city?: City
  nbCartWithNoOrder?: number
  priceReportings?: PriceReporting[]
  carrier: Carrier
  status?: TourEnums.TourStatusEnum
  carrierFeesSharingRule?: CarrierFeesEnums.FeeSharingRuleEnum
  stepsData?: TutorialStepData[]
  minOrderValueHT?: number
  freeDeliveryOrderValueHT?: number
  deliveryFeesHT?: number
  paymentConditions?: string
}

export type ProducerTour = {
  id: string
  producer: Producer
  tour?: Tour
  nbOrders?: number
  nbCrates?: number
  totalIncome?: string
  usedCapacity?: number
  minOrderValueHT?: number
  orderEndDate?: string
  producerId?: string
  additionalCarrierFees?: number
}

export type AddTour = {
  carrierId?: string
  start?: Date
  end?: Date
  target?: string
  deposit?: Date
  open?: Date
  close?: Date
  capacity?: string
  carrierFees?: string
  carrierExpenses?: string
  palletQuantity?: string
  defaultDateHour?: Date
  isPrivate?: boolean
  cityId?: string
}

export type AddStop = {
  clientId?: string
}

export type UpdateTour = {
  id?: string
  start?: Date
  end?: Date
  target?: string
  deposit?: Date
  open?: Date
  close?: Date
  capacity?: string
  carrierFees?: string
  carrierExpenses?: string
  nbCrates?: number
  sumCA?: number
  usedCapacity?: number
  palletQuantity?: string
  isPrivate?: boolean
  carrierId?: string
  status?: TourEnums.TourStatusEnum
  minOrderValueHT?: string
  freeDeliveryOrderValueHT?: string
  deliveryFeesHT?: string
  paymentConditions?: string
}

export type ConcludeTour = {
  id?: string
  carrierExpenses?: string
}

export type DuplicateTour = {
  targetDate?: Date
  defaultDateHour?: Date
  withProducers?: boolean
  withStops?: boolean
  carrierId?: string
}

export type SendMail = {
  mailContent?: string
  mailSubject?: string
}

export const ToursFake: Tour[] = []
