import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Button, SectionTitle, Spacer } from '../../../components'
import { Carrier, CarrierGroup, Group, Pagination } from '../../../domain'
import i18n from '../../../i18n'
import GroupCarrierService from '../../../services/carrier/group'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import Loader from '../../../components/Loader'
import CardListPaginated from '../../../components/CardListPaginated'
import CarrierCard from '../../../modules/Carrier/CarrierCard'
import { TutorialStepData } from '../../../../enums/tutorialStep'
import { GroupEnums } from '../../../../enums/group'

const CarrierGroupCarriersScreen = () => {
  const { getAccessInfos } = useAuthenticationContext()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const [pageSizeCarrierGroups, setPageSizeCarrierGroups] = useState(5)
  const [pageNumberCarrierGroups, setPageNumberCarrierGroups] = useState(1)

  const {
    data: groupData,
    refetch,
    isInitialLoading: feedLoading,
  } = useQuery<{ group: Group; stepsData: TutorialStepData[] }, Error>(
    ['sf_group'],
    () =>
      GroupCarrierService.getOneGroup(getAccessInfos().carrierGroupId, getAccessInfos().carrierId),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: carrierGroups,
    refetch: refetchCarrierGroups,
    isLoading: isCarrierGroupsLoading,
  } = useQuery<{ data: CarrierGroup[]; pagination: Pagination }, Error>(
    ['sf_group_carriers'],
    () =>
      GroupCarrierService.getOneGroupCarriers(
        getAccessInfos().carrierGroupId,
        getAccessInfos().carrierId,
        pageSizeCarrierGroups,
        pageNumberCarrierGroups,
      ),
    {
      keepPreviousData: true,
    },
  )

  const OnClickAddCarrier = () => {
    if (!groupData?.group?.id) return
    navigation.navigate('CarrierAddCarrier', { groupId: groupData.group.id })
  }

  const onClickCarrier = (carrier: Carrier) => {
    navigation.navigate('CarrierUpdateCarrier', { id: carrier.id })
  }

  if (isCarrierGroupsLoading || !groupData?.group) {
    return <Loader isLoading pageLoading />
  }

  const onChangePage = async (pageNumber: number) => {
    await setPageNumberCarrierGroups(pageNumber)
    refetchCarrierGroups()
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <SectionTitle
          title={`${FormatUtils.getCarrierLabel(
            GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS,
            true,
            2,
          )} (${carrierGroups?.pagination?.totalCount})`}
          buttonRight={
            <Button
              small
              label={FormatUtils.capitalize(i18n.t('add'))}
              onPress={() => OnClickAddCarrier()}
            />
          }
        />
        <CardListPaginated
          data={carrierGroups?.data}
          pagination={carrierGroups?.pagination}
          element={function (CarrierGroup: any) {
            return (
              <CarrierCard
                key={CarrierGroup.id}
                group={groupData?.group}
                carrier={CarrierGroup.carrier}
                onClick={onClickCarrier}
              />
            )
          }}
          isLoading={isCarrierGroupsLoading}
          emptyMessage={`Aucun p${FormatUtils.getCarrierLabel(
            GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS,
          )} dans votre groupe`}
          onChangePage={onChangePage}
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={5} />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierGroupCarriersScreen
