import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useCallback, useEffect, useState } from 'react'
import { View } from 'react-native'
import {
  Button,
  Col,
  Dropdown,
  Icon,
  Icons,
  Row,
  Spacer,
  TextInput,
  Typography,
} from '../../../components'
import Loader from '../../../components/Loader'
import { Cart, Producer } from '../../../domain'
import { ClientStackParamList } from '../../../navigation/ClientNavigationStack/ClientNavigationStack.model'
import BatchClientService from '../../../services/client/batch'
import CartClientService from '../../../services/client/cart'
import TourClientService from '../../../services/client/tour'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { queryClient } from '../../../utilities/queryClient'
import StorageUtil from '../../../utilities/storage/storage'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import {
  BoxWrapper,
  StyledButtonWrapper,
  StyledLabel,
  StyledLabelTopView,
  StyledNextPageCard,
  TextInputWrapper,
} from './ClientCatalog.styles'
import CatalogProducerBatchs from './components/CatalogProducerBatchs'

import FormatUtils from '../../../utilities/utils/format'
import GeneralUtils from '../../../utilities/utils/general'
import BatchCatalog from '../../../modules/Batch/BatchCatalog'
import { LabelEnums } from '../../../../enums'
import { Category } from '../../../domain/Category'
import ClientCatalogNavBar from './components/ClientCatalogNavBar'
import CatalogClientNavigationStackParamList from '../../../navigation/ClientNavigationStack/CatalogClientNavigation/CatalogClientNavigation.model'
import ClientCatalogFilter from './components/ClientCatalogFilter'
import PaginationBar from '../../../components/PaginationBar'
import { getProductLabelsQO } from '../../../services/common/product'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { useTheme } from 'styled-components/native'

const ClientCatalogScreen = () => {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<ClientStackParamList>>()
  const catalogNavigation =
    useNavigation<StackNavigationProp<CatalogClientNavigationStackParamList>>()

  const [displayMode, setDisplayMode] = useState<number>(1)
  const [selectedCategory, setSelectedCategory] = useState<Category>()
  const [pageSizeBatchs, setPageSizeBatchs] = useState(16)
  const [pageNumberBatchs, setPageNumberBatchs] = useState(1)
  const [cartId, setCartId] = useState<string | undefined>(undefined)
  const [searchValue, setSearchValue] = useState('')
  const [pageNumberProducers, setPageNumberProducers] = useState(1)
  const [selectedLabels, setSelectedLabels] = useState<string[] | undefined>(undefined)
  const pageSizeProducers = 3

  const { getAccessInfos } = useAuthenticationContext()

  const {
    data: cart,
    refetch: refetchCart,
    isInitialLoading: cartLoading,
  } = useQuery<Cart | null, Error>(['c_cart', cartId], () => CartClientService.getOneCart(cartId), {
    keepPreviousData: false,
    onError: () => {
      onChangeTour()
    },
  })

  const { data: categoriesProducersFilter, isInitialLoading: categoriesProducersFilterLoading } =
    useQuery<{ categories: Category[]; producers: Producer[] }, Error>(
      ['c_categories'],
      () => TourClientService.getTourCategories(getAccessInfos().clientId, cart?.tour?.id),
      {
        keepPreviousData: true,
        enabled: cart?.tour !== undefined,
      },
    )

  const enabled =
    cart?.tour?.id !== undefined &&
    cart?.id !== undefined &&
    categoriesProducersFilter?.producers !== undefined &&
    categoriesProducersFilter?.producers?.length > 0 &&
    !categoriesProducersFilterLoading

  //@todo this is non sense, must be in some useCallBack hook with categoriesProducersFilter as deps
  function getProducersFromPagination() {
    const tempProducers = categoriesProducersFilter?.producers || []

    if (tempProducers?.length > 0 && displayMode === 2) {
      const firstIndex = (pageNumberProducers - 1) * pageSizeProducers
      const lastIndex = pageNumberProducers * pageSizeProducers
      const slicedProducers = tempProducers.slice(firstIndex, lastIndex)

      return slicedProducers.map((p) => p.id)
    }
    return undefined
  }

  const {
    data: catalog,
    refetch: refetchBatchs,
    isLoading: batchsLoading,
    isRefetching: batchsRefetching,
  } = BatchClientService.catalogExtended.get(
    {
      tourId: cart?.tour?.id!,
      cartId: cart?.id!,
      limit: displayMode === 1 ? pageSizeBatchs : 100,
      pageNumber: pageNumberBatchs,
      categories: selectedCategory?.id ? [selectedCategory.id] : undefined,
      producers: getProducersFromPagination(),
      search: searchValue,
      productLabels: selectedLabels,
    },
    enabled,
  )

  const productLabels = useQuery(getProductLabelsQO()).data

  useEffect(() => {
    const getStoredCart = async () => {
      let storedCartId = await StorageUtil.getItem('c_cart_id')
      const storedCartIdExpirationDate = await StorageUtil.getItem('c_cart_id_expiration_date')

      if (storedCartIdExpirationDate) {
        const isToOld = GeneralUtils.isDateBefore(
          new Date(JSON.parse(storedCartIdExpirationDate)),
          new Date(),
        )
        if (isToOld) {
          storedCartId = null
          StorageUtil.deleteItem('c_cart_id')
          StorageUtil.deleteItem('c_cart_id_expiration_date')
        }
      }

      if (storedCartId) {
        await setCartId(JSON.parse(storedCartId))
        refetchCart()
      }
    }
    getStoredCart()
  }, [])

  useEffect(() => {
    const getStoredParams = async () => {
      const storedDiplayMode = await StorageUtil.getItem('catalog_display_mode')
      if (storedDiplayMode) {
        const storedDiplayParsed = JSON.parse(storedDiplayMode)
        if (storedDiplayParsed === 1 || storedDiplayParsed === 2) {
          setDisplayMode(JSON.parse(storedDiplayMode))
        }
      }
    }
    getStoredParams()
  }, [])

  const onChangeCategory = useCallback(
    async (categoryId?: string) => {
      if (categoryId) {
        const filteredCategory = categoriesProducersFilter?.categories?.find(
          (category) => category.id === categoryId,
        )
        await setSelectedCategory(filteredCategory)
      } else {
        await setSearchValue('')
        await setSelectedCategory(undefined)
      }
      await setPageNumberBatchs(1)
    },
    [categoriesProducersFilter?.categories],
  )

  const clearFilters = useCallback(() => {
    setSearchValue('')
    setPageNumberBatchs(1)
    setTimeout(() => {
      refetchBatchs() // will execute with new states values
    }, 0)
  }, [refetchBatchs])

  /* 
  TextInput maintains internal state even when screen is unfocused -
  Clear search value on screen unfocus to prevent TextInput from restoring old value 
  **/
  useFocusEffect(
    useCallback(() => {
      clearFilters()
      return () => {
        setSearchValue('')
      }
    }, [clearFilters]),
  )

  useEffect(() => {
    refetchBatchs()
  }, [selectedLabels])

  useEffect(() => {
    const refreshData = async () => {
      await refetchBatchs()
    }
    refreshData()
  }, [pageNumberBatchs, selectedCategory])

  useEffect(() => {
    refetchCart()
  }, [cartId])

  const OnClickEndOrder = () => {
    navigation.navigate('ClientCart', {})
  }

  let batchs = catalog?.batchs || []
  let producers = categoriesProducersFilter?.producers || []
  const pagination = catalog?.pagination

  const onChangePageProducers = async (pageNumber: number) => {
    const maxPage = Math.ceil(producers.length / pageSizeProducers)
    if (pageNumber > 0 && pageNumber <= maxPage) {
      await setPageNumberProducers(pageNumber)
      refetchBatchs()
    }
  }

  const onChangeTour = async () => {
    await setCartId(undefined)

    StorageUtil.deleteItem('c_cart_id')
    await queryClient.setQueryData(['c_cart', cartId], null)
    await queryClient.setQueryData(['c_catalog'], null)

    batchs = []
    producers = []

    catalogNavigation.navigate('ClientCatalogTours', {})
  }

  const onChangeSearchValue = async (newSearchValue: string) => {
    setSearchValue(newSearchValue)
    await setPageNumberBatchs(1)
  }

  const onChangeDisplayMode = async () => {
    const newDisplayMode = displayMode === 1 ? 2 : 1
    StorageUtil.setItem('catalog_display_mode', newDisplayMode)
    await setPageNumberProducers(1)
    await setSelectedCategory(undefined)
    await setSearchValue('')
    setSelectedLabels(undefined)

    if (newDisplayMode === 2) {
      await setPageNumberProducers(1)
    }
    await setDisplayMode(newDisplayMode)
    await setPageNumberBatchs(1)
  }

  if (cartLoading) {
    return <Loader isLoading pageLoading />
  }

  const onChangePage = async (pageNumber: number) => {
    await setPageNumberBatchs(pageNumber)
  }

  const onChangeProductLabels = (selectedOptions: any[]) => {
    const selectedLabelIds = selectedOptions.map((option: any) => option.value)
    if (selectedLabelIds.length === 0) {
      setSelectedLabels(undefined)
      return
    }
    setSelectedLabels(selectedLabelIds)
  }

  const getOrderConditions = () => {
    let minOrderConditions = 'Aucun minimum de commande'
    let deliveryFeeConditions = 'pas de frais de livraison'
    let freeDeliveryonditions = 'aucun Franco'

    const tour = cart?.tour

    if (tour?.minOrderValueHT && tour?.minOrderValueHT > 0) {
      minOrderConditions = `Minimum de commande de ${FormatUtils.formatPrice(
        tour?.minOrderValueHT,
      )}`
    }

    if (tour?.deliveryFeesHT && tour?.deliveryFeesHT > 0) {
      deliveryFeeConditions = `frais de livraison de ${FormatUtils.formatPrice(
        tour?.deliveryFeesHT,
      )}`
    }

    if (tour?.freeDeliveryOrderValueHT && tour?.freeDeliveryOrderValueHT > 0) {
      freeDeliveryonditions = `franco de ${FormatUtils.formatPrice(tour?.freeDeliveryOrderValueHT)}`
    }

    return `${minOrderConditions}, ${deliveryFeeConditions}, ${freeDeliveryonditions}`
  }

  const displayNextPage = () => {
    if (
      !pagination?.page ||
      !pagination?.totalPages ||
      pagination?.page === pagination?.totalPages
    ) {
      return false
    }

    if (pagination.noFixedPages) {
      if (!batchs || batchs?.length < (pagination.limit || 0)) {
        return false
      }
      const elementsPassed = pagination?.page * (pagination?.limit || 1)
      if (elementsPassed >= pagination.totalCount || batchs.length >= pagination.totalCount) {
        return false
      }
    }

    return true
  }

  const onChangePageClick = (pageDiff: number) => {
    if (!pagination?.page) return
    const newPage = pagination?.page + pageDiff
    console.log(newPage)
    if (newPage < 1 || newPage > pagination.totalPages) return
    onChangePage(newPage)
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper fullWidth>
        <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
        {cart && (
          <ClientCatalogNavBar
            cart={cart}
            tour={cart.tour}
            onChangeTour={onChangeTour}
            displayMode={displayMode}
            onChangeDisplayMode={onChangeDisplayMode}
          />
        )}
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        {cart?.targetCart && (
          <>
            <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.CardTitle>
              Modification de la commande #{FormatUtils.formatId(cart.order?.id)}
            </Typography.CardTitle>
            <Typography.BodySmall colorName="text-dark-3">
              Validée initialement le{' '}
              {FormatUtils.capitalize(
                FormatUtils.formatDate(cart.order?.createdDate, 'FullDate+StartTime'),
              )}
            </Typography.BodySmall>
            <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
          </>
        )}

        <Typography.BodySmall colorName="text-dark-3">{getOrderConditions()}</Typography.BodySmall>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
        {catalog?.clientLabels?.find((cl) => cl === LabelEnums.BIOCOOP) && (
          <StyledLabelTopView>
            <StyledLabel color="#0c4da2">
              <Typography.BodySmall colorName="text-light-1" bold>
                Biocoop
              </Typography.BodySmall>
            </StyledLabel>
            <Typography.BodySmall colorName="text-dark-3">
              {' '}
              produits provenant de producteurs certifiés Biocoop
            </Typography.BodySmall>
          </StyledLabelTopView>
        )}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1.5} />

        {catalog && categoriesProducersFilter && displayMode === 1 && (
          <>
            <Row>
              <Col xs={12} md={6} lg={6}>
                <ClientCatalogFilter
                  categories={categoriesProducersFilter?.categories}
                  selectedCategory={selectedCategory?.id}
                  onChangeCategory={onChangeCategory}
                />
              </Col>
              <Col xs={12} md={3} lg={3} xsAlignItems="flex-start" smAlignItems="flex-start">
                <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInputWrapper>
                  <TextInput
                    field="text"
                    label="Rechercher par nom"
                    value={searchValue}
                    onChangeText={onChangeSearchValue}
                    onBlurCallBack={refetchBatchs}
                    onKeyPressCallBack={refetchBatchs}
                    small
                  />
                </TextInputWrapper>
              </Col>
              <Col xs={12} md={3} lg={3}>
                <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                <Dropdown
                  label={'Filtrer par labels'}
                  onChange={onChangeProductLabels}
                  multiple={true}
                  defaultValue={selectedLabels}
                  optionsDefault={
                    productLabels?.map((productLabel) => ({
                      label: productLabel.label,
                      value: productLabel.id,
                    })) ?? []
                  }
                  zIndex={3}
                  zIndexInverse={3}
                  small={true}
                />
              </Col>
            </Row>
          </>
        )}
        {batchsLoading || batchsRefetching ? (
          <Loader isLoading withText />
        ) : (
          <>
            {cart && pagination && pagination.totalCount > 0 ? (
              <>
                {displayMode === 1 && (
                  <>
                    <View style={{ width: '100%' }}>
                      <BoxWrapper>
                        <Row>
                          {batchs.map((batch, index) => (
                            <Col
                              key={batch.id}
                              xs={12}
                              sm={6}
                              md={4}
                              lg={3}
                              smAlignItems="center"
                              xsAlignItems="center"
                            >
                              <BatchCatalog
                                key={batch.id}
                                cart={cart}
                                batch={batch}
                                clientLabels={catalog?.clientLabels}
                                displayProducer
                                group={cart.tour?.group}
                                testID={`batch-catalog-${index}`}
                              />
                            </Col>
                          ))}
                          {displayNextPage() && batchs.length % 4 !== 0 && (
                            <Col
                              key={'next-page'}
                              xs={12}
                              sm={6}
                              md={4}
                              lg={3}
                              alignItems="center"
                              smAlignItems="center"
                              xsAlignItems="center"
                            >
                              <StyledNextPageCard>
                                <TouchableOpacity
                                  onPress={() => onChangePageClick(1)}
                                  style={{ alignItems: 'center' }}
                                >
                                  <Icon
                                    type={Icons.Ionicons}
                                    name="chevron-forward-outline"
                                    size={theme.spacingUnit * 4}
                                    color={theme.colors['color-primary']}
                                  />
                                  <Typography.Body colorName="color-primary" bold align="center">
                                    Page suivante
                                  </Typography.Body>
                                </TouchableOpacity>
                              </StyledNextPageCard>
                            </Col>
                          )}
                        </Row>
                      </BoxWrapper>
                    </View>
                    <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
                    <PaginationBar
                      data={batchs}
                      pagination={pagination}
                      onChangePage={onChangePage}
                    />
                  </>
                )}

                {displayMode === 2 && (
                  <>
                    {producers.map((producer) => {
                      const producerBatchs = batchs.filter(
                        (batch) => batch.product?.producer?.id === producer.id,
                      )
                      if (producerBatchs.length > 0) {
                        return (
                          <CatalogProducerBatchs
                            key={producer.id}
                            cart={cart}
                            producer={producer}
                            batchs={producerBatchs}
                            clientLabels={catalog?.clientLabels}
                            group={cart.tour?.group}
                          />
                        )
                      }
                    })}
                    <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />

                    <PaginationBar
                      data={getProducersFromPagination()}
                      pagination={{
                        page: pageNumberProducers,
                        totalPages: Math.ceil(producers.length / pageSizeProducers),
                        totalCount: producers.length,
                      }}
                      onChangePage={onChangePageProducers}
                      isBig
                    />
                  </>
                )}

                <Spacer size={5} axis={Spacer.AxisEnum.VERTICAL} />

                <StyledButtonWrapper>
                  <Button small label="Finaliser la commande" onPress={() => OnClickEndOrder()} />
                </StyledButtonWrapper>
                <Spacer size={4} axis={Spacer.AxisEnum.VERTICAL} />
              </>
            ) : (
              <>
                <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
                {(batchsLoading || cartLoading) && <Loader withText isLoading />}
                {catalog && catalog.batchs && catalog.batchs.length === 0 ? (
                  <Typography.Body>Aucun produit disponible pour cette tournée</Typography.Body>
                ) : (
                  <Loader withText isLoading />
                )}
              </>
            )}
          </>
        )}

        <Spacer size={4} axis={Spacer.AxisEnum.VERTICAL} />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ClientCatalogScreen
