import { useQuery } from '@tanstack/react-query'
import { Account, SimplifiedAccountWithUnbilledCountDto } from '../../../domain/Account'
import { Producer } from '../../../domain'
import ApiService from '../../api.service'
import { queryClient } from '../../../utilities/queryClient'
import { PaymentMethodEnum } from '../../../../enums/payment'

const getOne = async (accountId: string): Promise<Account> => {
  const response = await ApiService.get(`/account/${accountId}`, {})
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const update = async (accountId: string, account: any): Promise<Account> => {
  const response = await ApiService.patch(`/account/${accountId}`, account)
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

// ================== Stripe Client

const getBillingPortalURL = async (idAccount: string): Promise<string> => {
  const response = await ApiService.get(`/account/${idAccount}/billing_portal`, {})
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getPaymentMethods = async (
  idAccount: string,
): Promise<{
  count: number
  stripeCustomerId: string
  defaultPaymentMethod: PaymentMethodEnum
  paymentMethods: { type: PaymentMethodEnum }[]
}> => {
  const response = await ApiService.get(`/account/${idAccount}/payment_methods`, {})
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const activateWireTransferPayment = async (idAccount: string): Promise<Account> => {
  const response = await ApiService.post(`/account/${idAccount}/activate-wire-payment`, {}).then(
    (result) => result,
  )
  return response
}

const getManyAccounts = async (filter: {
  unbilled?: boolean
  isBillable?: boolean
  useSimplified?: boolean
  addUnbilledDNCount?: boolean
  invoicedByIssuerId?: string
}): Promise<SimplifiedAccountWithUnbilledCountDto[]> => {
  const response = await ApiService.get(`/account`, {
    unbilled: filter?.unbilled,
    isBillable: filter?.unbilled,
    useSimplified: filter?.useSimplified,
    addUnbilledDNCount: filter?.addUnbilledDNCount,
    invoicedByIssuerId: filter?.invoicedByIssuerId,
  })
  return response
}

const getManyAccountsAdmin = async (filter: {
  unbilled?: boolean
  isBillable?: boolean
  useSimplified?: boolean
  addUnbilledDNCount?: boolean
  invoicedByIssuerId?: string
}): Promise<SimplifiedAccountWithUnbilledCountDto[]> => {
  const response = await ApiService.get(`/account/admin`, {
    unbilled: filter?.unbilled,
    isBillable: filter?.unbilled,
    useSimplified: filter?.useSimplified,
    addUnbilledDNCount: filter?.addUnbilledDNCount,
    invoicedByIssuerId: filter?.invoicedByIssuerId,
  })
  return response
}

export type UseGetManyAccountsArgs = {
  profileId: string
  unbilled?: boolean
  isBillable?: boolean
  useSimplified?: boolean
  addUnbilledDNCount?: boolean
  invoicedByIssuerId?: string
}

export type UseGetManyAccountsAdminArgs = {
  unbilled?: boolean
  isBillable?: boolean
  useSimplified?: boolean
  addUnbilledDNCount?: boolean
  invoicedByIssuerId?: string
}

function useGetManyAccounts(args: UseGetManyAccountsArgs) {
  function getQueryKey() {
    return [
      'accounts',
      args.profileId,
      args.unbilled ? 'unbilled' : '',
      args.isBillable ? 'isBillable' : '',
      args.useSimplified ? 'useSimplified' : '',
      args.addUnbilledDNCount ? 'addUnbilledDNCount' : '',
      args.invoicedByIssuerId ? 'invoicedByIssuerId' : '',
    ].filter(Boolean)
  }
  function query() {
    return useQuery<SimplifiedAccountWithUnbilledCountDto[], Error>({
      queryKey: getQueryKey(),
      queryFn: () => getManyAccounts(args),
      keepPreviousData: true,
    })
  }

  function invalidate() {
    queryClient.invalidateQueries(getQueryKey())
  }

  return {
    getQueryKey,
    query,
    invalidate,
  }
}

function useGetManyAccountsAdmin(args: UseGetManyAccountsAdminArgs) {
  function getQueryKey() {
    return [
      'accounts-admin',
      args.unbilled ? 'unbilled' : '',
      args.isBillable ? 'isBillable' : '',
      args.useSimplified ? 'useSimplified' : '',
      args.addUnbilledDNCount ? 'addUnbilledDNCount' : '',
      args.invoicedByIssuerId ? 'invoicedByIssuerId' : '',
    ].filter(Boolean)
  }
  function query() {
    return useQuery<SimplifiedAccountWithUnbilledCountDto[], Error>({
      queryKey: getQueryKey(),
      queryFn: () => getManyAccountsAdmin(args),
      keepPreviousData: true,
    })
  }

  function invalidate() {
    queryClient.invalidateQueries(getQueryKey())
  }

  return {
    getQueryKey,
    query,
    invalidate,
  }
}

// ================== Producer Stripe Account

const createStripeAccount = async (
  newStripeAccountToken: string,
  accountId: string,
  producer: Producer,
): Promise<Account> => {
  const response = await ApiService.post(`/account/${accountId}/stripe-account`, {
    stripeAccountToken: newStripeAccountToken,
    label: producer.label,
    addressLine1: producer.address?.addressLine1,
    addressLine2: producer.address?.addressLine1,
    postalCode: producer.address?.addressLine1,
    city: producer.address?.addressLine1,
    phone1: producer.phone1,
    mail: producer.mail,
  }).then((result) => result)
  return response
}

const getStripeAccount = async (idAccount: string): Promise<Account> => {
  const response = await ApiService.get(`/account/${idAccount}/stripe-account`, {}).then(
    (result) => result,
  )
  return response
}

const getStripeAccountOnboardingUrl = async (idAccount: string): Promise<string> => {
  const response = await ApiService.get(`/account/${idAccount}/stripe-account-onboarding`, {}).then(
    (result) => result,
  )
  return response
}

const AccountService = {
  getBillingPortalURL,
  getPaymentMethods,
  activateWireTransferPayment,
  useGetManyAccounts,
  useGetManyAccountsAdmin,
  getOne,
  update,
  createStripeAccount,
  getStripeAccount,
  getStripeAccountOnboardingUrl,
}

export default AccountService
