import { RouteProp, useFocusEffect, useNavigation, useRoute } from '@react-navigation/native'
import React, { useCallback, useState } from 'react'
import { useTheme } from 'styled-components/native'
import { Button, PageTitle, SectionTitle, Spacer, Typography } from '../../../components'
import { Address, Cart, Client, DeliveryNote, Order, Stop, Tour } from '../../../domain'
import BatchCartCard from '../../../modules/Batch/BatchCartCard'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import CarrierTourClientTopBox from './components/CarrierTourClientTopBox'
import { ToursContentWrapper } from './CarrierTourClient.styles'
import StopCarrierService from '../../../services/carrier/stop'
import { useQuery } from '@tanstack/react-query'
import TourCarrierService from '../../../services/carrier/tour'
import FormatUtils from '../../../utilities/utils/format'
import { StackNavigationProp } from '@react-navigation/stack'
import { queryClient } from '../../../utilities/queryClient'
import { Alert, View } from 'react-native'
import Loader from '../../../components/Loader'
import OrderCarrierService from '../../../services/carrier/order'
import DeliveryNoteCarrierService from '../../../services/carrier/deliveryNote'
import TourDeliveryNoteCard from '../../../modules/DeliveryNote/TourDeliveryNoteCard'
import OrderHistory from '../../../modules/Order/OrderHistory'
import OrderCard from '../../../modules/Order/OrderCard'
import { UserEnums } from '../../../../enums'
import TourUtil from '../../../utilities/utils/tour'
import { GroupEnums } from '../../../../enums/group'
import useGroupContext from '../../../utilities/hook/useGroupContext'
import CardSettings from '../../../components/CardSettings'
import StorageUtil from '../../../utilities/storage/storage'
import CartCarrierService from '../../../services/carrier/cart'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { TutorialStepData } from '../../../../enums/tutorialStep'
import AddressService from '../../../services/common/address'
import AccountingDocumentCard from '../../../components/AccountingDocumentCard'
import DeliveryNoteStatementService from '../../../services/delivery-note-statement'

const CarrierTourClientScreen = () => {
  const theme = useTheme()
  const { group } = useGroupContext()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierTourClient'>>()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const { getAccessInfos } = useAuthenticationContext()

  const { id, tourId, cartId } = route?.params ?? { id: '', tourId: '', cartId: '' }

  if (!id || !tourId) return null

  const {
    data: stop,
    refetch: refetchStop,
    isInitialLoading: stopLoading,
  } = useQuery<Stop, Error>(['sf_stop', id], () => StopCarrierService.getOneStop(tourId, id), {
    keepPreviousData: true,
    select: FormatUtils.stopRankingFormatter,
  })

  const {
    data: tour,
    refetch: refetchTour,
    isInitialLoading: tourLoading,
  } = TourCarrierService.getOne.query(tourId)

  const {
    data: lastOrders,
    refetch: refetchLastOrder,
    isInitialLoading: LastOrderLoading,
  } = useQuery<Order[], Error>(
    ['sf_client_last_orders', id, tourId],
    () => OrderCarrierService.getLastOrdersClient(tourId, stop?.client?.id),
    {
      enabled: stop?.client?.id !== undefined,
    },
  )

  const {
    data: deliveryNotes,
    refetch: refetchDeliveryNotes,
    isInitialLoading: deliveryNotesLoading,
  } = useQuery<DeliveryNote[], Error>(
    ['sf_tour_delivery_notes', tourId, stop?.client.id],
    () => DeliveryNoteCarrierService.getAllTour(tourId, undefined, stop?.client.id, undefined),
    {
      keepPreviousData: true,
      enabled: stop?.client.id !== undefined,
    },
  )

  const { query: deliveryNoteStatementQuery } =
    DeliveryNoteStatementService.useFindDeliveryNoteStatements({
      tourId: tourId,
      recipientId: stop?.client?.account?.id,
      limit: 10,
      pageNumber: 1,
    })

  const {
    data: deliveryNoteStatementsData,
    refetch: refetchDeliveryNoteStatements,
    isLoading: deliveryNoteStatementsLoading,
  } = deliveryNoteStatementQuery()

  const getOrderIdFormatted = (order?: Order) => {
    if (order && order.id && order.id.length > 5) {
      return order.id.slice(-5)
    }
    return 'NA'
  }

  useFocusEffect(
    useCallback(() => {
      refetchDeliveryNoteStatements()
    }, [refetchDeliveryNoteStatements]),
  )

  if (tourLoading || stopLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!stop || !tour) return null

  const onDeleteStop = async () => {
    if (!tour || stop.carts) return

    await StopCarrierService.remove(tour.id, stop.id)
    TourCarrierService.getOne.invalidate(tour.id)

    navigation.navigate('CarrierTourClients', { id: tour.id })
  }

  const groupTypeIsDeliveryProducers = group?.type === GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS

  const onCreateNewOrder = async (client: Client) => {
    if (!client.id) return
    const createdCart = await CartCarrierService.create(client.id, getAccessInfos().carrierId, {
      tourId: tour.id,
    })
    if (createdCart && createdCart.id) {
      await StorageUtil.setItem('sf_cart_id', createdCart.id)
      await StorageUtil.setItem('sf_cart_is_new_order', createdCart.id)
      const now = new Date()
      now.setMinutes(now.getMinutes() + 60)
      await StorageUtil.setItem('sf_cart_id_expiration_date', now)
      navigation.navigate('CarrierUpdateCart', {
        stopId: stop.id,
        cartId: createdCart.id,
        tourId: tour.id,
      })
    } else {
      Alert.alert('Erreur lors de la création du panier')
    }
  }

  const onUpdateOrder = async (cart: Cart) => {
    if (!cart.id) return
    const updatedCart = await CartCarrierService.createUpdateOrder(stop.client.id, cart.id)
    if (updatedCart && updatedCart.id) {
      await StorageUtil.setItem('sf_cart_id', updatedCart.id)
      const now = new Date()
      now.setMinutes(now.getMinutes() + 60)
      await StorageUtil.setItem('sf_cart_id_expiration_date', now)
      navigation.navigate('CarrierUpdateCart', {
        stopId: stop.id,
        cartId: updatedCart.id,
        tourId: tour.id,
      })
      return
    }
  }

  const onDeleteOrder = async (orderId: string | undefined) => {
    if (!orderId) return
    if (groupTypeIsDeliveryProducers) {
      setErrorMessage(
        `Vous ne pouvez pas supprimer de commande car votre groupe est en mode ${FormatUtils.getCarrierLabel(
          GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS,
        )}`,
      )
      return false
    }

    if ((deliveryNotes || []).length > 0) {
      setErrorMessage('Vous devez supprimer les BL afin de supprimer la commande')
      return false
    }
    setIsLoading(true)

    await OrderCarrierService.deleteOne(orderId)
    await refetchStop()
    TourCarrierService.getOne.invalidate(tourId)
    setIsLoading(false)
  }

  const onClickCartBatch = (cartBatchId: string, cartId: string) => {
    if (groupTypeIsDeliveryProducers) {
      return
    }
    navigation.navigate('CarrierUpdateCartBatch', {
      id: cartBatchId,
      cartId: cartId,
      clientId: stop?.client.id,
      tourId: tourId,
    })
  }

  const onClickDeliveryNote = (deliveryNote: DeliveryNote) => {
    if (!deliveryNote.id) return
    navigation.navigate('CarrierTourDeliveryNote', {
      deliveryNoteId: deliveryNote.id,
    })
  }

  const hasNoOrders = stop.nbOrders === 0

  const carts = stop.carts
  const renderCarts = () => {
    if (!carts || carts.length < 1) return null
    return carts.map((cart) => {
      return (
        <View key={cart.id}>
          <SectionTitle title={`Commande #${getOrderIdFormatted(cart.order)}`} />
          <Typography.BodySmall style={{ marginLeft: theme.spacingUnit * 2 + 'px' }}>
            Validée le {FormatUtils.formatDate(cart.order?.createdDate, 'FullDate')} à{' '}
            {FormatUtils.formatDate(cart.order?.createdDate, 'Time')}
          </Typography.BodySmall>
          {cart.cartBatchs?.map((cartBatch) => (
            <BatchCartCard
              key={cartBatch.id}
              cartBatch={cartBatch}
              onClick={() => onClickCartBatch(cartBatch.id, cart.id)}
              withProducer
              userRole={UserEnums.RoleEnums.CARRIER}
            />
          ))}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          {TourUtil.isTourCompleted(tour) && (
            <View style={{ width: '100%', alignItems: 'center' }}>
              <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
              <Typography.Body colorName="color-grey">
                Cette tournée est terminée, vous ne pouvez plus ajouter de lot pour ce client.
              </Typography.Body>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            </View>
          )}
          <SectionTitle title={`Actions`} />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <CardSettings
            title="Un changement sur la commande ?"
            // description={`Vous pouvez ajouter / modifier / supprimer des lots`}
            description={
              groupTypeIsDeliveryProducers
                ? `Vous ne pouvez pas modifier de commande car votre groupe est en mode ${FormatUtils.getCarrierLabel(
                    GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS,
                  )}`
                : `Vous pouvez ajouter / modifier / supprimer des lots`
            }
            children={
              <Button
                fullWidth={true}
                colorName="color-grey"
                label={'Modifier'}
                loading={isLoading}
                onPress={() => onUpdateOrder(cart)}
                disabled={groupTypeIsDeliveryProducers}
              />
            }
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <CardSettings
            title="Une annulation ?"
            // description={`Le client souhaite annuler sa commande`}
            description={
              groupTypeIsDeliveryProducers
                ? `Vous ne pouvez pas supprimer de commande`
                : `Le client souahite annuler sa commande`
            }
            children={
              <Button
                fullWidth={true}
                colorName="color-grey"
                label={'Supprimer'}
                loading={isLoading}
                onPress={() => onDeleteOrder(cart.order?.id)}
                disabled={groupTypeIsDeliveryProducers}
                hasDoubleValidation
                confirmationLabel="Êtes-vous sûr de vouloir supprimer cette commande ?"
              />
            }
          />
          <Spacer size={2.5} axis={Spacer.AxisEnum.VERTICAL} />
          <OrderHistory order={cart.order} />
        </View>
      )
    })
  }

  const handleUpdateAddress = async (addressId: string, addressToUpdate: Partial<Address>) => {
    await AddressService.update(addressId, addressToUpdate)
    queryClient.invalidateQueries(['sf_stop', id])
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle
            title={`Etape ${stop.formattedRanking}`}
            buttonRight={
              <View>
                <Typography.BodySmall>
                  {FormatUtils.capitalize(FormatUtils.formatDate(tour.start, 'FullDate'))}
                  {'\n'}
                  {FormatUtils.capitalize(
                    FormatUtils.formatDate(tour.start, 'StartTime+EndTime', tour.end),
                  )}
                </Typography.BodySmall>
              </View>
            }
          />
          <CarrierTourClientTopBox
            stop={stop}
            lastOrders={lastOrders}
            onUpdateAddress={handleUpdateAddress}
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          {errorMessage ? (
            <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
          ) : null}
          {carts && carts.length > 0 ? (
            renderCarts()
          ) : (
            <View>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />

              <Typography.Body colorName="color-danger" align="center">
                Aucune commande à livrer pour ce {FormatUtils.getLabelFromClientType(1)}
              </Typography.Body>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
              {TourUtil.isTourCompleted(tour) && (
                <View style={{ width: '100%', alignItems: 'center' }}>
                  <Typography.Body colorName="color-grey">
                    Cette tournée est terminée, vous ne pouvez plus supprimer ce client.
                  </Typography.Body>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
                </View>
              )}
              {hasNoOrders && (
                <CardSettings
                  title="Créer une commande"
                  description={
                    groupTypeIsDeliveryProducers
                      ? `Vous ne pouvez pas créer de commande pour ce client car votre groupe est en mode ${FormatUtils.getCarrierLabel(
                          GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS,
                        )}`
                      : `Créer une nouvelle commande pour ce client`
                  }
                  children={
                    <Button
                      fullWidth={true}
                      colorName="color-grey"
                      label={'Créer'}
                      loading={isLoading}
                      onPress={() => onCreateNewOrder(stop.client)}
                      disabled={groupTypeIsDeliveryProducers}
                    />
                  }
                />
              )}
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
              <CardSettings
                title="Supprimer cette étape"
                description={`Vous pouvez supprimer cette étape de la tournée si le client n'a pas encore passé commande`}
                children={
                  <Button
                    fullWidth={true}
                    colorName="color-grey"
                    label={'Supprimer'}
                    loading={isLoading}
                    onPress={() => onDeleteStop()}
                    hasDoubleValidation
                    confirmationLabel="Êtes-vous sûr de vouloir supprimer cet arrêt ?"
                    disabled={TourUtil.isTourCompleted(tour)}
                  />
                }
              />
            </View>
          )}
          <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
          {(deliveryNoteStatementsData?.data || []).length > 0 && (
            <Typography.SectionTitle colorName="text-dark-3">
              Bon de commande
            </Typography.SectionTitle>
          )}

          {(deliveryNoteStatementsData?.data || []).map((dns) => (
            <AccountingDocumentCard
              key={dns.id}
              item={dns}
              displayRecipient
              onClick={() =>
                navigation.navigate('CarrierDeliveryNoteStatement', {
                  deliveryNoteStatementId: dns.id,
                })
              }
              type="delivery-note-statement"
            />
          ))}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />

          {(deliveryNotes || []).length > 0 && (
            <Typography.SectionTitle colorName="text-dark-3">
              Bons de livraison
            </Typography.SectionTitle>
          )}
          {(deliveryNotes || []).map((deliveryNote) => (
            <TourDeliveryNoteCard
              key={deliveryNote.id}
              deliveryNote={deliveryNote}
              onClick={onClickDeliveryNote}
              isCarrier={false}
            />
          ))}
          <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
          {(lastOrders || []).length > 0 && (
            <Typography.SectionTitle colorName="text-dark-3">
              Précédentes commandes (max. 3)
            </Typography.SectionTitle>
          )}
          {(lastOrders || []).map((order) => (
            <OrderCard key={order.id} order={order} onClick={() => null} />
          ))}
          <Spacer size={4} axis={Spacer.AxisEnum.VERTICAL} />
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierTourClientScreen
