import { useQuery } from '@tanstack/react-query'
import { TutorialStepData } from '../../../../enums/tutorialStep'
import {
  CarrierGroup,
  ClientGroup,
  Group,
  GroupRequest,
  Pagination,
  ProducerGroup,
} from '../../../domain'
import ApiService from '../../api.service'

const getOneGroup = async (
  groupId: string,
  carrierId: string,
): Promise<{ group: Group; stepsData: TutorialStepData[] }> => {
  const response = await ApiService.get(`/group/${groupId}/carrier/${carrierId}`).catch((error) => {
    throw error
  })
  return response
}

const getOneGroupProducers = async (
  groupId: string,
  carrierId: string,
  limit: number = 30,
  pageNumber: number = 1,
  search?: string,
  tourId?: string,
  isArchived?: boolean,
): Promise<{ data: ProducerGroup[]; pagination: Pagination }> => {
  const response = await ApiService.get(`/group/${groupId}/carrier/${carrierId}/producers`, {
    limit,
    page: pageNumber,
    search,
    tourId,
    isArchived,
  }).catch((error) => {
    throw error
  })
  return response
}

function getOneGroupProducersKey(args: UseGetOneGroupProducersArgs) {
  return Object.values(args)
    .filter((arg) => arg !== undefined)
    .push('sf_group_producers')
}

export type UseGetOneGroupProducersArgs = {
  groupId: string
  carrierId: string
  tourId?: string
  limit: number
  page: number
  search?: string
}

function useGetOneGroupProducers({
  groupId,
  carrierId,
  tourId,
  limit,
  page,
  search,
}: UseGetOneGroupProducersArgs) {
  return useQuery<{ data: ProducerGroup[]; pagination: Pagination }, Error>({
    queryKey: [getOneGroupProducersKey({ groupId, carrierId, tourId, limit, page, search })],
    queryFn: async () => {
      return getOneGroupProducers(groupId, carrierId, limit, page, search, tourId)
    },
    keepPreviousData: true,
  })
}

const getOneGroupRequests = async (
  groupId: string,
  carrierId: string,
  limit: number = 30,
  pageNumber: number = 1,
): Promise<{
  data: { groupRequests: GroupRequest[]; groupRequestsPending: GroupRequest[] }
  pagination: Pagination
}> => {
  const response = await ApiService.get(`/group/${groupId}/carrier/${carrierId}/requests`, {
    limit,
    page: pageNumber,
  }).catch((error) => {
    throw error
  })
  return response
}

const getOneProducerGroup = async (
  groupId: string,
  carrierId: string,
  producerId: string,
): Promise<ProducerGroup> => {
  const response = await ApiService.get(
    `/group/${groupId}/carrier/${carrierId}/producer/${producerId}`,
  ).catch((error) => {
    throw error
  })
  return response
}

const updateProducerGroup = async (
  groupId: string,
  carrierId: string,
  producerId: string,
  producerGroup: any,
): Promise<ProducerGroup> => {
  const response = await ApiService.patch(
    `/group/${groupId}/carrier/${carrierId}/producer/${producerId}`,
    producerGroup,
  ).catch((error) => {
    throw error
  })
  return response
}

const archiveProducerGroup = async (
  groupId: string,
  carrierId: string,
  producerId: string,
): Promise<ProducerGroup> => {
  const response = await ApiService.patch(
    `/group/${groupId}/carrier/${carrierId}/producer/${producerId}/archive`,
    {},
  ).catch((error) => {
    throw error
  })
  return response
}

export const getOneCarrierGroup = async (
  groupId: string,
  carrierId: string,
  carrierToUpdateId: string,
): Promise<CarrierGroup> => {
  const response = await ApiService.get(
    `/group/${groupId}/carrier/${carrierId}/carrier/${carrierToUpdateId}`,
  ).catch((error) => {
    throw error
  })
  return response
}

const getOneGroupClients = async (
  groupId: string,
  carrierId: string,
  limit: number = 30,
  pageNumber: number = 1,
  search?: string,
  isArchived?: boolean,
): Promise<{ data: ClientGroup[]; pagination: Pagination }> => {
  const response = await ApiService.get(`/group/${groupId}/carrier/${carrierId}/clients`, {
    limit,
    page: pageNumber,
    search,
    isArchived,
  }).catch((error) => {
    throw error
  })
  return response
}
const getOneGroupCarriers = async (
  groupId: string,
  carrierId: string,
  limit: number = 30,
  pageNumber: number = 1,
): Promise<{ data: CarrierGroup[]; pagination: Pagination }> => {
  const response = await ApiService.get(`/group/${groupId}/carrier/${carrierId}/carriers`, {
    limit,
    page: pageNumber,
  }).catch((error) => {
    throw error
  })
  return response
}

const getOneClientGroup = async (
  groupId: string,
  carrierId: string,
  clientId: string,
): Promise<ClientGroup> => {
  const response = await ApiService.get(
    `/group/${groupId}/carrier/${carrierId}/client/${clientId}`,
  ).catch((error) => {
    throw error
  })
  return response
}

const archiveClientGroup = async (
  groupId: string,
  carrierId: string,
  clientId: string,
): Promise<ClientGroup> => {
  const response = await ApiService.patch(
    `/group/${groupId}/carrier/${carrierId}/client/${clientId}/archive`,
    {},
  ).catch((error) => {
    throw error
  })
  return response
}

const getOneGroupAdmins = async (groupId: string, carrierId: string) => {
  const response = await ApiService.get(
    `/user/carrier_users/group/${groupId}/carrier/${carrierId}`,
    {},
  ).catch((error) => {
    throw error
  })
  return response
}

const update = async (groupId: string, group: any): Promise<Group> => {
  const response = await ApiService.patch(`/group/${groupId}`, group).catch((error) => {
    throw error
  })
  return response
}

const updateGroupRequest = async (
  groupId: string,
  groupRequestId: string,
  groupRequest: GroupRequest,
): Promise<Group> => {
  const response = await ApiService.patch(
    `/group/${groupId}/request/${groupRequestId}`,
    groupRequest,
  ).catch((error) => {
    throw error
  })
  return response
}

const createGroup = async (carrierId: string, newGroup: any): Promise<Group> => {
  const response = await ApiService.post(`carrier/group`, newGroup).catch((error) => {
    throw error
  })
  return response
}

const GroupCarrierService = {
  getOneGroup,
  getOneGroupRequests,
  getOneGroupProducers,
  useGetOneGroupProducers,
  getOneProducerGroup,
  getOneGroupClients,
  getOneGroupCarriers,
  getOneClientGroup,
  getOneGroupAdmins,
  getOneCarrierGroup,
  update,
  updateGroupRequest,
  updateProducerGroup,
  archiveProducerGroup,
  archiveClientGroup,
  createGroup,
}

export default GroupCarrierService
