import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import DeviceUtil from '../../utilities/utils/device'
import { NavigationItemType } from '../Navigation.model'
import { Icons } from '../../components'
import HeaderHome from '../components/HeaderHome'
import BottomTabs from '../components/BottomTabs'
import CustomDrawer from '../components/Drawer'

import AdminDashboardScreen from '../../screens/Admin/AdminDashboard'
import BillingAdminNavigation from './BillingAdminNavigation/BillingAdminNavigation'

const navigationItems: NavigationItemType[] = [
  {
    route: 'AdminDashboard',
    label: 'Accueil',
    type: Icons.Ionicons,
    icon: 'home',
    component: AdminDashboardScreen,
    screenOptions: {
      headerShown: true,
      header: () => <HeaderHome />,
    },
  },
  {
    route: 'AdminBilling',
    label: 'Finances',
    type: Icons.Ionicons,
    icon: 'wallet',
    component: BillingAdminNavigation,
    screenOptions: {
      headerShown: false,
    },
  },
]

const Stack = createStackNavigator()

const AdminMainNavigationStack = () => {
  if (DeviceUtil.isMobileApp()) {
    return <BottomTabs items={navigationItems} />
  } else {
    return <CustomDrawer items={navigationItems} />
  }
}

export default AdminMainNavigationStack
