import { TourCloseSlideProps } from './TourCloseSlide.model'
import {
  Button,
  Col,
  DatePicker,
  Row,
  SectionTitle,
  Spacer,
  Typography,
} from '../../../../../components'
import { View } from 'react-native'
import { ButtonWrapper } from './TourCloseSlide.styles'
import { AddTour } from '../../../../../domain'
import { useState } from 'react'
import GeneralUtils from '../../../../../utilities/utils/general'
import InfoBox from '../../../../../components/InfoBox'
import FormatUtils from '../../../../../utilities/utils/format'
import { GroupEnums } from '../../../../../../enums/group'

const TourCloseSlide = ({ onClick, tour }: TourCloseSlideProps) => {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [dateIsInvalid, setDateIsInvalid] = useState<boolean>(false)
  const [timeIsInvalid, setTimeIsInvalid] = useState<boolean>(false)

  let defaultDateHour = new Date()

  if (tour?.deposit && tour?.deposit?.getUTCHours() < 13) {
    defaultDateHour = new Date(tour.deposit || '')
    defaultDateHour.setUTCDate(defaultDateHour.getUTCDate() - 1)
    defaultDateHour.setUTCHours(16)
    defaultDateHour.setUTCMinutes(0)
  } else {
    defaultDateHour = new Date(tour.deposit || '')
    defaultDateHour.setUTCHours(7)
    defaultDateHour.setUTCMinutes(0)
  }

  const [newTour, setNewTour] = useState<Partial<AddTour>>({
    close: defaultDateHour,
  })

  const info1 = {
    title: 'Pourquoi définir une date de fermeture des commandes ?',
    content: `À ce moment là les producteurs qui participent à la tournée de livraison et qui ont eu au moins un de leur produit commandé par des clients recevoir un ordre de dépot de la marchandise chez le ${FormatUtils.getCarrierLabel(
      GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS,
    )}.`,
  }

  const info2 = {
    title: 'Quand définir la date de fermeture ?',
    content:
      'Afin de laisser le temps aux producteurs de récolter les produits / de préparer les commandes nous recommandons entre 12h et 24h de délais entre la fermture et le dépôt',
  }

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!param && !(param in newTour)) return null

    const tourTemp = Object.assign({}, newTour)

    tourTemp[param as keyof typeof newTour] = value
    setNewTour(tourTemp)
  }

  const onChangeDateClose = (value: any) => {
    setValue(value, 'close')
    setDateIsInvalid(false)
    setTimeIsInvalid(false)

    if (GeneralUtils.isDateBeforeCurrent(value)) {
      setDateIsInvalid(true)
      setErrorMessage('La date de fermeture des commandes doit être supérieure à la date actuelle.')
      return
    }

    if (GeneralUtils.isDateBefore(new Date(tour.deposit || ''), new Date(value || ''), -1)) {
      setDateIsInvalid(true)
      setErrorMessage(
        'La date de fermeture des commandes doit être avant la date de dépôt de la marchandise',
      )
      return false
    }
  }

  return (
    <>
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <View style={{ width: '100%', alignItems: 'center' }}>
        <Typography.PageTitle>{'Fermeture des commandes'}</Typography.PageTitle>
      </View>
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <InfoBox title={info1.title} content={info1.content} />
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <InfoBox title={info2.title} content={info2.content} />
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <SectionTitle title="Date et heure de fermeture des commandes" />
      <Row>
        <Col xs={8}>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <DatePicker
            id="datefincommandes"
            label="Date"
            onChange={onChangeDateClose}
            value={newTour.close}
            mode={'date'}
            outputFormat="date"
            withDay
          />
        </Col>
        <Col xs={4}>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <DatePicker
            id="heurefincommandes"
            label="Heure"
            onChange={onChangeDateClose}
            value={newTour.close}
            mode={'time'}
            outputFormat="date"
          />
        </Col>
      </Row>
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      {errorMessage ? (
        <Typography.Body colorName="color-danger" style={{ textAlign: 'center' }}>
          {errorMessage}
        </Typography.Body>
      ) : (
        <>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          {!dateIsInvalid && !timeIsInvalid && (
            <ButtonWrapper>
              <Button
                onPress={() => onClick({ ...newTour })}
                label="Valider"
                colorName="color-secondary"
              />
            </ButtonWrapper>
          )}
        </>
      )}
    </>
  )
}

export default TourCloseSlide
