import React from 'react'
import { View } from 'react-native'
import { useTheme } from 'styled-components/native'
import { ClientTypeEnum } from '../../../../enums'
import { DynamicImage, Icon, Icons, Spacer, TouchableCard, Typography } from '../../../components'
import i18n from '../../../i18n'
import FormatUtils from '../../../utilities/utils/format'
import { ClientCardProps } from './ClientCard.model'
import {
  StyledCardContent,
  StyledCardWrapper,
  StyledImageBox,
  StyledLeftBox,
  StyledRightBox,
} from './ClientCard.styles'
import { CarrierGroup, User, UserCarrier } from '../../../domain'
import { LABELS_TYPES } from '../../../../enums/label'
import BadgeStatus from '../../../components/BadgeStatus'
import { ClientStatusEnum } from '../../../../enums/client'

const ClientCard = ({
  group,
  client,
  onClick,
  spaceBetween,
  lowMargin,
  minInfos,
  clientLinkTypeLabel,
  hasClientTypeLabel,
  clientGroup,
}: ClientCardProps) => {
  const theme = useTheme()

  const OnClickClient = () => {
    if (onClick) {
      onClick(client)
    }
  }

  const getLastUpdateUser = () => {
    const userClients = client?.userClients || []
    const users: User[] = []
    userClients.map((up) => {
      if (up.user) {
        users.push(up.user)
      }
    })

    if (users && users.length > 0 && users[0]) {
      users.sort((a, b) => (new Date(a.updatedDate || '') > new Date(b.updatedDate || '') ? -1 : 1))
      return users[0].updatedDate
    }
    return undefined
  }

  return (
    <StyledCardWrapper onPress={OnClickClient} activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent lowMargin={lowMargin}>
          {minInfos ? (
            <>
              <StyledImageBox>
                <DynamicImage />
              </StyledImageBox>
              <StyledLeftBox style={{ justifyContent: 'space-between' }}>
                <Typography.CardTitle ellipsis>{client.label}</Typography.CardTitle>
                {hasClientTypeLabel ? (
                  <Typography.BodyExtraSmall colorName="text-dark-3">
                    {!client.clientType || client.clientType === ClientTypeEnum.RETAILER
                      ? FormatUtils.capitalize(FormatUtils.getLabelFromClientType(1))
                      : 'Grossiste'}
                  </Typography.BodyExtraSmall>
                ) : null}
                {clientLinkTypeLabel ? (
                  <Typography.BodyExtraSmall colorName="text-dark-3">
                    {clientLinkTypeLabel}
                  </Typography.BodyExtraSmall>
                ) : null}
              </StyledLeftBox>
              <StyledRightBox>
                <Icon
                  type={Icons.Ionicons}
                  name="chevron-forward-outline"
                  size={theme.spacingUnit * 2}
                  color={theme.colors['text-dark-3']}
                />
                {client.orderCount && client.orderCount > 0 ? (
                  <Typography.BodyExtraSmall colorName="text-dark-3">
                    {client.orderCount} commande{client.orderCount !== 1 ? 's' : ''}
                  </Typography.BodyExtraSmall>
                ) : (
                  <Typography.BodyExtraSmall colorName="text-dark-3">
                    Aucune commande
                  </Typography.BodyExtraSmall>
                )}
                {clientGroup && clientGroup.archived ? (
                  <Typography.BodySmall colorName="color-danger">
                    Compte archivé depuis le{' '}
                    {FormatUtils.formatDate(clientGroup.updatedDate, 'Date')}
                  </Typography.BodySmall>
                ) : undefined}
              </StyledRightBox>
            </>
          ) : (
            <>
              <StyledLeftBox>
                <Typography.CardTitle ellipsis>{client.label}</Typography.CardTitle>
                <Typography.BodySmall colorName="color-primary" bold>
                  {client.owners}{' '}
                  {client.labels?.map(
                    (label) =>
                      `- ${LABELS_TYPES.find((labelType) => labelType.value === label)?.label} `,
                  )}
                </Typography.BodySmall>
                <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.BodySmall colorName="text-dark-3">
                  {client.deliveryAddress?.city} - {client.userClients?.length || 0} utilisateurs
                </Typography.BodySmall>
                <Typography.BodySmall
                  colorName={
                    client.clientType === ClientTypeEnum.WHOLESALER
                      ? 'color-warning'
                      : 'text-dark-3'
                  }
                >
                  {!client.clientType || client.clientType === ClientTypeEnum.RETAILER
                    ? FormatUtils.capitalize(FormatUtils.getLabelFromClientType(1))
                    : 'Grossiste'}
                </Typography.BodySmall>
                {clientGroup && clientGroup.archived ? (
                  <Typography.BodySmall colorName="color-danger">
                    Compte archivé depuis le{' '}
                    {FormatUtils.formatDate(clientGroup.updatedDate, 'Date')}
                  </Typography.BodySmall>
                ) : undefined}
              </StyledLeftBox>
              <StyledRightBox>
                <Icon
                  type={Icons.Ionicons}
                  name="chevron-forward-outline"
                  size={theme.spacingUnit * 2}
                  color={theme.colors['text-dark-3']}
                />
                <View style={{ alignItems: 'flex-end' }}>
                  <Typography.BodySmall colorName="text-dark-3" style={{ alignSelf: 'flex-end' }}>
                    Créé le
                  </Typography.BodySmall>
                  <Typography.BodySmall>
                    {FormatUtils.formatDate(client.createdDate, 'Date')}
                  </Typography.BodySmall>
                  <Spacer size={0.2} axis={Spacer.AxisEnum.VERTICAL} />
                  <Typography.BodySmall colorName="text-dark-3" style={{ alignSelf: 'flex-end' }}>
                    Dernière Connexion
                  </Typography.BodySmall>
                  <Typography.BodySmall>
                    {FormatUtils.formatDate(getLastUpdateUser(), 'Date')}
                  </Typography.BodySmall>
                </View>
              </StyledRightBox>
            </>
          )}
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default ClientCard
