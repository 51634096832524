import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import {
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Icon,
  Icons,
  Row,
  SectionTitle,
  Spacer,
  Typography,
} from '../../../components'
import TextInput from '../../../components/inputs/TextInput'
import Loader from '../../../components/Loader'
import { Batch, BatchComputed, Group, Product, UpdateBatch } from '../../../domain'
import i18n from '../../../i18n'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import BatchProducerService from '../../../services/producer/batch'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import {
  BatchContentWrapper,
  StyledButtonWrapper,
  StyledCardContent,
  StyledCardWrapper,
  StyledGainBox,
  StyledModeSwitchWrapper,
} from './ProducerUpdateBatchV2.styles'
import { Switch } from 'react-native-gesture-handler'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { BATCH_TYPES, BatchEnums } from '../../../../enums/batch'
import BatchUtil from '../../../utilities/utils/batch'
import { TextTag } from '../../../components/Text/Text.model'
import BatchsCard from '../../../modules/Batch/BatchsCard'
import GroupProducerService from '../../../services/producer/group'
import { View } from 'react-native'

const ProducerUpdateBatchV2Screen = () => {
  const theme = useTheme()
  const route = useRoute<RouteProp<ProducerStackParamList, 'ProducerUpdateBatch'>>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [crateQuantityString, setCrateQuantityString] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const { getAccessInfos } = useAuthenticationContext()
  const [selectedType, setSelectedType] = useState<any>()
  const [displayProducerPriceInfos, setDisplayProducerPriceInfos] = useState<boolean>(false)
  const [displayCrossedPrice, setDisplayCrossedPrice] = useState<boolean>(false)
  const [displayBatchInventory, setDisplayBatchInventory] = useState<boolean>(false)
  const [displayEditMinPrice, setDisplayEditMinPrice] = useState<boolean>(false)

  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const { hasCarrierRole } = useAuthenticationContext()
  const [product, setProduct] = useState<Product>()
  const [updatedBatch, setUpdatedBatch] = useState<UpdateBatch>()

  const { batchId, propositionsFilters } = route?.params ?? {
    batchId: '',
    propositionsFilters: undefined,
  }

  const {
    data: batch,
    refetch: refetchBatch,
    isInitialLoading: batchLoading,
  } = useQuery<Batch, Error>(
    ['p_batch', batchId],
    () => BatchProducerService.getOneBatch(batchId, true),
    {
      enabled: batchId !== '',
      select: FormatUtils.removeUTCFromBatchDate,
    },
  )

  const {
    data: updatedComputedBatch,
    refetch: refetchUpdatedComputedBatch,
    isLoading: updatedComputedBatchLoading,
  } = useQuery<BatchComputed | null, Error>(
    ['p_update_computed_batch', batchId],
    () =>
      BatchProducerService.getComputed(product?.id, {
        start: updatedBatch?.start ? new Date(updatedBatch?.start) : undefined,
        end: updatedBatch?.end ? new Date(updatedBatch?.end) : undefined,
        unitPriceProducer: updatedBatch?.unitPriceProducer
          ? FormatUtils.stringToFloat(updatedBatch?.unitPriceProducer)
          : undefined,
        carrierFees: updatedBatch?.carrierFees
          ? FormatUtils.stringToFloat(updatedBatch?.carrierFees)
          : undefined,
        createdDate: batch?.createdDate ? new Date(batch.createdDate) : undefined,
      }),
    {
      enabled:
        product !== undefined &&
        updatedBatch !== undefined &&
        updatedBatch.unitPriceProducer !== '',
    },
  )

  const {
    data: groups,
    refetch: refetchGroups,
    isInitialLoading: groupsLoading,
  } = useQuery<Group[], Error>(
    ['p_groups'],
    () => GroupProducerService.getAllGroupsByProducerId(getAccessInfos().producerId),
    {
      keepPreviousData: true,
    },
  )

  const hasGroupWithCrossedPriceFeature = (groups: Group[]): boolean => {
    return groups.some((group) => group.hasCrossedPriceFeature)
  }

  useEffect(() => {
    if (batch) {
      setUpdatedBatch({
        ...batch,
        start: new Date(batch.start),
        end: new Date(batch.end),
        unitPriceProducer: FormatUtils.floatToString(batch.unitPriceProducer),
        unitPrice: FormatUtils.floatToString(batch.unitPrice),
        crossedOutPrice: FormatUtils.floatToString(batch.crossedOutPrice),
        totalQuantity: FormatUtils.floatToString(batch.totalQuantity),
        carrierFees: FormatUtils.floatToString(batch.carrierFees),
        availableQuantity: isConcludedBatch() ? 0 : batch.availableQuantity,
      })
      setCrateQuantityString(
        Math.round(batch.totalQuantity / (batch.product?.packing || 1)).toString(),
      )
      if (batch.type) {
        setSelectedType({
          label: BATCH_TYPES.find((bt) => bt.value === batch.type)?.label,
          value: batch.type,
        })
      }

      if (batch.crossedOutPrice) {
        setDisplayCrossedPrice(true)
      }

      setProduct(batch.product)
      // setTour(batch.tour)
    }
  }, [batch, setProduct /*setTour*/])

  const onConfirm = async (inType?: BatchEnums.TYPE) => {
    /*
    await queryClient.setQueryData(['p_batch', batchId], null)
    await queryClient.setQueryData(['p_update_computed_batch', batchId], null)

    await queryClient.invalidateQueries(['p_batchs'])
    await queryClient.invalidateQueries(['p_batchs_dashboard'])
    */

    let mode: 'DAY' | 'WEEK' | 'MONTH' | undefined = undefined

    const type = inType || selectedType.value
    if (type === BatchEnums.TYPE.DAY) {
      mode = 'DAY'
    } else if (type === BatchEnums.TYPE.WEEK) {
      mode = 'WEEK'
    } else if (type === BatchEnums.TYPE.MONTH) {
      mode = 'MONTH'
    }

    const params = {
      mode: mode,
      start: updatedBatch?.start || undefined,
    }

    navigation.navigate(
      'ProducerPropositions',
      propositionsFilters
        ? { start: propositionsFilters.start, mode: propositionsFilters.mode }
        : params,
    )
  }

  if (!batch) return <Loader isLoading pageLoading />

  const setValue = async (value: any, param: string) => {
    if (!updatedBatch || (!param && !(param in updatedBatch))) return null
    const batchTemp = Object.assign({}, updatedBatch)

    batchTemp[param as keyof typeof updatedBatch] = value

    await setUpdatedBatch(batchTemp)
    refetchUpdatedComputedBatch()
  }

  const onChangeCrateQuantity = (crateQuantityString: string) => {
    if (!crateQuantityString || crateQuantityString === '0') {
      setValue('0', 'totalQuantity')
    }
    const crateQuantity = FormatUtils.stringToFloat(crateQuantityString)
    const totalQuantity = crateQuantity * (product?.packing || 1)
    setValue(totalQuantity.toString(), 'totalQuantity')
    setCrateQuantityString(crateQuantityString)
  }

  const onChangeIsPrivate = () => {
    if (updatedBatch?.isPrivate) {
      setValue(false, 'isPrivate')
      return
    }
    setValue(true, 'isPrivate')
  }

  const onChangeExpirationDate = (value: any) => {
    setErrorMessage('')

    const expirationTemp = Object.assign({}, updatedBatch)
    expirationTemp.expiration = value

    setUpdatedBatch(expirationTemp)
  }

  const onDeleteBatch = async () => {
    if (!batch || !batch.id) return

    if (getAvailableQuantity() !== batch?.totalQuantity) {
      setErrorMessage('Impossible de supprimer le lot car des quantités ont déjà été commandées')
      return
    }

    setIsLoading(true)
    await BatchProducerService.remove(batch.id)
      .then(() => {
        onConfirm()
      })
      .catch((error) => {
        setErrorMessage('Erreur lors de la suppression du lot')
      })
    setIsLoading(false)
  }

  const onUpdateBatch = async () => {
    setErrorMessage('')
    if (getAvailableQuantity() < 0) {
      setErrorMessage('La quantité disponible ne peut pas être inférieure à 0')
      return
    }
    if (!controlPrices()) {
      setErrorMessage('Votre prix de vente ne peut pas être inférieur ou égale à 0')
      return
    }
    if (!updatedBatch || !batch || !batch.id) {
      setErrorMessage('Un problème est survenu lors de la mise à jour du lot')
      return
    }
    if (!updatedBatch.start || !updatedBatch.end) {
      setErrorMessage('Date de début ou de fin de validité invalide')
      return
    }

    if (updatedBatch.start >= updatedBatch.end) {
      setErrorMessage('La date de début doit être avant la date de fin')
      return
    }

    if (updatedBatch.expiration && updatedBatch.end >= updatedBatch.expiration) {
      setErrorMessage("La date de fin de validité doit être avant la date d'expiration")
      return
    }

    let tempType = updatedBatch.type
    // if (we change type if needed regarding dates)
    if (updatedBatch.start && updatedBatch.end) {
      const diff = updatedBatch.end.getTime() - updatedBatch.start.getTime()
      const diffDays = Math.ceil(diff / (1000 * 3600 * 24))
      if (diffDays <= 1) {
        tempType = BatchEnums.TYPE.DAY
      } else if (
        diffDays <= 7 &&
        FormatUtils.startOfWeekDate(updatedBatch.start) ===
          FormatUtils.startOfWeekDate(updatedBatch.end)
      ) {
        tempType = BatchEnums.TYPE.WEEK
      } else {
        tempType = BatchEnums.TYPE.MONTH
      }

      await setSelectedType({
        label: BATCH_TYPES.find((bt) => bt.value === tempType)?.label,
        value: tempType,
      })
    }

    setIsLoading(true)
    const batchTemp = {
      start: FormatUtils.dateToUtcString(new Date(updatedBatch.start), '00:00:00'),
      end: FormatUtils.dateToUtcString(new Date(updatedBatch.end), '23:59:59'),
      type: tempType,
      unitPriceProducer: FormatUtils.stringToFloat(updatedBatch.unitPriceProducer),
      unitPrice: FormatUtils.stringToFloat(updatedBatch.unitPrice),
      crossedOutPrice: FormatUtils.stringToFloat(updatedBatch.crossedOutPrice),
      totalQuantity: FormatUtils.stringToFloat(updatedBatch.totalQuantity),
      isPrivate: updatedBatch.isPrivate,
      expiration: updatedBatch.expiration || null,
    }

    const updatedBatchTemp = await BatchProducerService.update(batch.id, batchTemp)
    if (updatedBatchTemp && updatedBatchTemp.id) {
      if (displayEditMinPrice) {
        setDisplayEditMinPrice(false)
        refetchBatch()
      } else {
        await onConfirm(tempType)
      }
    } else {
      setErrorMessage('Un problème est survenu lors de la mise à jour du lot')
    }
    setIsLoading(false)
  }

  const onConcludeBatch = async () => {
    if (!batch || !batch.id) return

    setIsLoading(true)

    const concludeBatch = await BatchProducerService.conclude(batch.id)

    if (concludeBatch && concludeBatch.id) {
      await onConfirm()
    } else {
      setErrorMessage('Un problème est survenu lors de la clôture du lot')
    }
    setIsLoading(false)
  }

  const isConcludedBatch = () => {
    return new Date(batch.end) < new Date() && batch.availableQuantity === 0
  }

  if (batchLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!updatedBatch || !product) return null

  const controlPrices = () => {
    const unitPriceProducer = FormatUtils.stringToFloat(updatedBatch.unitPriceProducer)
    if (unitPriceProducer < 0.01) return false

    return true
  }

  const getSoldQuantity = () => {
    const orderedQuantity = (batch?.totalQuantity || 0) - (batch?.availableQuantity || 0)

    return orderedQuantity
  }

  const getAvailableQuantity = () => {
    return FormatUtils.stringToFloat(updatedBatch?.totalQuantity || '0') - getSoldQuantity()
  }

  const onApplyPriceStatement = async () => {
    await setValue(
      FormatUtils.floatToString(
        Math.round((updatedBatch.computed?.pricesStats?.producerPriceBellow?.min || 0) * 100) / 100,
      ),
      'unitPriceProducer',
    )
    setDisplayEditMinPrice(false)
    refetchUpdatedComputedBatch()
  }

  const onChangeDate = async (
    attribute: 'start' | 'end',
    value: any,
    forcedType?: BatchEnums.TYPE,
  ) => {
    setErrorMessage('')
    const batchType = forcedType || selectedType.value

    const updatedBatchTemp: UpdateBatch = Object.assign({}, updatedBatch)
    if (attribute === 'start') {
      const tempStartDate = new Date(value)
      tempStartDate.setHours(2)
      tempStartDate.setMinutes(0)
      tempStartDate.setSeconds(0)
      updatedBatchTemp.start = tempStartDate

      if (batchType === BatchEnums.TYPE.DAY) {
        const tempEndDate = new Date(tempStartDate)
        tempEndDate.setHours(23)
        tempEndDate.setMinutes(59)
        tempEndDate.setSeconds(0)
        updatedBatchTemp.end = tempEndDate
        updatedBatchTemp.expiration = undefined
      }
      if (batchType === BatchEnums.TYPE.WEEK && forcedType) {
        const tempEndDate = new Date(tempStartDate)
        tempEndDate.setDate(FormatUtils.startOfWeek(tempEndDate))
        tempEndDate.setDate(tempEndDate.getDate() + 6)
        tempEndDate.setHours(23)
        tempEndDate.setMinutes(59)
        tempEndDate.setSeconds(0)
        updatedBatchTemp.end = tempEndDate
        updatedBatchTemp.expiration = undefined
      }
      if (batchType === BatchEnums.TYPE.MONTH && forcedType) {
        const tempEndDate = new Date(tempStartDate)
        tempEndDate.setDate(FormatUtils.startOfWeek(tempEndDate))
        tempEndDate.setDate(tempEndDate.getDate() + 12)
        tempEndDate.setHours(23)
        tempEndDate.setMinutes(59)
        tempEndDate.setMinutes(0)
        updatedBatchTemp.end = tempEndDate
      }
    }
    if (attribute === 'end') {
      const tempEndDate = new Date(value)
      tempEndDate.setHours(23)
      tempEndDate.setMinutes(59)
      tempEndDate.setSeconds(0)
      updatedBatchTemp.end = tempEndDate
    }

    await setUpdatedBatch(updatedBatchTemp)
    refetchUpdatedComputedBatch()
  }

  const getEndDateLimit = () => {
    if (selectedType.value === BatchEnums.TYPE.WEEK && updatedBatch.start) {
      const limitDate = new Date(updatedBatch.start)
      limitDate.setDate(FormatUtils.startOfWeek(limitDate))
      limitDate.setDate(limitDate.getDate() + 6)
      return limitDate
    }
    return undefined
  }

  const onChangeType = async (selectedTypeTemp: any) => {
    await setSelectedType(selectedTypeTemp)
    await setValue(selectedTypeTemp, 'type')
    if (updatedBatch.start) {
      onChangeDate('start', updatedBatch.start, selectedTypeTemp?.value)
    }
  }

  const batchComputed = BatchUtil.getBatchComputed({
    ...updatedBatch,
    computed: updatedComputedBatch ? updatedComputedBatch : batch.computed,
    id: batch.id,
    start: new Date(updatedBatch.start || new Date()).toString(),
    end: new Date(updatedBatch.end || new Date()).toString(),
    unitPriceProducer: FormatUtils.stringToFloat(updatedBatch.unitPriceProducer),
    unitPrice: FormatUtils.stringToFloat(updatedBatch.unitPrice),
    crossedOutPrice: displayCrossedPrice
      ? FormatUtils.stringToFloat(updatedBatch.crossedOutPrice)
      : undefined,
    totalQuantity: FormatUtils.stringToFloat(updatedBatch.totalQuantity),
    carrierFees: FormatUtils.stringToFloat(updatedBatch.carrierFees),
    availableQuantity: isConcludedBatch() ? 0 : batch.availableQuantity,
    orderedQuantity: batch.orderedQuantity,
  })

  const onNavigateToAddRule = () => {
    navigation.navigate('ProducerAddRule', {
      batch: batchComputed,
    })
  }

  const isFormValid = (): boolean => {
    if (!updatedBatch || !updatedBatch.start || !updatedBatch.end) return false
    let isValid = true
    if (getSoldQuantity() > 0) {
      if (updatedBatch.start >= updatedBatch.end) {
        isValid = false
      } else if (updatedBatch.end < new Date(batch.end)) {
        isValid = false
      }
    }

    return isValid
  }

  const hasPriceStatment = () => {
    return (
      updatedComputedBatch?.pricesStats?.producerPrice.min !== updatedBatch.unitPriceProducer &&
      !updatedComputedBatchLoading
    )
  }

  const cancelEditMinPrice = () => {
    setDisplayEditMinPrice(false)
    setUpdatedBatch({
      ...updatedBatch,
      unitPriceProducer: FormatUtils.floatToString(batch.unitPriceProducer),
    })
  }

  const renderRecomentedPrice = () => {
    if (batchComputed.computed?.pricesStats?.producerPriceBellow?.min) {
      return (
        <>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <Card>
            <Row>
              <Col xs={7} sm={7} md={7} lg={7} alignItems="center" justifyContent="center">
                <Typography.BodySmall align="center" bold>
                  Prix producteur recommandé
                </Typography.BodySmall>
                <Typography.BodyExtraSmall align="center" colorName="text-dark-3">
                  En dessous de votre prix minimum
                </Typography.BodyExtraSmall>
              </Col>
              <Col xs={2} sm={2} md={2} lg={2} alignItems="center" justifyContent="center">
                <Typography.Body bold align="center" colorName="color-warning">
                  {FormatUtils.formatPrice(
                    batchComputed.computed?.pricesStats.producerPriceBellow?.min,
                  )}
                </Typography.Body>
              </Col>
              <Col xs={3} sm={3} md={3} lg={3} alignItems="flex-end" justifyContent="center">
                <Button
                  label={'Appliquer'}
                  onPress={() => onApplyPriceStatement()}
                  disabled={isLoading}
                  small
                />
              </Col>
            </Row>
          </Card>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        </>
      )
    }
    return <></>
  }

  const renderEditPriceSection = () => {
    if (displayEditMinPrice) {
      return (
        <>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <Row>
            <Col xs={12}>
              <Spacer size={1.5} axis={Spacer.AxisEnum.VERTICAL} />
              <Typography.BodySmall align="left" bold>
                Modification de votre prix minimum
              </Typography.BodySmall>
              <Typography.BodySmall align="left" colorName="text-dark-3">
                Il s'agit du prix que vous percevrez tout frais, plus ce prix est bas, plus il
                permet à notre outil d'adapter le prix client en fonction des variation au cour de
                la saison
              </Typography.BodySmall>
            </Col>
            <Col xs={12} sm={12} md={5}>
              <Spacer size={0.7} axis={Spacer.AxisEnum.VERTICAL} />
              <TextInput
                value={updatedBatch.unitPriceProducer?.toString()}
                label={`Votre prix min. HT `}
                field="text"
                onChangeText={(text) => setValue(text, 'unitPriceProducer')}
                editable
                hidden={!product}
                suffix={`€/${FormatUtils.formatUnity(product.mesureType)}`}
              />
            </Col>
            <Col xs={12} sm={12} md={7} alignItems="center">
              <Spacer size={2.5} axis={Spacer.AxisEnum.VERTICAL} />
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Button
                  label={'Annuler'}
                  colorName="color-grey"
                  onPress={() => cancelEditMinPrice()}
                  small
                />
                <Spacer size={0.8} axis={Spacer.AxisEnum.HORIZONTAL} />
                <Button
                  label={FormatUtils.capitalize(i18n.t('save'))}
                  onPress={() => onUpdateBatch()}
                  small
                  loading={isLoading}
                  disabled={!isFormValid()}
                />
              </View>
            </Col>
          </Row>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        </>
      )
    }
    return <></>
  }

  const renderPriceSection = () => {
    return (
      <>
        {renderEditPriceSection()}
        {renderRecomentedPrice()}
        {!displayEditMinPrice && (
          <>
            <Row>
              <Col xs={6} sm={6} md={4} lg={5} alignItems={'center'}>
                <Spacer size={0.7} axis={Spacer.AxisEnum.VERTICAL} />
                <StyledCardWrapper>
                  <Card>
                    <StyledCardContent>
                      <Typography.BodyExtraSmall>Prix minimum</Typography.BodyExtraSmall>
                      <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
                      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Typography.BodySmall colorName="text-dark-3">
                          {FormatUtils.formatPrice(
                            updatedBatch.unitPriceProducer?.replace(',', '.'),
                          )}
                        </Typography.BodySmall>
                        <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
                        <Icon
                          type={Icons.MaterialCommunityIcons}
                          name="pencil"
                          color={theme.colors['color-grey']}
                          size={theme.spacingUnit * 1.2}
                          onPress={() => setDisplayEditMinPrice(!displayEditMinPrice)}
                        />
                      </View>
                      <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />

                      {batchComputed.computed?.priceParams?.producerPriceGainPercentage && (
                        <>
                          <Typography.BodyExtraSmall>Revalorisation</Typography.BodyExtraSmall>
                          <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
                          <StyledGainBox>
                            <Typography.BodyExtraSmall bold colorName="text-dark-1" align="center">
                              {batchComputed.computed?.priceParams?.producerPriceGainPercentage}
                            </Typography.BodyExtraSmall>
                          </StyledGainBox>
                          <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                          <Typography.BodySmall colorName="text-dark-3">
                            {batchComputed.computed?.priceParams?.producerPricesFormatted}
                          </Typography.BodySmall>
                        </>
                      )}
                    </StyledCardContent>
                  </Card>
                </StyledCardWrapper>
              </Col>
              <Col xs={6} sm={6} md={4} lg={3} alignItems={'center'}>
                <Spacer size={0.7} axis={Spacer.AxisEnum.VERTICAL} />
                <StyledCardWrapper>
                  <Card>
                    <StyledCardContent>
                      <Typography.BodyExtraSmall>Frais</Typography.BodyExtraSmall>
                      <Spacer size={0.2} axis={Spacer.AxisEnum.VERTICAL} />
                      <Typography.BodySmall bold>
                        {batchComputed.computed?.priceParams?.totalFeesAmountFormatted}
                      </Typography.BodySmall>
                      <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                      <Button.Text
                        label={`${displayProducerPriceInfos ? '-' : '+'} d'infos`}
                        onPress={() => setDisplayProducerPriceInfos(!displayProducerPriceInfos)}
                        colorName="text-dark-3"
                        small
                        textTag={TextTag.BODY_SMALL}
                      />
                    </StyledCardContent>
                  </Card>
                </StyledCardWrapper>
              </Col>
              <Col xs={6} sm={6} md={4} lg={4} alignItems={'center'}>
                <Spacer size={0.7} axis={Spacer.AxisEnum.VERTICAL} />
                <StyledCardWrapper>
                  <Card>
                    <StyledCardContent>
                      <Typography.BodyExtraSmall>Prix Client HT</Typography.BodyExtraSmall>
                      <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                      <Typography.BodySmall bold>
                        {batchComputed.computed?.priceParams?.unitPricesFormatted}
                      </Typography.BodySmall>
                      <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                    </StyledCardContent>
                  </Card>
                </StyledCardWrapper>
                <Spacer size={0.7} axis={Spacer.AxisEnum.VERTICAL} />
                {groups && hasGroupWithCrossedPriceFeature(groups) && !displayCrossedPrice && (
                  <Button.Text
                    label={`Afficher un prix barré`}
                    onPress={() => setDisplayCrossedPrice(true)}
                    colorName="text-dark-3"
                    small
                    textTag={TextTag.BODY_SMALL}
                  />
                )}
              </Col>
            </Row>
            {displayProducerPriceInfos && (
              <>
                <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.Body colorName="text-dark-1" bold>
                  Tournées où ce lot sera proposé :{' '}
                </Typography.Body>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <Card>
                  <View style={{ width: '100%' }}>
                    <Row>
                      <Col xs={3}>
                        <Typography.BodySmall colorName="text-dark-1" bold>
                          Tournée
                        </Typography.BodySmall>
                        <Typography.BodySmall colorName="text-dark-1" bold>
                          Date
                        </Typography.BodySmall>
                      </Col>
                      <Col xs={2} alignItems="center">
                        <Typography.BodySmall colorName="text-dark-3" bold align="center">
                          Prix Réel
                        </Typography.BodySmall>
                      </Col>
                      <Col xs={3} alignItems="center">
                        <Typography.BodySmall colorName="text-dark-3" bold align="center">
                          Frais log. / commerciaux
                        </Typography.BodySmall>
                      </Col>
                      <Col xs={2} alignItems="center">
                        <Typography.BodySmall colorName="text-dark-3" bold align="center">
                          Frais plateforme
                        </Typography.BodySmall>
                      </Col>
                      <Col xs={2} alignItems="flex-end" justifyContent="flex-start">
                        <Typography.BodySmall colorName="text-dark-3" bold align="right">
                          Prix client
                        </Typography.BodySmall>
                      </Col>
                    </Row>
                  </View>
                </Card>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                {batchComputed.computed?.pricesParams?.map((ps) => (
                  <>
                    <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.8} />
                    <Card>
                      <View style={{ width: '100%' }}>
                        <Row>
                          <Col xs={3}>
                            <Typography.BodySmall colorName="text-dark-1">
                              {ps.tour.city?.label}
                            </Typography.BodySmall>
                            <Typography.BodySmall colorName="text-dark-3">
                              {FormatUtils.formatDate(ps.tour.start, 'FullDate')}
                            </Typography.BodySmall>
                          </Col>
                          <Col xs={2} alignItems="center">
                            <Typography.BodySmall colorName="text-dark-3">
                              {FormatUtils.formatPrice(ps.producerPrice)}
                            </Typography.BodySmall>
                          </Col>
                          <Col xs={3} alignItems="center">
                            {ps.carrierFeesCuts.map((cf) => (
                              <Typography.BodySmall colorName="text-dark-3">
                                {FormatUtils.formatPrice(cf.amount)} (
                                {FormatUtils.formatPercentage(cf.rate / 100)})
                              </Typography.BodySmall>
                            ))}
                          </Col>
                          <Col xs={2} alignItems="center">
                            <Typography.BodySmall colorName="text-dark-3">
                              {FormatUtils.formatPrice(ps.platformFeesAmount)} (
                              {FormatUtils.formatPercentage(ps.platformFees / 100)})
                            </Typography.BodySmall>
                          </Col>
                          <Col xs={2} alignItems="flex-end">
                            <Typography.BodySmall colorName="text-dark-3">
                              {FormatUtils.formatPrice(ps.unitPrice)}/
                              {FormatUtils.formatUnity(batch.product?.mesureType)}
                            </Typography.BodySmall>
                          </Col>
                        </Row>
                      </View>
                    </Card>
                    <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.8} />
                  </>
                ))}
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
                <Button.Text
                  label={`Cacher les informations`}
                  onPress={() => setDisplayProducerPriceInfos(!displayProducerPriceInfos)}
                  colorName="text-dark-3"
                  small
                  textTag={TextTag.BODY_SMALL}
                />
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
              </>
            )}
            {groups && hasGroupWithCrossedPriceFeature(groups) && displayCrossedPrice && (
              <>
                <SectionTitle title={`Afficher une remise`} />
                <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />

                <Row rowStyles={{ justifyContent: 'center', alignItems: 'center' }}>
                  <Col xs={6}>
                    <Spacer size={0.7} axis={Spacer.AxisEnum.VERTICAL} />
                    <TextInput
                      value={updatedBatch.crossedOutPrice?.toString()}
                      label={`Prix avant remise (par ${FormatUtils.formatUnity(
                        product?.mesureType,
                      )})`}
                      field="text"
                      onChangeText={(text) => setValue(text, 'crossedOutPrice')}
                      editable
                      hidden={!product}
                    />
                  </Col>
                  <Col xs={6} alignItems={'center'}>
                    <Spacer size={0.7} axis={Spacer.AxisEnum.VERTICAL} />
                    <Button.Text
                      label={'Supprimer le prix barré'}
                      onPress={() => {
                        setDisplayCrossedPrice(false)
                        setUpdatedBatch({ ...updatedBatch, crossedOutPrice: '' })
                      }}
                      colorName="text-dark-3"
                      small
                      textTag={TextTag.BODY_SMALL}
                    />
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </>
    )
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <BatchContentWrapper>
          <ScrollableFormWrapper>
            <Typography.PageTitle>
              {FormatUtils.capitalize(i18n.t('pageTitleUpdateBatch'))}
            </Typography.PageTitle>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <Card>
              <Typography.Body>{FormatUtils.capitalize(i18n.t('product'))}</Typography.Body>
              <Typography.CardTitle>{product.label}</Typography.CardTitle>
            </Card>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <Dropdown
              label={'Choisir un mode'}
              onChange={onChangeType}
              multiple={false}
              optionsDefault={BATCH_TYPES.map((element) => ({
                label: element.label,
                value: element.value,
              }))}
              defaultValue={selectedType?.value}
              zIndex={1000}
              itemKey="type"
            />

            <>
              <SectionTitle title={`Disponibilité`} />
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
              {getSoldQuantity() === 0 ? (
                <DatePicker
                  id="batchStart"
                  label={
                    selectedType?.value === BatchEnums.TYPE.DAY
                      ? 'Date de validité'
                      : 'Début de validité (inclus)'
                  }
                  mode="date"
                  onChange={(value) => onChangeDate('start', value)}
                  value={updatedBatch.start}
                  withDay
                />
              ) : (
                <TextInput
                  value={
                    updatedBatch.start
                      ? FormatUtils.formatDate(updatedBatch.start.toString(), 'DateFullYear')
                      : ''
                  }
                  label="Début de validité"
                  field="text"
                  onChangeText={() => {
                    return
                  }}
                  editable={false}
                />
              )}
              {getSoldQuantity() > 0 && (
                <>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
                  <Typography.BodyExtraSmall colorName="text-dark-3">
                    La date de début de validité pour ce lot ne peut pas être modifiée car des
                    quantités ont déjà été commandées.
                  </Typography.BodyExtraSmall>
                  <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
                </>
              )}
              {updatedBatch.start && selectedType.value !== BatchEnums.TYPE.DAY && (
                <>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
                  <DatePicker
                    id="batchEnd"
                    label="Fin de validité (inclus)"
                    mode="date"
                    minDate={getSoldQuantity() > 0 ? updatedBatch.end : updatedBatch.start}
                    maxDate={getEndDateLimit()}
                    onChange={(value) => onChangeDate('end', value)}
                    value={updatedBatch.end}
                    withDay
                  />
                </>
              )}
              {getSoldQuantity() > 0 && selectedType.value !== BatchEnums.TYPE.DAY && (
                <>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
                  <Typography.BodyExtraSmall colorName="text-dark-3">
                    La date de fin de validité pour ce lot ne peut pas être antérieur à celle
                    actuelle car des quantités ont déjà été commandées.
                  </Typography.BodyExtraSmall>
                  <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
                </>
              )}
              {selectedType.value === BatchEnums.TYPE.MONTH && (
                <>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
                  <DatePicker
                    id="batchexpirationdate"
                    label="DLC (optionnelle - visible par les clients)"
                    mode="date"
                    onChange={onChangeExpirationDate}
                    value={updatedBatch.expiration}
                  />
                </>
              )}
            </>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
            <Typography.BodySmall colorName="text-dark-3">
              Ce lot sera disponible dans au moins{' '}
              {batchComputed?.computed?.carriersFeesParams?.carrierFees?.length || 0} tournées de
              livraison
            </Typography.BodySmall>

            <SectionTitle title={`Prix du lot`} />
            {renderPriceSection()}

            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            <SectionTitle title={`Quantités en vente`} />
            <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
            <Row>
              <Col xs={5}>
                <TextInput
                  value={crateQuantityString}
                  label={`Colis disponibles`}
                  field="text"
                  onChangeText={(text) => onChangeCrateQuantity(text)}
                  editable
                />
              </Col>
              <Col xs={3} alignItems={'center'}>
                <Typography.BodySmall>
                  {FormatUtils.formatUnity(product?.mesureType)} / colis
                </Typography.BodySmall>
                <Typography.Body bold colorName="text-dark-3">
                  {FormatUtils.formatQuantity(product.packing || 1, product.mesureType)}
                </Typography.Body>
                <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.BodySmall>Quantité en vente</Typography.BodySmall>
                <Typography.Body bold colorName="text-dark-3">
                  {FormatUtils.formatQuantity(
                    FormatUtils.stringToFloat(updatedBatch.totalQuantity || '0'),
                    product.mesureType,
                  )}
                </Typography.Body>
              </Col>
              <Col xs={4} alignItems={'center'}>
                <Typography.BodySmall>Quantité vendu</Typography.BodySmall>
                <Typography.Body bold colorName="text-dark-3">
                  {FormatUtils.formatQuantity(getSoldQuantity() || 0, product.mesureType)}
                </Typography.Body>
                <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.BodySmall>Quantité disponible</Typography.BodySmall>
                <Typography.Body
                  bold
                  colorName={getAvailableQuantity() > 0 ? 'color-primary' : 'color-danger'}
                >
                  {FormatUtils.formatQuantity(getAvailableQuantity(), product.mesureType)}
                </Typography.Body>
              </Col>
            </Row>
            <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
            <Button.Text
              label={`${displayBatchInventory ? '-' : '+'} d'informations sur les stocks`}
              onPress={() => setDisplayBatchInventory(!displayBatchInventory)}
              colorName="text-dark-3"
              small
              textTag={TextTag.BODY_SMALL}
            />
            {displayBatchInventory && (
              <>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.Body colorName="text-dark-1" bold>
                  Gestion des stocks
                </Typography.Body>
                <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.Body colorName="text-dark-1">
                  Ventes dans les prochains jours :{' '}
                  {FormatUtils.formatQuantity(updatedBatch.orderNextQuantity, product.mesureType)}
                </Typography.Body>
                <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.Body colorName="text-dark-1">
                  Stock attendu après les dépots et préparations du jours :{' '}
                  {FormatUtils.formatQuantity(
                    getAvailableQuantity() + (updatedBatch.orderNextQuantity || 0),
                    product.mesureType,
                  )}
                </Typography.Body>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
              </>
            )}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />

            <StyledModeSwitchWrapper>
              <Typography.Body>
                Cacher sur le catalogue{' '}
                {FormatUtils.capitalize(FormatUtils.getLabelFromClientType(1))}
              </Typography.Body>
              <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={1} />
              <Switch
                trackColor={{ false: '#767577', true: theme.colors['color-primary'] }}
                onValueChange={() => onChangeIsPrivate()}
                value={updatedBatch.isPrivate}
              />
              <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={2} />
            </StyledModeSwitchWrapper>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />

            {batchComputed.computed?.statusMessage &&
            batchComputed.computed?.statusMessage?.length > 0 ? (
              <>
                <SectionTitle title={`Problèmes sur ce lot`} />
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.7} />
                {batchComputed.computed.statusMessage.map((st) => (
                  <>
                    <Typography.Body bold colorName="color-danger">
                      {st.title}
                    </Typography.Body>
                    <Typography.BodySmall colorName="color-danger">
                      {st.details}
                    </Typography.BodySmall>
                  </>
                ))}
              </>
            ) : null}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            {updatedBatch.isPrivate && (
              <Typography.Body colorName="color-danger">
                Ce lot est indisponible sur le catalogue client et ne pourra être ajouté à une
                commande que par le super-producteur
              </Typography.Body>
            )}
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            {errorMessage ? (
              <Typography.Body colorName="color-danger" align="center">
                {errorMessage}
              </Typography.Body>
            ) : null}
            {/*!isPricesProducerGreaterThanPriceStatement() ? (
              <Typography.Body colorName="color-danger">
                Vos prix ne peuvent pas être en dessous des prix conseillés
              </Typography.Body>
            ) : null*/}
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <StyledButtonWrapper>
              {isConcludedBatch() ? (
                <Typography.Body colorName="text-dark-3">
                  Ce lot est clôturé et ne peut plus être modifié
                </Typography.Body>
              ) : (
                <>
                  {getSoldQuantity() > 0 ? (
                    <>
                      <Button
                        label={FormatUtils.capitalize(i18n.t('conclude'))}
                        colorName="text-dark-3"
                        onPress={() => onConcludeBatch()}
                        loading={isLoading}
                        hasDoubleValidation
                        confirmationLabel="Êtes-vous sûr de vouloir clôturer ce lot ?"
                      />
                    </>
                  ) : (
                    <Button
                      danger
                      label={FormatUtils.capitalize(i18n.t('remove'))}
                      onPress={() => onDeleteBatch()}
                      loading={isLoading}
                      hasDoubleValidation
                      confirmationLabel="Êtes-vous sûr de vouloir supprimer ce lot ?"
                    />
                  )}
                </>
              )}
              {!isConcludedBatch() && (
                <Button
                  label={FormatUtils.capitalize(i18n.t('save'))}
                  onPress={() => onUpdateBatch()}
                  loading={isLoading}
                  disabled={!isFormValid()}
                  confirmationLabel="Êtes-vous sûr de vouloir mettre à jour ce lot ?"
                />
              )}
            </StyledButtonWrapper>

            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
            <SectionTitle
              title={`Lots de produits similaires proposés par les autres producteurs`}
            />
            <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
            {batch.otherProducersBatchs && batch.otherProducersBatchs.length > 0 ? (
              <>
                {batch.otherProducersBatchs.map((productBatch) => {
                  if (productBatch?.batchs && productBatch.batchs[0]?.id !== batch.id) {
                    return (
                      <BatchsCard
                        key={productBatch.product?.id}
                        productBatchs={productBatch}
                        onClick={() => null}
                        displayProducer
                      />
                    )
                  }
                })}
              </>
            ) : (
              <Typography.Body colorName="text-dark-3">Aucun lots</Typography.Body>
            )}
            <Spacer size={4} axis={Spacer.AxisEnum.VERTICAL} />
          </ScrollableFormWrapper>
        </BatchContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerUpdateBatchV2Screen
