import FormatUtils from '../src/utilities/utils/format'
import { Group } from './../src/domain/Group/group.model'
import { GroupEnums } from './group'
export namespace TutorialStepsEnums {
  export enum ProducerTutorialStepTypeEnum {
    COMPLETE_PROFILE = 10,
    COMPLETE_PRODUCT = 20,
    COMPLETE_TOUR = 30,
    COMPLETE_PROPOSITION = 40,
    COMPLETE_ORDER = 50,
    COMPLETE_LEGAL_INFOS = 60,
    COMPLETE_HAS_GROUP = 70,
  }
  export enum CarrierTutorialStepTypeEnum {
    COMPLETE_PROFILE = 10,
  }

  export enum GroupTutorialStepTypeEnum {
    COMPLETE_ADD_TOUR = 10,
    COMPLETE_ADD_CLIENT_GROUP = 20,
    COMPLETE_ADD_PRODUCER_GROUP = 30,
    COMPLETE_SHARED_TOUR = 40,
  }

  export enum TourTutorialStepTypeEnum {
    COMPLETE_DELIVERY_EXPENSES_PARAMS = 10,
    COMPLETE_PRODUCER_TOUR = 20,
    COMPLETE_CLIENT_TOUR = 30,
  }

  export type TutorialStep = {
    ranking: number
    title: string
    body: string
    type:
      | ProducerTutorialStepTypeEnum
      | CarrierTutorialStepTypeEnum
      | GroupTutorialStepTypeEnum
      | TourTutorialStepTypeEnum
    completed?: boolean
    active?: boolean
    navigationParam?: {
      navigation: string
      screen?: string
      params?: any
    }
  }
  export enum TutorialStepStatusEnum {
    COMPLETED = 20,
    ACTIVE = 10,
    DISABLED = 0,
  }
}

export type TutorialStepData = {
  type:
    | TutorialStepsEnums.ProducerTutorialStepTypeEnum
    | TutorialStepsEnums.CarrierTutorialStepTypeEnum
    | TutorialStepsEnums.GroupTutorialStepTypeEnum
    | TutorialStepsEnums.TourTutorialStepTypeEnum
  completed: boolean
  data?: any
}

export const ProducerTutorialSteps: TutorialStepsEnums.TutorialStep[] = [
  {
    ranking: 1,
    title: 'Completez votre fiche producteur',
    body: 'Vos informations visibles par vos clients pro',
    type: TutorialStepsEnums.ProducerTutorialStepTypeEnum.COMPLETE_PROFILE,
    navigationParam: {
      navigation: 'ProfileProducerNav',
      screen: 'ProducerPublicProfile',
    },
  },
  {
    ranking: 2,
    title: 'Ajoutez vos produits',
    body: 'Composez votre catalogue de produits',
    type: TutorialStepsEnums.ProducerTutorialStepTypeEnum.COMPLETE_PRODUCT,
    navigationParam: {
      navigation: 'ProducerCatalog',
    },
  },
  {
    ranking: 3,
    title: `Rejoignez un groupe ou devenez ${FormatUtils.getCarrierLabel(
      GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS,
    )}`,
    body: `Rejoignez un groupe à proximité ou partez en livraison en créant votre compte ${FormatUtils.getCarrierLabel(
      GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS,
    )}`,
    type: TutorialStepsEnums.ProducerTutorialStepTypeEnum.COMPLETE_HAS_GROUP,
    navigationParam: {
      navigation: 'ProducerGroups',
      screen: 'ProducerGroups',
    },
  },
  {
    ranking: 4,
    title: 'Rejoignez votre première tournée',
    body: 'Participez à votre première vente/livraison groupée',
    type: TutorialStepsEnums.ProducerTutorialStepTypeEnum.COMPLETE_TOUR,
    navigationParam: {
      navigation: 'ProducerTours',
    },
  },
  {
    ranking: 5,
    title: 'Composez votre mercuriale',
    body: 'Ajoutez vos disponibilités et vos prix',
    type: TutorialStepsEnums.ProducerTutorialStepTypeEnum.COMPLETE_PROPOSITION,
    navigationParam: undefined,
  },
  {
    ranking: 6,
    title: 'Recevez vos commandes',
    body: 'Consultez les commandes et la marchandise à déposer',
    type: TutorialStepsEnums.ProducerTutorialStepTypeEnum.COMPLETE_ORDER,
    navigationParam: undefined,
  },
  {
    ranking: 7,
    title: 'Complétez vos informations légales',
    body: 'Vos informations légales nécessaire pour commencer à vendre vos produits',
    type: TutorialStepsEnums.ProducerTutorialStepTypeEnum.COMPLETE_LEGAL_INFOS,
    navigationParam: {
      navigation: 'ProfileProducerNav',
      screen: 'ProducerInformations',
    },
  },
]

export const GroupTutorialSteps: TutorialStepsEnums.TutorialStep[] = [
  {
    ranking: 1,
    title: 'Créez votre tournée',
    body: 'Programmez votre première tournée',
    type: TutorialStepsEnums.GroupTutorialStepTypeEnum.COMPLETE_ADD_TOUR,
    navigationParam: {
      navigation: 'CarrierTours',
      screen: 'CarrierAddTour',
    },
  },
  {
    ranking: 2,
    title: 'Invitez vos clients',
    body: 'Invitez les clients à rejoindre votre groupe pour participer à vos tournées',
    type: TutorialStepsEnums.GroupTutorialStepTypeEnum.COMPLETE_ADD_CLIENT_GROUP,
    navigationParam: {
      navigation: 'CarrierGroup',
      screen: 'CarrierGroupClients',
    },
  },
  {
    ranking: 3,
    title: 'Invitez des producteurs à rejoindre votre groupe',
    body: 'Invitez au moins 2 collègues producteurs à rejoindre votre groupe',
    type: TutorialStepsEnums.GroupTutorialStepTypeEnum.COMPLETE_ADD_PRODUCER_GROUP,
    navigationParam: {
      navigation: 'CarrierGroup',
      screen: 'CarrierGroupProducers',
    },
  },
  {
    ranking: 4,
    title: 'Réalisez votre première tournée mutualisée',
    body: 'Invitez au moins 2 collègues producteurs à rejoindre votre tournée',
    type: TutorialStepsEnums.GroupTutorialStepTypeEnum.COMPLETE_SHARED_TOUR,
    navigationParam: {
      navigation: 'CarrierTours',
      screen: 'CarrierTour',
    },
  },
]

export const TourTutorialSteps: TutorialStepsEnums.TutorialStep[] = [
  {
    ranking: 1,
    title: 'Estimez vos coûts de livraison',
    body: '',
    type: TutorialStepsEnums.TourTutorialStepTypeEnum.COMPLETE_DELIVERY_EXPENSES_PARAMS,
    navigationParam: {
      navigation: 'CarrierTours',
      screen: 'CarrierTourDeliveryExpenses',
    },
  },
  {
    ranking: 2,
    title: 'Invitez un producteur à rejoindre votre tournée',
    body: 'Invitez au moins un collègue producteurs à rejoindre votre tournée',
    type: TutorialStepsEnums.TourTutorialStepTypeEnum.COMPLETE_PRODUCER_TOUR,
    navigationParam: {
      navigation: 'CarrierTours',
      screen: 'CarrierTour',
    },
  },
  {
    ranking: 3,
    title: 'Invitez un client à rejoindre votre tournée',
    body: 'Invitez au moins un client à rejoindre votre tournée',
    type: TutorialStepsEnums.TourTutorialStepTypeEnum.COMPLETE_CLIENT_TOUR,
    navigationParam: {
      navigation: 'CarrierTours',
      screen: 'CarrierTour',
    },
  },
]
