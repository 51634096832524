import { IntroductionSlideProps } from './IntroductionSlide.model'
import { Button, Spacer, Typography } from '../../../../../components'
import { ImageSourcePropType, View } from 'react-native'
import {
  BottomPartWrapper,
  ButtonWrapper,
  StyledImageLogo,
  StyledImageWrapper,
  TextWrapper,
} from './IntroductionSlide.styles'
import InfoBox from '../../../../../components/InfoBox'
import { GroupEnums } from '../../../../../../enums/group'
import FormatUtils from '../../../../../utilities/utils/format'

export type TutorialStep = {
  id: number
  title: string
  imageSource: ImageSourcePropType
  label: string
  description: string
  isFinal?: boolean
}

const stepInfo = {
  id: 1,
  title: `Devenez ${FormatUtils.getCarrierLabel(GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS)}`,
  imageSource: require('../../../../../../assets/drawings/camion.png'),
  label: 'Organisez vos tournées de livraison mutualisées',
  description: `Vous effectuez déjà des tournées de livraison vers vos clients professionnels ? 

  Harvy vous aide à automatiser les nombreuses tâches de l’élaboration de votre proposition, la vente, la préparation des commandes et la livraison.`,
}
const info = {
  title: 'Comment Harvy me permet de gagner du temps ? ',
  content:
    'De nombreuses tâches sont totallement automatisées, par exemple, nous générons tout vos bons de livraison en moins de 5 secondes ! ',
}

const IntroductionSlide = ({ onClick }: IntroductionSlideProps) => {
  return (
    <>
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <View style={{ width: '100%', alignItems: 'center' }}>
        <Typography.PageTitle>{stepInfo.title}</Typography.PageTitle>
      </View>
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <StyledImageWrapper>
        <StyledImageLogo source={require('../../../../../../assets/drawings/camion.png')} />
      </StyledImageWrapper>
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <TextWrapper>
        <Typography.PageTitle colorName="color-primary" align="center">
          {stepInfo.label}
        </Typography.PageTitle>
        <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
        <Typography.Body colorName="color-primary" align="center">
          {stepInfo.description}
        </Typography.Body>
      </TextWrapper>
      <Spacer size={3} axis={Spacer.AxisEnum.VERTICAL} />
      <View style={{ width: '100%' }}>
        <Typography.Body bold align="center">
          Effectuez vous déjà des tournées de livraison ?
        </Typography.Body>
      </View>
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <InfoBox title={info.title} content={info.content} />
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <BottomPartWrapper>
        <ButtonWrapper>
          <Button onPress={() => onClick(false)} label="Non" colorName="color-secondary" />
        </ButtonWrapper>
        <Spacer size={2} axis={Spacer.AxisEnum.HORIZONTAL} />
        <ButtonWrapper>
          <Button onPress={() => onClick(true)} label="Oui" colorName="color-secondary" />
        </ButtonWrapper>
      </BottomPartWrapper>
    </>
  )
}

export default IntroductionSlide
