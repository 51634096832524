import { TourEndSlideProps } from './TourEndSlide.model'
import { Button, Spacer, Typography } from '../../../../../components'
import { ImageSourcePropType, View } from 'react-native'
import {
  ButtonWrapper,
  StyledImageLogo,
  StyledImageWrapper,
  TextWrapper,
} from './TourEndSlide.styles'
import { GroupEnums } from '../../../../../../enums/group'
import FormatUtils from '../../../../../utilities/utils/format'

export type TutorialStep = {
  id: number
  title: string
  imageSource: ImageSourcePropType
  label: string
  description: string
  isFinal?: boolean
}

const stepInfo = {
  id: 1,
  title: `Votre compte ${FormatUtils.getCarrierLabel(
    GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS,
  )} a été créé`,
  imageSource: require('../../../../../../assets/drawings/camion.png'),
  label: 'Organisez vos tournées de livraison mutualisées',
  description: `Vous effectuez déjà des tournées de livraison vers vos clients professionnels ? 

  Harvy vous aide à automatiser les nombreuses tâches de l’élaboration de votre proposition, la vente, la préparation des commandes et la livraison.`,
}

const TourEndSlide = ({ onClick }: TourEndSlideProps) => {
  return (
    <>
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <View style={{ width: '100%', alignItems: 'center' }}>
        <Typography.PageTitle>{stepInfo.title}</Typography.PageTitle>
      </View>
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <StyledImageWrapper>
        <StyledImageLogo source={require('../../../../../../assets/drawings/camion.png')} />
      </StyledImageWrapper>
      <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
      <TextWrapper>
        <Typography.PageTitle colorName="color-primary" align="center">
          {'Nous allons vous basculer dans votre espace livreur'}
        </Typography.PageTitle>
        <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
        <Typography.Body colorName="color-primary" align="center">
          {`Dans cet espace vous pouvez gérer vos tournées de livraison. Vous pouvez à tout moment revenir sur votre espace producteur en cliquant sur le bouton “mode producteur”`}
        </Typography.Body>
      </TextWrapper>
      <Spacer size={5} axis={Spacer.AxisEnum.VERTICAL} />
      <ButtonWrapper>
        <Button onPress={() => onClick()} label="Basculer" colorName="color-secondary" />
      </ButtonWrapper>
    </>
  )
}

export default TourEndSlide
