import { DeliveryNoteTypeEnum } from '../../../../enums'
import { DeliveryNotesCartBatchs, Order, Pagination, UdpateCartBatchCarrier } from '../../../domain'
import ApiService from '../../api.service'

const getAllCartBatchs = async (
  groupId: string,
  tourId?: string,
  clientId?: string,
  producerId?: string,
  before?: Date,
  after?: Date,
  limit = 30,
  pageNumber = 1,
): Promise<{ data: DeliveryNotesCartBatchs[]; pagination: Pagination; stats: any }> => {
  const response = await ApiService.get(`/order/group/${groupId}/batchs`, {
    clientId: clientId || undefined,
    tourId: tourId || undefined,
    producerId: producerId || undefined,
    before: before || undefined,
    after: after || undefined,
    limit,
    page: pageNumber,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })

  return response
}

const getStatsProducerSales = async (
  groupId: string,
  tourId?: string,
  clientId?: string,
  producerId?: string,
  before?: Date,
  after?: Date,
): Promise<{
  totalHTProducer: number
  totalHT: number
  totalHTCheck: number
}> => {
  const response = await ApiService.get(`/order/stats/producer-sales/`, {
    clientId: clientId || undefined,
    tourId: tourId || undefined,
    producerId: producerId || undefined,
    groupId,
    before: before || undefined,
    after: after || undefined,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })

  return response
}

const getDeliveryNoteStats = async (
  type?: DeliveryNoteTypeEnum,
  tourId?: string,
  clientId?: string,
  producerId?: string,
  before?: Date,
  after?: Date,
): Promise<any> => {
  const response = await ApiService.get(`/order/stats/sum-delivery-notes`, {
    clientId: clientId || undefined,
    tourId: tourId || undefined,
    producerId: producerId || undefined,
    type: type || undefined,
    before: before || undefined,
    after: after || undefined,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getCSVCartBatchs = async (
  groupId: string,
  tourId?: string,
  clientId?: string,
  producerId?: string,
  before?: Date,
  after?: Date,
  limit = 30,
  pageNumber = 1,
): Promise<any> => {
  const response = await ApiService.get(`/order/group/${groupId}/cart-batchs/csv`, {
    clientId: clientId || undefined,
    tourId: tourId || undefined,
    producerId: producerId || undefined,
    before: before || undefined,
    after: after || undefined,
    limit,
    page: pageNumber,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })

  return response
}

const getOneCartOrder = async (cartId: string, clientId: string) => {
  const response = await ApiService.get(`/cart/${cartId}/client/${clientId}`).catch((error) => {
    throw error
  })
  return response
}

const getLastOrdersClient = async (tourId: string, clientId?: string) => {
  if (!clientId) return
  const response = await ApiService.get(`/order/client/${clientId}/last-tour/${tourId}`).catch(
    (error) => {
      throw error
    },
  )
  return response
}

const updateCartBatch = async (orderId: string, clientId: string, dto: UdpateCartBatchCarrier) => {
  const response = await ApiService.post(
    `/order/${orderId}/client/${clientId}/cartBatch/`,
    dto,
  ).catch((error) => {
    throw error
  })
  return response
}

const deleteOne = async (idOrder: string): Promise<Order> => {
  const response = await ApiService.remove(`/order/${idOrder}`).catch((error) => {
    throw error
  })
  return response
}

const create = async (dto: any): Promise<Order> => {
  const response = await ApiService.post(`/order`, dto).catch((error) => {
    throw error
  })
  return response
}

const update = async (dto: any): Promise<Order> => {
  const response = await ApiService.patch(`/order`, dto).catch((error) => {
    throw error
  })
  return response
}

const OrderCarrierService = {
  getAllCartBatchs,
  getCSVCartBatchs,
  getOneCartOrder,
  getLastOrdersClient,
  updateCartBatch,
  deleteOne,
  create,
  update,
  getStatsProducerSales,
  getDeliveryNoteStats,
}

export default OrderCarrierService
