import FormatUtils from '../../utilities/utils/format'
import Spacer from '../Spacer'
import Typography from '../Typography'
import { ProducerTotalSummaryCardProps } from './TotalSummaryCard.model'
import {
  SummaryLeftBox,
  SummaryMiddleBox,
  SummaryRightBox,
  TotalSummaryCardContent,
  TotalSummaryCardWrapper,
} from '../TotalSummaryCard/TotalSummaryCard.styles'
import FeesUtil from '../../utilities/utils/fees'

const TotalSummaryCardProducerStatement = ({
  producerTotalAmounts,
  producerFeesDetails,
  producerFeesTotalAmounts,
  producerBankTransferAmount,
  producerInvoices,
}: ProducerTotalSummaryCardProps) => {
  const platformFees = FeesUtil.getFeesByType(2, producerFeesDetails)
  const logisticsFees = FeesUtil.getFeesByType(1, producerFeesDetails)
  const logisticsFees2 = FeesUtil.getFeesByType(1, producerFeesDetails, 1)

  return (
    <TotalSummaryCardWrapper>
      <TotalSummaryCardContent>
        <SummaryLeftBox>
          <Typography.Body>Total ventes HT</Typography.Body>
          <Typography.Body>Total TVA</Typography.Body>
          <Typography.Body bold>Total ventes TTC</Typography.Body>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <Typography.Body>{FeesUtil.getFeeLabel(2, producerInvoices)}</Typography.Body>
          <Typography.Body ellipsis>{FeesUtil.getFeeLabel(1, producerInvoices)}</Typography.Body>
          {logisticsFees2 && logisticsFees2.amount > 0 ? (
            <Typography.Body ellipsis>{FeesUtil.getFeeLabel(3, producerInvoices)}</Typography.Body>
          ) : undefined}
          <Typography.Body>Total frais HT</Typography.Body>
          <Typography.Body>TVA frais (20%)</Typography.Body>
          <Typography.Body bold>Total frais TTC</Typography.Body>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <Typography.Body bold>Versement</Typography.Body>
        </SummaryLeftBox>

        <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={2} />
        <SummaryMiddleBox>
          <Typography.Body>{FormatUtils.formatPercentage(platformFees?.rate)}</Typography.Body>
          <Typography.Body>{FormatUtils.formatPercentage(logisticsFees?.rate)}</Typography.Body>
          {logisticsFees2 && logisticsFees2.amount > 0 ? (
            <Typography.Body>{FormatUtils.formatPercentage(logisticsFees2?.rate)}</Typography.Body>
          ) : undefined}
        </SummaryMiddleBox>

        <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={2} />
        <SummaryRightBox>
          <Typography.Body>
            {FormatUtils.formatPrice(producerTotalAmounts?.totalHT)}
          </Typography.Body>
          <Typography.Body>
            {FormatUtils.formatPrice(producerTotalAmounts?.totalVAT)}
          </Typography.Body>
          <Typography.Body bold>
            {FormatUtils.formatPrice(producerTotalAmounts?.totalTTC)}
          </Typography.Body>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <Typography.Body>{FormatUtils.formatPrice(platformFees.amount)}</Typography.Body>
          <Typography.Body>{FormatUtils.formatPrice(logisticsFees.amount)}</Typography.Body>
          {logisticsFees2 && logisticsFees2.amount > 0 ? (
            <Typography.Body>{FormatUtils.formatPrice(logisticsFees2.amount)}</Typography.Body>
          ) : undefined}
          <Typography.Body>
            {FormatUtils.formatPrice(producerFeesTotalAmounts?.totalHT)}
          </Typography.Body>
          <Typography.Body>
            {FormatUtils.formatPrice(producerFeesTotalAmounts?.totalVAT)}
          </Typography.Body>
          <Typography.Body bold>
            {FormatUtils.formatPrice(producerFeesTotalAmounts?.totalTTC)}
          </Typography.Body>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <Typography.Body bold>
            {FormatUtils.formatPrice(producerBankTransferAmount)}
          </Typography.Body>
        </SummaryRightBox>
      </TotalSummaryCardContent>
    </TotalSummaryCardWrapper>
  )
}

export default TotalSummaryCardProducerStatement
