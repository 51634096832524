import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import {
  Button,
  Card,
  Col,
  Icon,
  Icons,
  PageTitle,
  Row,
  Spacer,
  Typography,
} from '../../../components'
import TextInput from '../../../components/inputs/TextInput'
import Loader from '../../../components/Loader'
import { Batch, Cart, Tour, UdpateCartBatchCarrier } from '../../../domain'
import i18n from '../../../i18n'

import { queryClient } from '../../../utilities/queryClient'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import {
  BatchContentWrapper,
  StyledButtonWrapper,
  StyledCenteredValueBox,
} from './CarrierUpdateCartBatch.styles'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import OrderCarrierService from '../../../services/carrier/order'
import TourCarrierService from '../../../services/carrier/tour'
import BatchCarrierService from '../../../services/carrier/batch'
import TourUtil from '../../../utilities/utils/tour'
import { View } from 'react-native'
import { GroupEnums } from '../../../../enums/group'
import useGroupContext from '../../../utilities/hook/useGroupContext'
import { TutorialStepData } from '../../../../enums/tutorialStep'

const CarrierUpdateCartBatchScreen = () => {
  const theme = useTheme()
  const { group } = useGroupContext()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierUpdateCartBatch'>>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [crateQuantityString, setCrateQuantityString] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [priceUpdateMode, setPriceUpdateMode] = useState(false)

  const [isConfirmed, setIsConfirmed] = useState(false)

  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()

  const [cartBatch, setCartBatch] = useState<UdpateCartBatchCarrier>()

  const { id, cartId, clientId, tourId } = route?.params ?? {
    id: '',
    cartId: '',
    clientId: '',
    tourId: '',
  }

  const {
    data: tour,
    refetch: refetchTour,
    isInitialLoading: tourLoading,
  } = TourCarrierService.getOne.query(tourId)

  const {
    data: cart,
    refetch: refetchCart,
    isInitialLoading: cartLoading,
  } = useQuery<Cart, Error>(['sf_cart', cartId], () =>
    OrderCarrierService.getOneCartOrder(cartId, clientId),
  )

  const {
    data: batch,
    refetch: refetchProducts,
    isInitialLoading: batchLoading,
  } = useQuery<Batch, Error>(
    ['sf_batch', cartBatch?.batch?.id],
    () => BatchCarrierService.getOneBatch(cartBatch?.batch?.id || ''),
    {
      enabled: cartBatch?.batch?.id !== undefined,
    },
  )

  useEffect(() => {
    if (cart && cart.cartBatchs) {
      const foundCartBatch = cart.cartBatchs.find((cb) => cb.id === id)

      if (foundCartBatch) {
        setCartBatch({
          batchId: foundCartBatch.batch?.id,
          clientId: clientId,
          packingQuantity: foundCartBatch.packingQuantity,
          packing: foundCartBatch.packing,
          totalQuantity: foundCartBatch.totalQuantity,
          unitPrice: foundCartBatch.unitPrice,
          unitPriceProducer: foundCartBatch.unitPriceProducer,
          batch: foundCartBatch.batch,
          carrierFees: foundCartBatch.carrierFees,
          platformFees: foundCartBatch.platformFees,
          otherBatchs: undefined,
        })
        setCrateQuantityString(
          (Math.round((foundCartBatch.packingQuantity || 0) * 100) / 100)?.toString() || '',
        )
      }
    }
  }, [cart])

  const setValue = (value: any, param: string) => {
    if (!cartBatch || (!param && !(param in cartBatch))) return null
    const cartBatchTemp = Object.assign({}, cartBatch)

    cartBatchTemp[param as keyof typeof cartBatch] = value
    setCartBatch(cartBatchTemp)
  }

  const onChangeCrateQuantity = (crateQuantityString: string) => {
    const cartBatchTemp = Object.assign({}, cartBatch)

    if (!crateQuantityString || crateQuantityString === '0') {
      cartBatchTemp.totalQuantity = 0
      cartBatchTemp.packingQuantity = 0
      setCartBatch(cartBatchTemp)
      setCrateQuantityString(crateQuantityString)

      return
    }
    const crateQuantity = parseFloat(crateQuantityString)
    const totalQuantity = crateQuantity * (cartBatch?.packing || 1)

    cartBatchTemp.totalQuantity = totalQuantity
    cartBatchTemp.packingQuantity = crateQuantity
    setCartBatch(cartBatchTemp)
    setCrateQuantityString(crateQuantityString)
  }

  const onUpdateCartBatch = async () => {
    if (!cartBatch || !cart) return

    if (cartBatch?.totalQuantity === undefined || cartBatch?.totalQuantity < 0) {
      setErrorMessage('La quantité disponible ne peut pas être inférieure à 0')
      return
    }

    if (!cart.order?.id || !cart.client?.id) {
      setErrorMessage('Aucune commande retrouvée ou client associé')
      return
    }

    if (getCarrierFeesComputed() * 100 < 0) {
      setErrorMessage('Le prix producteur ne doit pas être supérieur au prix client')
      return
    }

    setErrorMessage('')

    setIsLoading(true)
    const cartBatchTemp = {
      batchId: cartBatch.batchId,
      packingQuantity: cartBatch.packingQuantity,
      packing: cartBatch.packing,
      totalQuantity: cartBatch.totalQuantity,
      unitPrice: FormatUtils.stringToFloat(cartBatch.unitPrice?.toString()),
      unitPriceProducer: FormatUtils.stringToFloat(cartBatch.unitPriceProducer?.toString()),
      carrierFees: getCarrierFeesComputed() * 100,
      otherBatchs: undefined,
    }

    const updatedCartBatchTemp = await OrderCarrierService.updateCartBatch(
      cart.order?.id,
      cart.client?.id,
      cartBatchTemp,
    )
    if (updatedCartBatchTemp && updatedCartBatchTemp.id) {
      await queryClient.setQueryData(['sf_cart', cartId], null)
      await queryClient.invalidateQueries(['sf_cart', cartId])
      await queryClient.invalidateQueries(['sf_tour', tourId])
      await queryClient.invalidateQueries(['sf_stop'])
      await queryClient.invalidateQueries(['sf_tour_producer'])
      await queryClient.invalidateQueries(['sf_next_tours'])
      await queryClient.invalidateQueries(['sf_tour_clients'])
      await queryClient.invalidateQueries(['sf_tour_delivery_notes', tourId, clientId])
      await queryClient.invalidateQueries(['sf_tour_delivery_notes', tourId])
      await queryClient.invalidateQueries(['sf_tour_delivery_notes_orders', tourId])
      await queryClient.invalidateQueries(['sf_tour_delivery_notes_producers', tourId])

      await queryClient.invalidateQueries([
        'sf_tour_delivery_notes',
        tourId,
        cartBatch.batch?.product?.producer?.id,
      ])

      setIsConfirmed(true)
    } else {
      setErrorMessage('Un problème est survenu lors de la mise à jour')
    }
    setIsLoading(false)
  }

  const onGoBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack()
    }
  }

  if (cartLoading || !cartBatch || tourLoading || batchLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!cart || !cartBatch || !tour || !batch) return null

  const getAvailablePackingQuantity = () => {
    let availableCrateQuantity = 0
    if (
      !cart ||
      !cart.cartBatchs ||
      !batch ||
      (!batch.availableQuantity && batch.availableQuantity !== 0)
    ) {
      return 0
    }

    const oldCartBatch = cart?.cartBatchs.find((cb) => cb.id === id)
    const diffOldVsNewQuantity =
      (cartBatch.packingQuantity || 0) - (oldCartBatch?.packingQuantity || 0)

    availableCrateQuantity =
      batch.availableQuantity / (cartBatch.packing || 1) - diffOldVsNewQuantity

    return Math.round(availableCrateQuantity * 100) / 100
  }

  const OnClickUpdateBatch = () => {
    navigation.navigate('CarrierUpdateBatch', { batchId: batch.id, tourId: tour.id })
  }

  const getCarrierFeesComputed = () => {
    const platformFeesRatio = (cartBatch.platformFees || 0) / 100
    if (cartBatch.unitPrice && cartBatch.unitPriceProducer) {
      const convertedUnitPrice = FormatUtils.stringToFloat(cartBatch.unitPrice.toString())
      const convertedUnitPriceProducer = FormatUtils.stringToFloat(
        cartBatch.unitPriceProducer.toString(),
      )
      const platformFeesAmount = convertedUnitPrice * platformFeesRatio

      const carrierFeesAmount = convertedUnitPrice - convertedUnitPriceProducer - platformFeesAmount
      const carrierFeesRatio =
        Math.round(((carrierFeesAmount * 100) / convertedUnitPrice) * 10000) / 1000000

      return carrierFeesRatio
    } else {
      return 0
    }
  }
  const groupTypeIsDeliveryProducers = group?.type === GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <BatchContentWrapper>
          <PageTitle
            title={` Modifier une commande`}
            buttonRight={
              <>
                <Typography.BodySmall>
                  {FormatUtils.capitalize(FormatUtils.formatDate(tour.start, 'FullDate'))}
                  {'\n'}
                  {FormatUtils.capitalize(
                    FormatUtils.formatDate(tour.start, 'StartTime+EndTime', tour.end),
                  )}
                </Typography.BodySmall>
              </>
            }
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <ScrollableFormWrapper>
            <Card>
              <Typography.Body>Client</Typography.Body>
              <Typography.CardTitle>{cart?.client?.label}</Typography.CardTitle>
            </Card>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <Card>
              <Typography.Body>Lot</Typography.Body>
              <Typography.CardTitle>{cartBatch.batch?.product?.label}</Typography.CardTitle>
              <Typography.Body>{cartBatch.batch?.product?.producer?.label}</Typography.Body>
            </Card>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />

            <Row>
              <Col xs={12} sm={12} md={4} lg={4}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                {!isConfirmed && priceUpdateMode ? (
                  <TextInput
                    value={cartBatch.unitPriceProducer?.toString()}
                    label={`Prix producteur (par ${FormatUtils.formatUnity(
                      cartBatch.batch?.product?.mesureType,
                    )})`}
                    field="text"
                    onChangeText={(text) => setValue(text, 'unitPriceProducer')}
                    editable
                  />
                ) : (
                  <StyledCenteredValueBox>
                    <Typography.BodySmall>
                      Prix producteur (par{' '}
                      {FormatUtils.formatUnity(cartBatch.batch?.product?.mesureType)})
                    </Typography.BodySmall>

                    <View style={{ flexDirection: 'row' }}>
                      <Typography.BodySmall bold>
                        {FormatUtils.formatPrice(cartBatch.unitPriceProducer)}
                      </Typography.BodySmall>
                      <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
                      {!isConfirmed && (
                        <Icon
                          type={Icons.Ionicons}
                          name="pencil-outline"
                          color={theme.colors['color-primary']}
                          size={theme.spacingUnit * 1.5}
                          onPress={() => setPriceUpdateMode(true)}
                        />
                      )}
                    </View>
                  </StyledCenteredValueBox>
                )}
              </Col>
              <Col xs={12} sm={12} md={4} lg={4} alignItems="center">
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.BodyExtraSmall>
                  Ancienne Commission Logistique
                </Typography.BodyExtraSmall>
                <Typography.BodySmall bold>
                  {FormatUtils.formatPercentage((cartBatch.carrierFees || 0) / 100)}
                </Typography.BodySmall>
                <Spacer size={0.7} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.BodyExtraSmall>Commission Logistique</Typography.BodyExtraSmall>
                <Typography.BodySmall bold>
                  {FormatUtils.formatPercentage(getCarrierFeesComputed())}
                </Typography.BodySmall>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                {!isConfirmed && priceUpdateMode ? (
                  <TextInput
                    value={cartBatch.unitPrice?.toString()}
                    label={`Prix unitaire (par ${FormatUtils.formatUnity(
                      cartBatch.batch?.product?.mesureType,
                    )})`}
                    field="text"
                    onChangeText={(text) => setValue(text, 'unitPrice')}
                    editable
                  />
                ) : (
                  <StyledCenteredValueBox>
                    <Typography.BodySmall>
                      Prix unitaire (par{' '}
                      {FormatUtils.formatUnity(cartBatch.batch?.product?.mesureType)})
                    </Typography.BodySmall>

                    <View style={{ flexDirection: 'row' }}>
                      <Typography.BodySmall bold>
                        {FormatUtils.formatPrice(cartBatch.unitPrice)}
                      </Typography.BodySmall>
                      <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
                      {!isConfirmed && (
                        <Icon
                          type={Icons.Ionicons}
                          name="pencil-outline"
                          color={theme.colors['color-primary']}
                          size={theme.spacingUnit * 1.5}
                          onPress={() => setPriceUpdateMode(true)}
                        />
                      )}
                    </View>
                  </StyledCenteredValueBox>
                )}
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                {!isConfirmed ? (
                  <TextInput
                    value={crateQuantityString}
                    label={`Colis commandés (dispo: ${getAvailablePackingQuantity()})`}
                    field="text"
                    onChangeText={(text) => onChangeCrateQuantity(text)}
                    editable
                  />
                ) : (
                  <StyledCenteredValueBox>
                    <Typography.BodySmall>Colis commandés</Typography.BodySmall>
                    <Typography.BodySmall bold>{cartBatch.packingQuantity}</Typography.BodySmall>
                  </StyledCenteredValueBox>
                )}
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                {!isConfirmed ? (
                  <TextInput
                    value={cartBatch.totalQuantity?.toString()}
                    label={`Quantité totale (en ${FormatUtils.formatUnity(
                      cartBatch.batch?.product?.mesureType,
                    )})`}
                    field="text"
                    onChangeText={(text) => setValue(text, 'totalQuantity')}
                    editable
                  />
                ) : (
                  <StyledCenteredValueBox>
                    <Typography.BodySmall>Quantité totale</Typography.BodySmall>
                    <Typography.BodySmall bold>
                      {FormatUtils.formatQuantity(
                        cartBatch.totalQuantity,
                        cartBatch.batch?.product?.mesureType,
                      )}
                    </Typography.BodySmall>
                  </StyledCenteredValueBox>
                )}
              </Col>
            </Row>
            <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />

            {!isConfirmed && (
              <>
                <Typography.BodySmall>
                  En changeant le prix et/ou la quantité des colis commandés, le montant total de la
                  commande sera mis à jour et le bon de livraison sera supprimé.
                </Typography.BodySmall>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
                {errorMessage ? (
                  <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
                ) : null}
                {getAvailablePackingQuantity() < 0 ? (
                  <Typography.Body colorName="color-danger">
                    Les quantités disponibles ne sont pas suffisantes
                  </Typography.Body>
                ) : null}
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                {TourUtil.isTourCompleted(tour) && (
                  <View style={{ width: '100%', alignItems: 'center' }}>
                    <Typography.Body colorName="color-grey">
                      Cette tournée est terminée, vous ne pouvez plus modifier cette commande.
                    </Typography.Body>
                    <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                  </View>
                )}
                <StyledButtonWrapper>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <Button
                    label={'Modifier Stock'}
                    onPress={() => OnClickUpdateBatch()}
                    colorName="text-dark-3"
                    disabled={TourUtil.isTourCompleted(tour) || groupTypeIsDeliveryProducers}
                  />
                  <Spacer size={2} axis={Spacer.AxisEnum.HORIZONTAL} />

                  <Button
                    label={FormatUtils.capitalize(i18n.t('save'))}
                    onPress={() => onUpdateCartBatch()}
                    loading={isLoading}
                    hasDoubleValidation
                    confirmationLabel="Êtes-vous sûr de vouloir modifier cette commande ?"
                    disabled={
                      getAvailablePackingQuantity() < 0 ||
                      TourUtil.isTourCompleted(tour) ||
                      groupTypeIsDeliveryProducers
                    }
                  />
                </StyledButtonWrapper>
              </>
            )}
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            {groupTypeIsDeliveryProducers && (
              <Typography.BodySmall colorName="color-warning" align="center">
                Vous ne pouvez pas modifier cette information, car vous êtes dans un groupe de type
                "{FormatUtils.getCarrierLabel(GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS)}". Seuls
                les producteurs peuvent modifier cette information depuis leur espace.
              </Typography.BodySmall>
            )}

            {isConfirmed && (
              <>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
                <Typography.BodySmall colorName="color-danger">
                  Pensez à re-générer les bons de livraison et actualiser les identifications de
                  cagette
                </Typography.BodySmall>
                <Spacer size={3} axis={Spacer.AxisEnum.VERTICAL} />

                <StyledButtonWrapper>
                  <Button label={'Terminer'} onPress={() => onGoBack()} loading={isLoading} />
                </StyledButtonWrapper>
              </>
            )}

            <Spacer size={3} axis={Spacer.AxisEnum.VERTICAL} />
          </ScrollableFormWrapper>
        </BatchContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierUpdateCartBatchScreen
