import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import { Spacer } from '../../../components'
import { Tour } from '../../../domain'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import { useQuery } from '@tanstack/react-query'
import TourCarrierService from '../../../services/carrier/tour'
import Loader from '../../../components/Loader'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { UserEnums } from '../../../../enums'
import TourTopBox from '../../../components/TourTopBox'
import SliderMenu from '../../../components/SliderMenu'
import CarrierTourOrganization from './slides/CarrierTourOrganization'
import CarrierTourClientsV2 from './slides/CarrierTourClients'
import CarrierTourProducersV2 from './slides/CarrierTourProducers'
import CarrierTourDocuments from './slides/CarrierTourDocuments'

const carrierTourModes = [
  {
    label: 'Organisation',
    value: 'ORGANIZATION',
  },
  {
    label: 'Producteurs',
    value: 'PRODUCERS',
  },
  {
    label: 'Clients',
    value: 'CLIENTS',
  },
  {
    label: 'Documents',
    value: 'DOCUMENTS',
  },
]

const CarrierTourScreen = () => {
  const theme = useTheme()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierTour'>>()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const { carrierHasRight } = useAuthenticationContext()
  const [selectedMode, setSelectedMode] = useState<string | number>(carrierTourModes[0].value)
  const [modePreselected, setModePreselected] = useState<Boolean>(true)

  const { id } = route?.params ?? { id: '' }

  let mode = route?.params?.mode
  const {
    data: tour,
    refetch: refetchTour,
    isInitialLoading: tourLoading,
  } = TourCarrierService.getOne.query(id)

  const {
    data: tourBatchs,
    refetch: refetchTourBatchs,
    isInitialLoading: tourBatchsLoading,
  } = useQuery<Tour, Error>(['sf_tour_batchs', id], () => TourCarrierService.getOneTourBatchs(id), {
    keepPreviousData: true,
  })

  useEffect(() => {
    if (mode && modePreselected) {
      setSelectedMode(mode)
      setModePreselected(false)
    } else if (selectedMode) {
      setSelectedMode(selectedMode)
    }
  }, [selectedMode])

  useEffect(() => {
    refetchTour()
  }, [tour])

  const onCardClick = (mode: string) => {
    setSelectedMode(mode)
  }

  if (tourLoading || tourBatchsLoading) {
    return <Loader isLoading pageLoading paddingLeft />
  }

  if (!tour) return null

  const renderSelectedComponent = () => {
    switch (selectedMode) {
      case 'ORGANIZATION':
        return (
          <CarrierTourOrganization
            tour={tour}
            stepsData={tour.stepsData || []}
            tourCarrierBatchs={tourBatchs?.batchs}
            onCardClick={(mode: string) => onCardClick(mode)}
          />
        )
      case 'CLIENTS':
        return <CarrierTourClientsV2 tour={tour} />
      case 'PRODUCERS':
        return <CarrierTourProducersV2 tour={tour} />
      case 'DOCUMENTS':
        return <CarrierTourDocuments tour={tour} />
      default:
        return null
    }
  }

  const onSelectElementSliderMenu = (value: string | number) => {
    setSelectedMode(value)
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <TourTopBox tour={tour} userRole={UserEnums.RoleEnums.CARRIER} />
      <ContentWrapper marginTopLarge>
        <Spacer fixedSize={65} axis={Spacer.AxisEnum.VERTICAL} />
        <SliderMenu
          elements={carrierTourModes}
          onSelectElement={onSelectElementSliderMenu}
          defaultSelectedElement={selectedMode}
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1.6} />
        {renderSelectedComponent()}

        {/*
          <Typography.SectionTitle>Conditions</Typography.SectionTitle>
          <CardPageLink label={`Règles camion (2)`} icon="arrow-right" onClick={() => null} />
          <CardPageLink label={`Règles Magasins (3)`} icon="arrow-right" onClick={() => null} />
        */}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierTourScreen
