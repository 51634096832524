import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { useTheme } from 'styled-components/native'
import { CLIENT_TYPES } from '../../../../enums/client'
import {
  Button,
  Col,
  Divider,
  Dropdown,
  Icon,
  Icons,
  PageTitle,
  Row,
  SectionTitle,
  Spacer,
  Typography,
} from '../../../components'
import TextInput from '../../../components/inputs/TextInput'
import { UpdateClient, Group, Client, ClientGroup, Address, AddressType } from '../../../domain'
import i18n from '../../../i18n'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import ClientCarrierService from '../../../services/carrier/client'
import GroupCarrierService from '../../../services/carrier/group'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { queryClient } from '../../../utilities/queryClient'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import { ToursContentWrapper, StyledButtonWrapper } from './CarrierUpdateClient.styles'
import LabelForm from '../../../modules/Common/LabelForm'
import { LabelEnums } from '../../../../enums'
import GeneralUtils from '../../../utilities/utils/general'
import { TutorialStepData } from '../../../../enums/tutorialStep'
import AddressForm from '../../../modules/Address/AddressForm'
import AccountService from '../../../services/billing/account'
import CardSettings from '../../../components/CardSettings'

const CarrierUpdateClientScreen = () => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierUpdateClient'>>()
  const [isLoading, setIsLoading] = useState(false)

  const { getAccessInfos } = useAuthenticationContext()

  const { id } = route?.params ?? { id: '' }

  const {
    data: groupData,
    refetch,
    isInitialLoading: feedLoading,
  } = useQuery<{ group: Group; stepsData: TutorialStepData[] }, Error>(
    ['sf_group'],
    () =>
      GroupCarrierService.getOneGroup(getAccessInfos().carrierGroupId, getAccessInfos().carrierId),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: clientGroup,
    refetch: refetchClientGroup,
    isInitialLoading: isClientGroupLoading,
  } = useQuery<ClientGroup, Error>(
    ['sf_group_client', id],
    () =>
      GroupCarrierService.getOneClientGroup(
        getAccessInfos().carrierGroupId,
        getAccessInfos().carrierId,
        id,
      ),
    {
      keepPreviousData: true,
    },
  )

  const [updatedClient, setUpdatedClient] = useState<UpdateClient>()
  const [selectedClientType, setSelectedClientType] = useState<any | undefined>(undefined)
  const [client, setClient] = useState<Client>()

  useEffect(() => {
    if (!groupData?.group || !clientGroup) return

    setUpdatedClient({
      ...clientGroup.client,
    })

    setClient(clientGroup.client)

    const clientType = CLIENT_TYPES.find((mt) => mt.value === clientGroup?.client?.clientType)

    if (clientType) {
      setSelectedClientType({
        ...clientType,
      })
    }
  }, [id, groupData?.group, clientGroup])

  const theme = useTheme()
  const [errorMessage, setErrorMessage] = useState<string>('')

  if (!updatedClient || !clientGroup) return <View></View>

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!param && !(param in updatedClient)) return null

    const clientTemp = Object.assign({}, updatedClient)

    clientTemp[param as keyof typeof updatedClient] = value
    setUpdatedClient(clientTemp)
  }

  const onChangeClientType = (value: any) => {
    setSelectedClientType(value)
  }

  const onUpdateClient = async () => {
    setErrorMessage('')
    setIsLoading(true)
    const client = {
      clientType: selectedClientType.value,
      label: updatedClient.label,
      owners: updatedClient.owners,
      phone1: updatedClient.phone1,
      phone2: updatedClient.phone2,
      mail: updatedClient.mail,
      addressLine1: updatedClient.addressLine1,
      addressLine2: updatedClient.addressLine2,
      postalCode: updatedClient.postalCode,
      city: updatedClient.city,
      labels: updatedClient.labels,
    }

    if (!GeneralUtils.isEmail(client.mail!)) {
      setErrorMessage(`Le format de l'email saisi est invalide`)
      setIsLoading(false)
      return
    }

    const updatedClientTemp = await ClientCarrierService.update(id, client)
    if (updatedClientTemp && updatedClientTemp.id) {
      await queryClient.fetchQuery(['sf_group'])
      await queryClient.fetchQuery(['sf_group_clients'])

      navigation.navigate('CarrierGroupClients', {})
    } else {
      setErrorMessage('Un problème est survenu lors de la mise à jour du client')
    }
    setIsLoading(false)
  }

  const onAddUser = () => {
    if (!client || !groupData?.group?.id) return
    navigation.navigate('CarrierAddUser', {
      groupId: groupData?.group.id,
      clientId: client.id,
      clientLabel: client.label,
    })
  }
  const onUpdateUser = (userId?: string) => {
    if (!client || !groupData?.group?.id || !userId) return
    navigation.navigate('CarrierUpdateUser', {
      groupId: groupData?.group.id,
      userId: userId,
      clientId: client.id,
    })
  }

  const onChangeDeliveryAddress = (newAddress: Address) => {
    if (newAddress && newAddress.id) {
      const clientTemp = Object.assign({}, updatedClient)

      clientTemp['deliveryAddress'] = newAddress
      setUpdatedClient(clientTemp)
      refetchClientGroup()
    }
  }
  const onChangeBillingAddress = async (newAddress: Address) => {
    if (newAddress && newAddress.id && clientGroup?.client?.account?.id) {
      await AccountService.update(clientGroup?.client?.account?.id, {
        address: { id: newAddress.id },
      })
      await refetchClientGroup()
    }
  }

  const onClickArchive = async () => {
    if (clientGroup?.archived) {
      return
    }

    await GroupCarrierService.archiveClientGroup(
      getAccessInfos().carrierGroupId,
      getAccessInfos().carrierId,
      id,
    ).then(() => {
      queryClient.invalidateQueries(['sf_group_client'])
      queryClient.invalidateQueries(['sf_group_clients'])
    })
  }

  if (clientGroup?.archived) {
    return (
      <ContentWrapper>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
        <Typography.PageTitle>{client?.label || 'Modifier un client'}</Typography.PageTitle>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
        <Divider />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <Typography.Body colorName="color-grey">
          Ce compte est archivé, le client n'a plus accès au groupe et à ces prochaines tournées.
        </Typography.Body>
        <Typography.Body colorName="color-grey">
          Vous devez le retirer manuellement des tournées dans lesquelles il est déjà présent.
        </Typography.Body>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
        <Typography.Body colorName="color-grey">
          Vous souhaitez ré-intégrer ce compte, contactez l'équipe Harvy.
        </Typography.Body>
      </ContentWrapper>
    )
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />

          <Typography.PageTitle>{client?.label || 'Modifier un client'}</Typography.PageTitle>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
          <Divider />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
          <ScrollableFormWrapper>
            <Typography.Body colorName="color-grey">Utilisateurs</Typography.Body>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />

            <Row>
              <Col xs={7}>
                {(client?.userClients || []).map((userClient) => (
                  <TouchableOpacity onPress={() => onUpdateUser(userClient?.user?.id)}>
                    <View style={{ flexDirection: 'row' }}>
                      <Typography.Body bold>{userClient.user?.email}</Typography.Body>
                      <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
                      <Icon
                        type={Icons.Ionicons}
                        name="pencil-outline"
                        color={theme.colors['color-primary']}
                        size={theme.spacingUnit * 1.5}
                      />
                    </View>

                    <Typography.BodySmall colorName="text-dark-3">
                      Dernière connexion :{' '}
                      {FormatUtils.formatDate(userClient.user?.updatedDate, 'Date')}
                    </Typography.BodySmall>
                    <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                  </TouchableOpacity>
                ))}
                {(client?.userClients || []).length < 1 && (
                  <Typography.Body colorName="color-grey">Aucun</Typography.Body>
                )}
              </Col>
              <Col xs={5}>
                <Button
                  label={'Ajouter un utilisateur'}
                  small
                  onPress={() => onAddUser()}
                  disabled={isLoading}
                />
              </Col>
            </Row>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <Dropdown
              label={'Type de client'}
              onChange={onChangeClientType}
              multiple={false}
              optionsDefault={CLIENT_TYPES.map((clientType) => ({
                ...clientType,
              }))}
              zIndex={200}
              itemKey="clientType"
              defaultValue={selectedClientType.value}
            />
            <Row>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedClient.label}
                  label="Nom du client"
                  field="text"
                  onChangeText={(text) => setValue(text, 'label')}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedClient.owners}
                  label="Gérant.e.s"
                  field="text"
                  onChangeText={(text) => setValue(text, 'owners')}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedClient.mail}
                  label="Email"
                  field="text"
                  onChangeText={(text) => setValue(text, 'mail')}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedClient.phone1}
                  label="Téléphone (0656...)"
                  field="text"
                  onChangeText={(text) => setValue(text, 'phone1')}
                />
              </Col>
            </Row>
            {updatedClient.deliveryAddress && (
              <AddressForm
                sectionTitle="Adresse de livraison"
                onChangeAddress={onChangeDeliveryAddress}
                address={updatedClient.deliveryAddress}
                addressType={AddressType.DELIVERY}
                client={clientGroup?.client}
              />
            )}
            {!updatedClient.deliveryAddress && (
              <>
                <SectionTitle title="Adresse de livraison" />
                <Row>
                  <Col xs={12} sm={12} md={6}>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <TextInput
                      value={updatedClient.addressLine1}
                      label="N°, rue"
                      field="text"
                      onChangeText={(text) => setValue(text, 'addressLine1')}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <TextInput
                      value={updatedClient.addressLine2}
                      label="Complément d'adresse"
                      field="text"
                      onChangeText={(text) => setValue(text, 'addressLine2')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={6}>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <TextInput
                      value={updatedClient.postalCode}
                      label="Code postal"
                      field="text"
                      onChangeText={(text) => setValue(text, 'postalCode')}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <TextInput
                      value={updatedClient.city}
                      label="Ville"
                      field="text"
                      onChangeText={(text) => setValue(text, 'city')}
                    />
                  </Col>
                </Row>
              </>
            )}

            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <AddressForm
              sectionTitle="Adresse de facturation"
              onChangeAddress={onChangeBillingAddress}
              address={clientGroup?.client?.account?.address}
              addressType={AddressType.BILLING}
              client={clientGroup?.client}
            />

            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <LabelForm
              labels={updatedClient.labels}
              validLabels={[LabelEnums.BIOCOOP]}
              type={1}
              onChangeLabel={(labels) => setValue(labels, 'labels')}
            />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          </ScrollableFormWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <StyledButtonWrapper>
            {errorMessage ? (
              <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
            ) : null}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />

            <Button
              label={FormatUtils.capitalize(i18n.t('save'))}
              onPress={() => onUpdateClient()}
              loading={isLoading}
              hasDoubleValidation
              confirmationLabel="Êtes-vous sûr de vouloir modifier ce client ?"
            />
          </StyledButtonWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={5} />
          <CardSettings
            title="Archiver ce compte client"
            description={`Le client n'aura plus accès aux tournées de ce groupe`}
          >
            <Button
              label={'Archiver'}
              confirmationLabel="Êtes-vous sûr de vouloir archiver ce client ?"
              hasDoubleValidation
              onPress={() => onClickArchive()}
              colorName="color-danger"
              small
            />
          </CardSettings>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={5} />
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierUpdateClientScreen
