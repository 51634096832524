import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useTheme } from 'styled-components/native'
import { Button, Col, Icon, Icons, PageTitle, Row, Spacer, Typography } from '../../../components'
import TextInput from '../../../components/inputs/TextInput'
import { UpdateCarrier, Group, Carrier, CarrierGroup, Address } from '../../../domain'
import i18n from '../../../i18n'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import GroupCarrierService from '../../../services/carrier/group'
import CarrierCarrierService from '../../../services/carrier/carrier'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { queryClient } from '../../../utilities/queryClient'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import { ToursContentWrapper, StyledButtonWrapper } from './CarrierUpdateCarrier.styles'
import { TouchableOpacity } from 'react-native-gesture-handler'
import GeneralUtils from '../../../utilities/utils/general'
import { TutorialStepData } from '../../../../enums/tutorialStep'
import AddressForm from '../../../modules/Address/AddressForm'
import { GroupEnums } from '../../../../enums/group'

const CarrierUpdateCarrierScreen = () => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierUpdateCarrier'>>()
  const [isLoading, setIsLoading] = useState(false)

  const { getAccessInfos, carrierHasRight } = useAuthenticationContext()

  const { id } = route?.params ?? { id: '' }

  const {
    data: groupData,
    refetch,
    isInitialLoading: feedLoading,
  } = useQuery<{ group: Group; stepsData: TutorialStepData[] }, Error>(
    ['sf_group'],
    () =>
      GroupCarrierService.getOneGroup(getAccessInfos().carrierGroupId, getAccessInfos().carrierId),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: carrierGroup,
    refetch: refetchCarrierGroup,
    isInitialLoading: isCarrierGroupLoading,
  } = useQuery<CarrierGroup, Error>(
    ['sf_group_carrier', id],
    () =>
      GroupCarrierService.getOneCarrierGroup(
        getAccessInfos().carrierGroupId,
        getAccessInfos().carrierId,
        id,
      ),
    {
      keepPreviousData: true,
    },
  )

  const [updatedCarrier, setUpdatedCarrier] = useState<UpdateCarrier>()
  const [carrier, setCarrier] = useState<Carrier>()

  useEffect(() => {
    if (!groupData?.group || !carrierGroup) return

    setUpdatedCarrier({
      ...carrierGroup.carrier,
    })
    setCarrier(carrierGroup.carrier)
  }, [id, setUpdatedCarrier, groupData?.group, carrierGroup])

  const theme = useTheme()

  const [errorMessage, setErrorMessage] = useState<string>('')

  if (!updatedCarrier) return <View></View>

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!param && !(param in updatedCarrier)) return null

    const carrierTemp = Object.assign({}, updatedCarrier)

    carrierTemp[param as keyof typeof updatedCarrier] = value
    setUpdatedCarrier(carrierTemp)
  }

  const onUpdateCarrier = async () => {
    if (updatedCarrier.mail && !GeneralUtils.isEmail(updatedCarrier.mail)) {
      setErrorMessage(`Le format de l'email saisi est invalide`)
      setIsLoading(false)
      return
    }
    setErrorMessage('')
    setIsLoading(true)
    const carrier = {
      name: updatedCarrier.name,
      legalName: updatedCarrier.legalName,
      addressLine1: updatedCarrier.addressLine1,
      addressLine2: updatedCarrier.addressLine2,
      postalCode: updatedCarrier.postalCode,
      city: updatedCarrier.city,
      phone1: updatedCarrier.phone1,
      phone2: updatedCarrier.phone2,
      mail: updatedCarrier.mail,
    }

    const updatedCarrierTemp = await CarrierCarrierService.update(id, carrier)
    if (updatedCarrierTemp && updatedCarrierTemp.id) {
      await queryClient.fetchQuery(['sf_group'])
      await queryClient.fetchQuery(['sf_group_carriers'])

      navigation.navigate('CarrierGroupCarriers', {})
    } else {
      setErrorMessage('Un problème est survenu lors de la mise à jour du producteur')
    }
    setIsLoading(false)
  }

  const onChangeAddress = (newAddress: Address) => {
    if (newAddress && newAddress.id) {
      const carrierTemp = Object.assign({}, updatedCarrier)

      carrierTemp['address'] = newAddress
      setUpdatedCarrier(carrierTemp)
    }
  }

  const onAddUser = () => {
    if (!carrier || !groupData?.group?.id || !canUpdateCarrier(carrier)) return
    navigation.navigate('CarrierAddUser', {
      groupId: groupData?.group.id,
      carrierId: carrier.id,
      carrierLabel: carrier.name,
    })
  }

  const onUpdateUser = (userId?: string) => {
    if (!carrier || !groupData?.group?.id || !userId || !canUpdateCarrier(carrier)) return
    navigation.navigate('CarrierUpdateUser', { groupId: groupData?.group.id, userId: userId })
  }

  const canUpdateCarrier = (carrier: Carrier | undefined) => {
    return carrier?.id === getAccessInfos().carrierId
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle
            title={`Modifier un ${FormatUtils.getCarrierLabel(
              GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS,
            )}`}
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <ScrollableFormWrapper>
            <Typography.Body colorName="color-grey">Utilisateurs</Typography.Body>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />

            <Row>
              <Col xs={7}>
                {(carrier?.userCarriers || []).map((userCarrier) => (
                  <TouchableOpacity onPress={() => onUpdateUser(userCarrier?.user?.id)}>
                    <View style={{ flexDirection: 'row' }}>
                      <Typography.Body bold>{userCarrier.user?.email}</Typography.Body>
                      <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
                      {canUpdateCarrier(carrier) && (
                        <Icon
                          type={Icons.Ionicons}
                          name="pencil-outline"
                          color={theme.colors['color-primary']}
                          size={theme.spacingUnit * 1.5}
                        />
                      )}
                    </View>

                    <Typography.BodySmall colorName="text-dark-3">
                      Dernière connexion :{' '}
                      {FormatUtils.formatDate(userCarrier.user?.updatedDate, 'Date')}
                    </Typography.BodySmall>
                    <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                  </TouchableOpacity>
                ))}
                {(carrier?.userCarriers || []).length < 1 && (
                  <Typography.Body colorName="color-grey">Aucun</Typography.Body>
                )}
              </Col>
              <Col xs={5}>
                <Button
                  label={'Ajouter un utilisateur'}
                  small
                  onPress={() => onAddUser()}
                  disabled={!canUpdateCarrier(carrier)}
                />
              </Col>
            </Row>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <Row>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedCarrier.name}
                  label={`Nom du ${FormatUtils.getCarrierLabel(
                    GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS,
                  )}`}
                  field="text"
                  onChangeText={(text) => setValue(text, 'name')}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedCarrier.legalName}
                  label="Nom légal"
                  field="text"
                  onChangeText={(text) => setValue(text, 'legalName')}
                  hidden={!updatedCarrier.name}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedCarrier.mail}
                  label="Email"
                  field="text"
                  onChangeText={(text) => setValue(text, 'mail')}
                  hidden={!updatedCarrier.legalName}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedCarrier.phone1}
                  label="Téléphone portable (0606...)"
                  field="text"
                  onChangeText={(text) => setValue(text, 'phone1')}
                  hidden={!updatedCarrier.legalName}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedCarrier.phone2}
                  label="Téléphone Fixe (0505...)"
                  field="text"
                  onChangeText={(text) => setValue(text, 'phone2')}
                  hidden={!updatedCarrier.legalName}
                />
              </Col>
            </Row>

            <AddressForm
              onChangeAddress={onChangeAddress}
              address={updatedCarrier.address}
              carrier={carrierGroup?.carrier}
              canEdit={canUpdateCarrier(carrier)}
            />

            {!updatedCarrier.address && (
              <>
                <Row>
                  <Col xs={12} sm={12} md={6}>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <TextInput
                      value={updatedCarrier.addressLine1}
                      label="Adresse (n°, rue)"
                      field="text"
                      onChangeText={(text) => setValue(text, 'addressLine1')}
                      hidden={!updatedCarrier.legalName}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <TextInput
                      value={updatedCarrier.addressLine2}
                      label="Complément d'adresse"
                      field="text"
                      onChangeText={(text) => setValue(text, 'addressLine2')}
                      hidden={!updatedCarrier.legalName}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} sm={12} md={6}>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <TextInput
                      value={updatedCarrier.postalCode}
                      label="Code postale"
                      field="text"
                      onChangeText={(text) => setValue(text, 'postalCode')}
                      hidden={!updatedCarrier.legalName}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <TextInput
                      value={updatedCarrier.city}
                      label="Ville"
                      field="text"
                      onChangeText={(text) => setValue(text, 'city')}
                      hidden={!updatedCarrier.legalName}
                    />
                  </Col>
                </Row>
              </>
            )}
            {/* <SectionTitle title="Informations pour les bons de livraison" />
            <Row>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedCarrier.account?.legalInfo.siret}
                  label="Siret"
                  field="text"
                  onChangeText={(text) =>
                    setAccountValue(
                      { ...updatedCarrier.account?.legalInfo, siret: text },
                      'legalInfo',
                    )
                  }
                  hidden={!updatedCarrier.legalName}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedCarrier.account?.legalInfo.apeCode}
                  label="Code APE"
                  field="text"
                  onChangeText={(text) =>
                    setAccountValue(
                      { ...updatedCarrier.account?.legalInfo, apeCode: text },
                      'legalInfo',
                    )
                  }
                  hidden={!updatedCarrier.legalName}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={updatedCarrier.account?.legalInfo.vatNumber}
                  label="Numéro de TVA"
                  field="text"
                  onChangeText={(text) =>
                    setAccountValue(
                      { ...updatedCarrier.account?.legalInfo, vatNumber: text },
                      'legalInfo',
                    )
                  }
                  hidden={!updatedCarrier.legalName}
                />
              </Col>
            </Row>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} /> */}
          </ScrollableFormWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <StyledButtonWrapper>
            {errorMessage ? (
              <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
            ) : null}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            {updatedCarrier.legalName && (
              <Button
                label={FormatUtils.capitalize(i18n.t('save'))}
                onPress={() => onUpdateCarrier()}
                loading={isLoading}
                disabled={!canUpdateCarrier(carrier)}
                hasDoubleValidation
                confirmationLabel={`Êtes-vous sûr de vouloir modifier ce ${FormatUtils.getCarrierLabel(
                  GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS,
                )} ?`}
              />
            )}
          </StyledButtonWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierUpdateCarrierScreen
