import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { ScrollView } from 'react-native-gesture-handler'
import { useTheme } from 'styled-components/native'
import { Button, PageTitle, Spacer, Typography } from '../../../components'
import { CartBatch, DeliveryNote, DeliveryNoteStatement } from '../../../domain'
import i18n from '../../../i18n'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import {
  StyledButtonWrapper,
  ToursContentWrapper,
} from './CarrierTourDeliveryNoteStatements.styles'
import { useQuery } from '@tanstack/react-query'
import TourCarrierService from '../../../services/carrier/tour'
import DeliveryNoteCarrierService from '../../../services/carrier/deliveryNote'
import Loader from '../../../components/Loader'
import TourDeliveryNoteCard from '../../../modules/DeliveryNote/TourDeliveryNoteCard'
import { StackNavigationProp } from '@react-navigation/stack'
import CardSettings from '../../../components/CardSettings'
import { TutorialStepData } from '../../../../enums/tutorialStep'
import DeliveryNoteStatementService from '../../../services/delivery-note-statement'
import useFilterParams from '../../../utilities/hook/useFilterParams'
import CardListPaginated from '../../../components/CardListPaginated'
import { usePagination } from '../../../utilities/hook/usePagination'
import AccountingDocumentCard from '../../../components/AccountingDocumentCard'

const CarrierTourDeliveryNoteStatementsScreen = () => {
  const theme = useTheme()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierTourDeliveryNoteStatements'>>()
  const [isLoadingGenerate, setIsLoadingGenerate] = useState(false)
  const [deliveryNotesDocumentRefreshs, setDeliveryNotesDocumentRefreshs] = useState<number>(0)

  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()

  const { id } = route?.params ?? { id: '', type: '' }

  const { data: tour, isInitialLoading: tourLoading } = TourCarrierService.getOne.query(id)

  const { filters, setPage, setDates, getDates, setPageSize, getDefaultDates } = useFilterParams()

  const { query: deliveryNoteStatementQuery } =
    DeliveryNoteStatementService.useFindDeliveryNoteStatements({
      tourId: id,
      limit: filters.pageSize,
      pageNumber: filters.page,
    })

  const {
    data: deliveryNoteStatementsData,
    refetch: refetchDeliveryNoteStatements,
    isLoading: deliveryNoteStatementsLoading,
  } = deliveryNoteStatementQuery()

  const getTourIdFormatted = () => {
    if (tour && tour.id && tour.id.length > 5) {
      return tour.id.slice(-5)
    }
    return 'NA'
  }

  const onClickGenerateDeliveryNoteStatements = async () => {
    if (!tour?.id) return
    setIsLoadingGenerate(true)

    await DeliveryNoteStatementService.generateForTour(tour.id)

    setTimeout(async () => {
      await refetchDeliveryNoteStatements()

      setIsLoadingGenerate(false)
    }, 6000)
  }

  if (tourLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!tour) return null

  const producersTour = tour.producerTour || []
  const carts = tour.carts || []

  const nbPossibleDeliveryNotesStatement = () => {
    if (tour && tour.nbOrders) {
      return tour.nbOrders
    }
    return 0
  }

  const onDeleteAll = async () => {
    if (!deliveryNoteStatementsData?.data) return
    setIsLoadingGenerate(true)

    // TODO
    await DeliveryNoteStatementService.deleteForTour(tour.id)

    await refetchDeliveryNoteStatements()

    setIsLoadingGenerate(false)
  }

  const isAllDeliveryNotesExist = () => {
    if (!deliveryNoteStatementsData?.data) return false

    return deliveryNoteStatementsData.data.every(
      (deliveryNoteStatement) => deliveryNoteStatement.documentId,
    )
  }

  const inLoadingDnMessage = () => {
    if (!isAllDeliveryNotesExist()) {
      return 'Tous les BLs doivent être générés avant de pouvoir envoyer les mails.'
    }
  }

  const onClickDeliveryNoteStatement = (deliveryNoteStatement: DeliveryNoteStatement) => {
    /*
    if (!deliveryNote.id) return
    navigation.navigate('CarrierTourDeliveryNote', {
      deliveryNoteId: deliveryNote.id,
    })
      */
  }

  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const deliveryNoteStatementList = deliveryNoteStatementsData?.data || []

  const pagination = usePagination({
    totalItems: deliveryNoteStatementsData?.pagination?.totalCount,
  })

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle
            title={`Bons de commande BL tournée #${getTourIdFormatted()}`}
            buttonRight={
              <>
                <Typography.BodySmall>
                  {FormatUtils.capitalize(FormatUtils.formatDate(tour.start, 'FullDate'))}
                  {'\n'}
                  {FormatUtils.capitalize(
                    FormatUtils.formatDate(tour.start, 'StartTime+EndTime', tour.end),
                  )}
                </Typography.BodySmall>
              </>
            }
          />

          {errorMessage ? (
            <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
          ) : null}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />

          <CardListPaginated
            data={deliveryNoteStatementList}
            pagination={{
              page: pagination.page,
              limit: pagination.pageSize,
              totalCount: pagination.totalCount,
              totalPages: pagination.totalPages,
            }}
            element={(deliveryNoteStatement: DeliveryNoteStatement) => (
              <AccountingDocumentCard
                key={deliveryNoteStatement.id}
                item={deliveryNoteStatement}
                isDownloading={false}
                displayRecipient
                onClick={() =>
                  navigation.navigate('CarrierDeliveryNoteStatement', {
                    deliveryNoteStatementId: deliveryNoteStatement.id,
                  })
                }
                type="delivery-note-statement"
              />
            )}
            emptyMessage="Aucun bon de commande n'a été créé"
            isLoading={deliveryNoteStatementsLoading}
            onChangePage={setPage}
          />

          {(pagination.totalCount || 0) !== nbPossibleDeliveryNotesStatement() && (
            <StyledButtonWrapper>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
              <Button
                label={`Générer les bons manquants (${
                  nbPossibleDeliveryNotesStatement() - (pagination.totalCount || 0)
                })`}
                onPress={() => onClickGenerateDeliveryNoteStatements()}
                loading={isLoadingGenerate}
                disabled={deliveryNoteStatementsLoading}
              />
            </StyledButtonWrapper>
          )}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={4} />

          {(deliveryNoteStatementList || []).length > 0 && (
            <CardSettings
              title="Supprimer tous les bons"
              description={`Si vous avez effectué plusieurs changements.`}
              children={
                <Button
                  label={'Supprimer'}
                  fullWidth={true}
                  small
                  onPress={() => onDeleteAll()}
                  loading={isLoadingGenerate}
                  colorName="color-grey"
                  hasDoubleValidation
                  confirmationLabel="Êtes-vous sûr de vouloir supprimer tous les Bons ?"
                />
              }
            />
          )}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierTourDeliveryNoteStatementsScreen
