import { useQuery } from '@tanstack/react-query'
import { InvoiceStatusEnum } from '../../../enums/invoice'
import { DeliveryNoteStatement, Pagination } from '../../domain'
import { InvoiceStatement } from '../../domain/InvoiceStatement'
import ApiService from '../api.service'
import { queryClient } from '../../utilities/queryClient'
import FormatUtils from '../../utilities/utils/format'

interface FindParams {
  recipientId?: string
  tourId?: string
  orderId?: string
  withoutDocument?: boolean
  start?: Date
  end?: Date
  limit?: number
  pageNumber?: number
}

const find = async ({
  orderId,
  tourId,
  start,
  end,
  limit,
  pageNumber,
  recipientId,
}: FindParams): Promise<{ data: DeliveryNoteStatement[]; pagination?: Pagination }> => {
  const response = await ApiService.get(`/delivery-note-statement`, {
    orderId,
    tourId,
    recipientId,
    after: start?.toISOString(),
    before: end?.toISOString(),
    limit,
    page: pageNumber,
  }).then((result) => result)
  return response
}

const findFromOrder = async ({
  orderId,
}: FindParams): Promise<{ data: DeliveryNoteStatement[]; pagination?: Pagination }> => {
  const response = await ApiService.get(`/delivery-note-statement/order/${orderId}`, {}).then(
    (result) => result,
  )
  return response
}

const getOne = async (
  deliveryNoteStatementId: string,
  detailled?: boolean,
): Promise<DeliveryNoteStatement> => {
  return await ApiService.get(`/delivery-note-statement/${deliveryNoteStatementId}`, {
    detailled: detailled,
  })
}

const startPdfGeneration = async (deliveryNoteStatementId: string): Promise<any> => {
  return await ApiService.get(`/delivery-note-statement/${deliveryNoteStatementId}/pdf`)
}

const sendDeliveryNoteStatementEmail = async (
  deliveryNoteStatementId: string,
  mails?: string[],
): Promise<DeliveryNoteStatement> => {
  const response = await ApiService.post(
    `/delivery-note-statement/${deliveryNoteStatementId}/send-mail`,
    { mails },
  )
  return response
}

const generateForTour = async (tourId: string): Promise<DeliveryNoteStatement> => {
  const response = await ApiService.post(`/delivery-note-statement/generate/tour/${tourId}`, {})
  return response
}

const deleteForTour = async (tourId: string): Promise<DeliveryNoteStatement> => {
  const response = await ApiService.remove(`/delivery-note-statement/tour/${tourId}`)
  return response
}

// ============================ custom hook ============================

export type UseFindDeliveryNoteStatementsArgs = FindParams

function useFindDeliveryNoteStatements(args: UseFindDeliveryNoteStatementsArgs) {
  function getQueryKey() {
    return [
      'delivery-note-statement',
      args.recipientId ? `recipient_${args.recipientId}` : '',
      args.tourId ? `status_${args.tourId}` : '',
      args.orderId ? `type_${args.orderId}` : '',
      args.start ? `start_${args.start.toISOString()}` : '',
      args.end ? `end_${args.end.toISOString()}` : '',
      args.limit ? `limit_${args.limit}` : '',
      args.pageNumber ? `pageNumber_${args.pageNumber}` : '',
    ].filter(Boolean)
  }

  function query() {
    return useQuery<{ data: DeliveryNoteStatement[]; pagination?: Pagination }, Error>({
      queryKey: getQueryKey(),
      queryFn: () => find(args),
      select: (data): { data: DeliveryNoteStatement[]; pagination?: Pagination } => ({
        pagination: data.pagination,
        data: data.data.map((statement) => ({
          ...statement,
          deliveryDate: new Date(FormatUtils.removeUTCFromDate(statement.deliveryDate)),
          createdDate: new Date(FormatUtils.removeUTCFromDate(statement.createdDate)),
        })),
      }),
    })
  }

  function refetch() {
    queryClient.refetchQueries(getQueryKey())
  }

  return {
    query,
    refetch,
  }
}

// ============================ useGetOneDeliveryNoteStatement hook ============================

export type UseGetOneDeliveryNoteStatementArgs = {
  deliveryNoteStatementId: string
}

export interface DeliveryNoteStatementExtended extends DeliveryNoteStatement {
  priceItems: {
    totalHT: number
    totalVAT: number
    totalTTC?: number
  }[]
}

function useGetOneDeliveryNoteStatement(args: UseGetOneDeliveryNoteStatementArgs) {
  function getQueryKey() {
    return ['sf_billing_delivery_note_statement', args.deliveryNoteStatementId].filter(Boolean)
  }

  function query() {
    return useQuery<DeliveryNoteStatement, Error, DeliveryNoteStatementExtended>({
      queryKey: getQueryKey(),
      queryFn: () => getOne(args.deliveryNoteStatementId),
      select: (deliveryNoteStatement): DeliveryNoteStatementExtended => ({
        ...deliveryNoteStatement,
        priceItems: [
          {
            totalHT: deliveryNoteStatement.totalAmounts?.totalHT || 0,
            totalVAT: deliveryNoteStatement.totalAmounts?.totalVAT || 0,
            totalTTC: deliveryNoteStatement.totalAmounts?.totalTTC || 0,
          },
        ],
      }),
    })
  }

  function invalidate() {
    queryClient.invalidateQueries(getQueryKey())
  }

  return {
    getQueryKey,
    query,
    invalidate,
  }
}

export type PdfStatus = {
  documentId: string
}

async function getPdfStatus(jobId: string) {
  return await ApiService.getResponse<PdfStatus>(`/delivery-note-statement/pdf/status/${jobId}`)
}

const getOneDownload = async (invoiceStatementId: string): Promise<any> => {
  return await ApiService.get(`/delivery-note-statement/${invoiceStatementId}/download`)
}

const DeliveryNoteStatementService = {
  useFindDeliveryNoteStatements,
  findFromOrder,
  useGetOneDeliveryNoteStatement,
  sendDeliveryNoteStatementEmail,
  startPdfGeneration,
  generateForTour,
  deleteForTour,
  getPdfStatus,
  getOneDownload,
}

export default DeliveryNoteStatementService
