import React from 'react'
import { useTheme } from 'styled-components/native'
import { StyledTopBox } from './CarrierPriceReportingTopBox.styles'
import { CarrierPriceReportingTopBoxProps } from './CarrierPriceReportingTopBox.model'
import { Card, CardParam, Icon, Icons, Typography } from '../../../../../components'
import FormatUtils from '../../../../../utilities/utils/format'
import i18n from '../../../../../i18n'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { CarrierStackParamList } from '../../../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import useAuthenticationContext from '../../../../../utilities/hook/useAuthenticationContext'
import { RightKeyEnum } from '../../../../../../enums'

function CarrierPriceReportingTopBox({ priceReporting }: CarrierPriceReportingTopBoxProps) {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const { carrierHasRight } = useAuthenticationContext()

  const onClickEditPriceReporting = () => {
    navigation.navigate('CarrierUpdatePriceReporting', { id: priceReporting.id })
  }

  const hasUpdateRight = carrierHasRight(RightKeyEnum.C_TOUR_UPDATE)

  const getCitiesList = () => {
    let citiesList = ''
    priceReporting.priceReportingCities?.map((c, index) => {
      citiesList = citiesList + `${index === 0 ? '' : ', '}${c.city.label}`
    })

    return citiesList
  }

  return (
    <>
      <StyledTopBox>
        <CardParam
          label={'libellé'}
          value={priceReporting.label}
          flex={7}
          isStart
          onClick={hasUpdateRight ? () => onClickEditPriceReporting() : null}
        />
        <CardParam
          label={'Ville ciblé'}
          value={getCitiesList()}
          onClick={hasUpdateRight ? () => onClickEditPriceReporting() : null}
          flex={5}
          isEnd
        />
      </StyledTopBox>
      <StyledTopBox>
        <CardParam
          label={'Début de validité'}
          value={FormatUtils.formatDate(priceReporting.validityStart, 'FullDate+StartTime')}
          onClick={hasUpdateRight ? () => onClickEditPriceReporting() : null}
          flex={4}
          isStart
        />
        <CardParam
          label={'Fin de validité'}
          value={FormatUtils.capitalize(
            FormatUtils.formatDate(priceReporting.validityEnd, 'FullDate+StartTime'),
          )}
          onClick={hasUpdateRight ? () => onClickEditPriceReporting() : null}
          flex={4}
          isEnd
        />
        <CardParam
          label={'Com. log. cible'}
          value={
            priceReporting.carrierFees
              ? FormatUtils.formatPercentage(priceReporting.carrierFees / 100)
              : '-'
          }
          onClick={hasUpdateRight ? () => onClickEditPriceReporting() : null}
          flex={4}
          isEnd
        />
      </StyledTopBox>
    </>
  )
}

export default CarrierPriceReportingTopBox
