import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { ScrollView } from 'react-native'
import { PageTitle, Spacer, Typography } from '../../../components'
import { Producer } from '../../../domain'
import { ClientStackParamList } from '../../../navigation/ClientNavigationStack/ClientNavigationStack.model'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import ProducerCard from './ProducerCard'
import ProducerClientService from '../../../services/client/producer'

const ClientProducersScreen = () => {
  const navigation = useNavigation<StackNavigationProp<ClientStackParamList>>()
  const { getAccessInfos } = useAuthenticationContext()

  const {
    data: producers,
    refetch: refetchProducersPrevious,
    isInitialLoading: producersLoading,
  } = useQuery<Producer[], Error>(
    ['c_producers', getAccessInfos().clientId],
    () => ProducerClientService.getAllProducers(getAccessInfos().clientId),
    {
      keepPreviousData: true,
    },
  )
  const OnClickProducer = (producer: Producer) => {
    navigation.navigate('ClientProducer', { id: producer.id })
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle title="Producteurs" />
        <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
        <Typography.BodySmall colorName="text-dark-3">
          Vous pouvez consulter les informations et certificats des producteurs à qui vous avez déjà
          passé une commande.
        </Typography.BodySmall>
        {producers &&
          producers.map((producer) => {
            return <ProducerCard key={producer.id} producer={producer} onClick={OnClickProducer} />
          })}
        {!producers ||
          (producers?.length < 1 && (
            <>
              <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
              <Typography.BodySmall>
                Vous n'avez pas encore passé de commande chez un producteur.
              </Typography.BodySmall>
            </>
          ))}
        <Spacer size={5} axis={Spacer.AxisEnum.VERTICAL} />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ClientProducersScreen
