import React from 'react'
import { View } from 'react-native'
import { useTheme } from 'styled-components/native'
import { Icon, Icons, Spacer, TouchableCard, Typography } from '../../../components'
import i18n from '../../../i18n'
import FormatUtils from '../../../utilities/utils/format'
import { ProducerCardProps } from './ProducerCard.model'
import {
  StyledCardContent,
  StyledCardWrapper,
  StyledLeftBox,
  StyledRightBox,
} from './ProducerCard.styles'
import { ProducerBillingTypeEnum } from '../../../../enums'
import { CarrierGroup, User, UserCarrier } from '../../../domain'
import { LABELS_TYPES } from '../../../../enums/label'
import BadgeStatus from '../../../components/BadgeStatus'
import { ProducerStatusEnum } from '../../../../enums/producer'

const ProducerCard = ({ group, producerGroup, onClick }: ProducerCardProps) => {
  const theme = useTheme()

  if (!producerGroup.producer) return <></>

  const OnClickProducer = () => {
    if (onClick) {
      onClick(producerGroup.producer!)
    }
  }

  const getLastUpdateUser = () => {
    const userProducers = producerGroup.producer?.userProducers || []

    const users: User[] = []
    userProducers.map((up) => {
      if (up.user) {
        users.push(up.user)
      }
    })

    if (users && users.length > 0 && users[0]) {
      users.sort((a, b) => (new Date(a.updatedDate || '') > new Date(b.updatedDate || '') ? -1 : 1))
      return users[0].updatedDate
    }
    return undefined
  }

  return (
    <StyledCardWrapper onPress={OnClickProducer} activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent>
          <StyledLeftBox>
            <Typography.CardTitle ellipsis>{producerGroup.producer.label}</Typography.CardTitle>
            <Typography.BodySmall colorName="color-primary" bold>
              {producerGroup.producer.owners}{' '}
              {producerGroup.producer.labels?.map(
                (label) =>
                  `- ${LABELS_TYPES.find((labelType) => labelType.value === label)?.label} `,
              )}
            </Typography.BodySmall>
            <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.BodySmall colorName="text-dark-3">
              {producerGroup.producer.location && producerGroup.producer.location} -{' '}
              {producerGroup.producer.userProducers?.length || 0} utilisateurs
            </Typography.BodySmall>
            <Typography.BodySmall colorName="text-dark-3">
              BL et facturation{' '}
              {producerGroup?.billingType === ProducerBillingTypeEnum.CARRIER
                ? 'via Super-producteur'
                : `direct ${FormatUtils.capitalize(FormatUtils.getLabelFromClientType(1))}`}
            </Typography.BodySmall>
            {producerGroup.additionalCarrierFees ? (
              <Typography.BodySmall colorName="text-dark-3">
                Commission Logistique Supplémentaire :{' '}
                {FormatUtils.formatPercentage(producerGroup.additionalCarrierFees / 100)}
              </Typography.BodySmall>
            ) : undefined}
            {producerGroup.gainRateForCarrier ? (
              <Typography.BodySmall colorName="text-dark-3">
                Repartition du gain pour le gestionnaire :{' '}
                {FormatUtils.formatPercentage(producerGroup.gainRateForCarrier / 100)}
              </Typography.BodySmall>
            ) : undefined}
            {producerGroup.archived ? (
              <Typography.BodySmall colorName="color-danger">
                Compte archivé depuis le {FormatUtils.formatDate(producerGroup.updatedDate, 'Date')}
              </Typography.BodySmall>
            ) : undefined}
          </StyledLeftBox>
          <StyledRightBox>
            <View style={{ flexDirection: 'row' }}>
              {producerGroup.producer.status !== undefined &&
              producerGroup.producer.status < ProducerStatusEnum.ONBOARDING ? (
                <>
                  <BadgeStatus
                    label="En cours d'inscription"
                    color="text-light-1"
                    backgroundColor="text-dark-3"
                  />
                </>
              ) : null}
              <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
              <Icon
                type={Icons.Ionicons}
                name="chevron-forward-outline"
                size={theme.spacingUnit * 2}
                color={theme.colors['text-dark-3']}
              />
            </View>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <View style={{ alignItems: 'flex-end' }}>
              <Typography.BodySmall colorName="text-dark-3" style={{ alignSelf: 'flex-end' }}>
                Créé le
              </Typography.BodySmall>
              <Typography.BodySmall>
                {FormatUtils.formatDate(producerGroup.producer.createdDate, 'Date')}
              </Typography.BodySmall>
              <Spacer size={0.2} axis={Spacer.AxisEnum.VERTICAL} />
              <Typography.BodySmall colorName="text-dark-3" style={{ alignSelf: 'flex-end' }}>
                Dernière connexion
              </Typography.BodySmall>
              <Typography.BodySmall>
                {FormatUtils.formatDate(getLastUpdateUser(), 'Date')}
              </Typography.BodySmall>
            </View>
          </StyledRightBox>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default ProducerCard
