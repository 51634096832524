import React, { useEffect, useState } from 'react'

import PageTitle from '../../../components/PageTitle'

import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import { SectionTitle, Spacer } from '../../../components'
import { useQuery } from '@tanstack/react-query'
import MetricsAdminService, { AdminDashboardMetrics } from '../../../services/admin/metrics'
import FormatUtils from '../../../utilities/utils/format'
import { InterfaceColors } from '../../../utilities/styling/theme/theme'
import DashboardStats from '../../../components/DashboardStats'

const currentMonth = new Date().getMonth()
const currentYear = new Date().getFullYear()
const defaultDateHour = new Date(currentYear, currentMonth, 1, 0, 0, 0)
const defaultDateHour2 = new Date(currentYear, currentMonth + 1, 1, 0, 0, 0)

const AdminDashboardScreen = () => {
  const [metricsFilters, setMetricsFilters] = useState({
    start: new Date(defaultDateHour),
    end: new Date(defaultDateHour2),
  })

  const {
    data: metrics,
    refetch: refetchMetrics,
    isInitialLoading: metricsLoading,
  } = useQuery<AdminDashboardMetrics, Error>(
    ['group_metrics_dashboard_admin'],
    () => MetricsAdminService.getDashboardMetrics(metricsFilters.start, metricsFilters.end),
    {
      keepPreviousData: true,
    },
  )

  useEffect(() => {
    refetchMetrics()
  }, [metricsFilters])

  const onClickMetricsChangeDate = async (metricsFilters: { start: Date; end: Date }) => {
    let currentMonthNew = metricsFilters.start.getMonth()
    let currentYear = metricsFilters.start.getFullYear()

    if (currentMonthNew < 0) {
      currentMonthNew = 11
      currentYear = currentYear - 1
    }

    if (currentMonthNew > 11) {
      currentMonthNew = 0
      currentYear = currentYear + 1
    }

    const newStartDate = new Date(currentYear, currentMonthNew, 1, 0, 0, 0)

    const newEndDate = new Date(currentYear, currentMonthNew + 1, 1, 0, 0, 0)

    const tempMetricsFilter = { start: newStartDate, end: newEndDate }

    await setMetricsFilters(tempMetricsFilter)
  }

  const selectedMetrics = [
    {
      label: 'GMV HT',
      value: metrics?.totalHTProducerPrice
        ? `${FormatUtils.formatPrice(metrics?.totalHTProducerPrice)}`
        : '-',
    },
    {
      label: 'Ventes HT',
      value: metrics?.totalHT ? `${FormatUtils.formatPrice(metrics?.totalHT)}` : '-',
    },
    { label: 'Commandes', value: metrics?.nbOrders ? metrics?.nbOrders.toString() : '-' },
    { label: 'Tournées', value: metrics?.nbTours ? metrics?.nbTours.toString() : '-' },
    {
      label: 'Producteurs',
      value: metrics?.nbActiveProducers ? metrics?.nbActiveProducers.toString() : '-',
    },
    {
      label: 'Clients',
      value: metrics?.nbActiveClients ? metrics?.nbActiveClients.toString() : '-',
    },
    {
      label: 'Cmd/Client',
      value:
        metrics?.nbActiveClients && metrics?.nbActiveClients > 0 && metrics?.nbOrders
          ? ((metrics?.nbOrders || 0) / metrics?.nbActiveClients)
              .toFixed(2)
              .replace('.', ',')
              .toString()
          : '-',
    },
    {
      label: 'Com. log. Moy.',
      value: metrics?.averageCarrierFees
        ? `${FormatUtils.formatDouble(metrics?.averageCarrierFees)}%`
        : '-',
      badgeColor: metrics?.averageCarrierFees
        ? ('color-success' as keyof InterfaceColors)
        : undefined,
    },
    {
      label: 'Com. Plat. Moy.',
      value: metrics?.averagePlatformFees
        ? `${FormatUtils.formatDouble(metrics?.averagePlatformFees)}%`
        : '-',
      badgeColor: metrics?.averagePlatformFees
        ? ('color-success' as keyof InterfaceColors)
        : undefined,
    },
  ]

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle title="Dashboard Admin" />
        <DashboardStats
          metrics={selectedMetrics}
          metricsFilters={metricsFilters}
          metricsType="MONTH"
          onChangeDates={onClickMetricsChangeDate}
          isLoading={metricsLoading}
        />
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default AdminDashboardScreen
