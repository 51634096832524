import * as React from 'react'

import { DefaultTheme, NavigationContainer, NavigationState } from '@react-navigation/native'
import { useCallback, useState, useEffect } from 'react'
import { InteractionManager } from 'react-native'
import { useTheme } from 'styled-components/native'
import { DeepLinkEnum } from '../utilities/context/deepLinkContext'
import { checkDeepLinkResult, linking } from '../utilities/helpers/deeplinks'
import useDeepLinks from '../utilities/hook/useDeepLinks'
import useURL from '../utilities/hook/useURL'
import { RoutesProps } from './Navigation.model'
import { navigationRef } from './RootNavigation'
import AuthenticationNavigationStack from './AuthenticationNavigationStack'
import useAuthenticationContext from '../utilities/hook/useAuthenticationContext'
import VersionController from '../libs/versionController/VersionController'
import ProducerNavigationStack from './ProducerNavigationStack/ProducerNavigationStack'
import CarrierNavigationStack from './CarrierAppNavigationStack/CarrierNavigationStack'
import ClientNavigationStack from './ClientNavigationStack/ClientNavigationStack'
import StorageUtil from '../utilities/storage/storage'
import SplashScreen from '../screens/Common/SplashScreen'
import PublicNavigationStack from './PublicNavigationStack'
import AdminNavigationStack from './AdminNavigationStack/AdminNavigationStack'

const Routes = ({ onNavigationReady }: RoutesProps) => {
  const theme = useTheme()
  // TODO useAuthenticationContext()
  const {
    isUserAuthenticated,
    isModeAdminPlatform,
    logout,
    authenticationContextLoading,
    authenticationLoading,
    viewType,
    loadStoredSession,
  } = useAuthenticationContext()
  const [navigationState, setNavigationState] = useState<NavigationState | null>(null)
  const [url, setUrl] = useState<string | null>(null)

  const { addDeepLink } = useDeepLinks()

  const onStateChange = (newNavigationState: NavigationState | undefined) => {
    if (newNavigationState) {
      setNavigationState(newNavigationState)
    }
  }

  useEffect(() => {
    async function check() {
      const authDataLocalStorage = await StorageUtil.getItem('authenticationData')
      if (!authDataLocalStorage && isUserAuthenticated) {
        logout()
      }
    }
    check()
  })

  const handleDeepLink = useCallback(
    async (url: string) => {
      const { didDeepLinkLand, action, linkPath } = await checkDeepLinkResult(url, logout)

      const task = InteractionManager.runAfterInteractions(async () => {
        if (!didDeepLinkLand && action && linkPath) {
          addDeepLink({
            id: linkPath,
            type: DeepLinkEnum.NAVIGATION,
            action: () => navigationRef.current?.dispatch(action),
          })
        }
      })
      setUrl(url)

      return () => task.cancel()
    },
    [addDeepLink, navigationRef.current],
  )

  const onReady = useCallback(() => {
    onNavigationReady()
  }, [onNavigationReady])

  useURL(({ url }) => handleDeepLink(url))

  useEffect(() => {
    // intiial load

    if (url) {
      loadStoredSession()
    }
  }, [url])

  if (authenticationContextLoading) {
    return <SplashScreen />
  }

  const NavigationTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      background: theme.colors['background-1'],
    },
  }

  const renderNavigationOnType = () => {
    if (authenticationContextLoading || authenticationLoading) {
      return <SplashScreen />
    }

    if (!isUserAuthenticated) {
      return <PublicNavigationStack />
    }
    if (isModeAdminPlatform() && viewType === 10) {
      return <AdminNavigationStack />
    }
    if (viewType === 1) {
      return <CarrierNavigationStack />
    }
    if (viewType === 2) {
      return <ProducerNavigationStack />
    }
    if (viewType === 3) {
      return <ClientNavigationStack />
    }
    return <PublicNavigationStack />
  }

  return (
    <NavigationContainer
      ref={navigationRef}
      onStateChange={onStateChange}
      onReady={onReady}
      linking={linking}
      theme={NavigationTheme}
      documentTitle={{
        formatter: (options, route) => `Harvy ${options?.title ? `- ${options?.title}` : ''}`,
      }}
    >
      {renderNavigationOnType()}
      <VersionController navigationState={navigationState} navigationRef={navigationRef} />
    </NavigationContainer>
  )
}
// {isUserAuthenticated ? renderNavigationOnType() : <AuthenticationNavigationStack />}

// {isUserAuthenticated || true ? <AppNavigationStack /> : <AppNavigationStack />}
//       <DrawerCarrier />

export default Routes
