import { DeliveryNoteTypeEnum } from '../../../enums'
import { DeliveryNote, DeliveryNotesCartBatchs } from '../../domain'
import GeneralUtils from './general'

type DeliveryNoteInfo = {
  profileType: string
  name: string
}

const getDeliveryPartyInfo = (
  deliveryNote: DeliveryNote,
): { issuer: DeliveryNoteInfo; recipient: DeliveryNoteInfo } => {
  let issuer: DeliveryNoteInfo = { profileType: '', name: '' }
  let recipient: DeliveryNoteInfo = { profileType: '', name: '' }

  if (
    deliveryNote.issuer &&
    deliveryNote.recipient &&
    deliveryNote.type === DeliveryNoteTypeEnum.PRODUCER_CLIENT
  ) {
    issuer = { profileType: 'Producteur', name: deliveryNote.issuer.legalName }
    recipient = { profileType: 'Client', name: deliveryNote.recipient.legalName }
    return { issuer, recipient }
  }

  switch (deliveryNote.type) {
    case DeliveryNoteTypeEnum.PRODUCER_CARRIER:
      issuer = { profileType: 'Producteur', name: deliveryNote.producer?.label || '' }
      recipient = { profileType: 'Super-producteur', name: deliveryNote.carrier?.name || '' }
      break
    case DeliveryNoteTypeEnum.PRODUCER_CLIENT:
      issuer = { profileType: 'Producteur', name: deliveryNote.producer?.label || '' }
      recipient = { profileType: 'Client', name: deliveryNote.order?.cart?.client?.label || '' }
      break
    case DeliveryNoteTypeEnum.CARRIER_CLIENT:
      issuer = { profileType: 'Super-producteur', name: deliveryNote.carrier?.name || '' }
      recipient = { profileType: 'Client', name: deliveryNote.order?.cart?.client?.label || '' }
      break
  }

  return { issuer, recipient }
}

const downloadCSV = (data: string, filename: string) => {
  const blob = new Blob([data], { type: 'text/csv' })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  link?.parentNode?.removeChild(link)
}

export const isDateIntervalValid = (start: Date, end: Date) => {
  return GeneralUtils.diffDays(start, end) <= 45
}

const DeliveryNoteUtils = {
  downloadCSV,
  getDeliveryPartyInfo,
}

export default DeliveryNoteUtils
