import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { ClientStackParamList } from '../../../navigation/ClientNavigationStack/ClientNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import { CardPageLink, PageTitle, SectionTitle } from '../../../components'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'

const ClientBillingScreen = () => {
  const navigation = useNavigation<StackNavigationProp<ClientStackParamList>>()
  const { getAccessInfos } = useAuthenticationContext()

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle title="Gestion financière" />
        <CardPageLink
          label={`Relevés`}
          icon="arrow-right"
          onClick={() => navigation.navigate('InvoiceStatementList')}
        />

        <SectionTitle title="Paramétrage" />
        <CardPageLink
          label="Configuration de la facturation"
          icon="arrow-right"
          onClick={() =>
            navigation.navigate('UserClientConfigurationNav', {
              screen: 'Account',
              params: {
                id: getAccessInfos().clientId,
              },
            })
          }
        />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ClientBillingScreen
