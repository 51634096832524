import { useCallback, useEffect, useState } from 'react'
import { format } from 'date-fns'
import { Button, SectionTitle, Spacer } from '../../../components'
import CardListPaginated from '../../../components/CardListPaginated'
import FilterModule from '../../../components/FilterModule'
import PageTitle from '../../../components/PageTitle'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import { InvoiceStatement } from '../../../domain/InvoiceStatement'
import InvoiceStatementService from '../../../services/invoice-statement'
import {
  Filter,
  InvoiceFilterEnum,
  SelectedValues,
} from '../../../components/FilterModule/FilterModule.model'
import { INVOICE_STATEMENT_STATUSES, InvoiceStatusEnum } from '../../../../enums/invoice'

import AccountingDocumentCard from '../../../components/AccountingDocumentCard'
import useFilterParams from '../../../utilities/hook/useFilterParams'
import { usePagination } from '../../../utilities/hook/usePagination'
import DateFilter from '../../../components/DateFilter'
import AccountService from '../../../services/billing/account'
import { StackNavigationProp } from '@react-navigation/stack'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { AdminStackParamList } from '../../../navigation/AdminNavigationStack/AdminNavigationStack.model'
import CardSettings from '../../../components/CardSettings'

const AdminBillingInvoiceStatementListScreen = () => {
  const [isDownloading, setIsDownloading] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState<SelectedValues>({})
  const [currentlyUpdatingFilter, setCurrentlyUpdatingFilter] = useState<InvoiceFilterEnum | null>(
    null,
  )
  const { filters, setPage, setDates, getDates, setPageSize, getDefaultDates } = useFilterParams()

  const { start, end } = getDates()
  const metricsFilters = { start, end }

  const { query: invoiceStatementsQuery, invalidate: invalidateInvoiceStatements } =
    InvoiceStatementService.useFindInvoiceStatementsAdmin({
      status: selectedFilters[InvoiceFilterEnum.STATUS] as InvoiceStatusEnum | undefined,
      type: undefined, // isClient ? InvoiceStatementTypeEnum.CLIENT : InvoiceStatementTypeEnum.PRODUCER,
      recipientId: selectedFilters[InvoiceFilterEnum.CLIENT] as string | undefined,
      withoutDocument: selectedFilters[InvoiceFilterEnum.NO_DOCUMENT] as boolean | undefined,
      start,
      end,
      limit: filters.pageSize,
      pageNumber: filters.page,
    })

  const {
    data: invoiceStatementData,
    refetch: refetchInvoiceStatements,
    isLoading: invoiceStatementsLoading,
  } = invoiceStatementsQuery()

  // Set the initial page size only once when component mounts
  useEffect(() => {
    setPageSize(5)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // Empty dependency array means this runs once on mount

  useFocusEffect(
    useCallback(() => {
      refetchInvoiceStatements()
    }, [refetchInvoiceStatements]),
  )

  const navigation = useNavigation<StackNavigationProp<AdminStackParamList>>()

  const invoiceStatementList = invoiceStatementData?.data || []

  const pagination = usePagination({
    totalItems: invoiceStatementData?.pagination?.totalCount,
  })

  const { query: getManyAccounts, invalidate: invalidateGetManyAccounts } =
    AccountService.useGetManyAccountsAdmin({
      unbilled: false,
      useSimplified: false,
      addUnbilledDNCount: false,
      invoicedByIssuerId: undefined,
    })

  const { data: recipients = [] } = getManyAccounts()

  const getFilters = useCallback(() => {
    const getConditionalLabel = (filterKey: InvoiceFilterEnum) => {
      if (selectedFilters[filterKey]) {
        if (filterKey === InvoiceFilterEnum.CLIENT) {
          const selectedClient = recipients.find(
            (recipient) => recipient.id === selectedFilters[filterKey],
          )
          return `Compte : ${selectedClient?.legalName || 'Sélectionné'}`
        }
        if (selectedFilters[filterKey] && filterKey === InvoiceFilterEnum.STATUS) {
          const selectedStatus = INVOICE_STATEMENT_STATUSES.find(
            (status) => status.value.toString() === selectedFilters[filterKey]?.toString(),
          )
          return `Statut : ${selectedStatus?.label || 'Sélectionné'}`
        }
        if (filterKey === InvoiceFilterEnum.NO_DOCUMENT) {
          return `Sans document`
        }
      }
      switch (filterKey) {
        case InvoiceFilterEnum.CLIENT:
          return 'par compte'
        case InvoiceFilterEnum.STATUS:
          return 'par statut'
        case InvoiceFilterEnum.NO_DOCUMENT:
          return 'par PDF'
        default:
          return 'NA'
      }
    }

    return [
      {
        key: InvoiceFilterEnum.CLIENT,
        active: !!selectedFilters[InvoiceFilterEnum.CLIENT],
        label: getConditionalLabel(InvoiceFilterEnum.CLIENT),
        options: recipients.map((recipient) => ({
          label: recipient.legalName,
          value: recipient.id,
        })),
      },
      {
        key: InvoiceFilterEnum.STATUS,
        active: !!selectedFilters[InvoiceFilterEnum.STATUS],
        label: getConditionalLabel(InvoiceFilterEnum.STATUS),
        options: INVOICE_STATEMENT_STATUSES.map((status) => ({
          label: status.label,
          value: status.value,
        })),
      },
      {
        key: InvoiceFilterEnum.NO_DOCUMENT,
        active: !!selectedFilters[InvoiceFilterEnum.NO_DOCUMENT],
        label: getConditionalLabel(InvoiceFilterEnum.NO_DOCUMENT),
        options: [
          { label: 'Tous', value: undefined },
          { label: 'Sans document', value: true },
        ],
      },
    ]
  }, [recipients, selectedFilters, INVOICE_STATEMENT_STATUSES])

  const handleFilterUpdate = async (filterKey: InvoiceFilterEnum, selectedValue?: any) => {
    if (selectedValue === undefined) {
      setCurrentlyUpdatingFilter(currentlyUpdatingFilter === filterKey ? null : filterKey)
    } else {
      setCurrentlyUpdatingFilter(null)
      setSelectedFilters((prev) => ({
        ...prev,
        [filterKey]: selectedValue,
      }))
      setPage(1)
      await refetchInvoiceStatements()
    }
  }

  const handleFilterDelete = async (filterKey: InvoiceFilterEnum) => {
    setSelectedFilters((prev) => {
      const newFilters = { ...prev }
      delete newFilters[filterKey]
      return newFilters
    })
    setCurrentlyUpdatingFilter(null)
    setPage(1)
    await refetchInvoiceStatements()
  }

  const onClickMetricsChangeDate = async (
    value?: number,
    exactDate?: any,
    paramDate?: 'start' | 'end',
  ) => {
    setDates(value, exactDate, paramDate)
    await refetchInvoiceStatements()
  }

  const formatFilterLabel = (key: InvoiceFilterEnum) => {
    switch (key) {
      case InvoiceFilterEnum.STATUS:
        return 'statut'
      case InvoiceFilterEnum.CLIENT:
        return 'client'
      default:
        return `option`
    }
  }

  const generateAllDocuments = async () => {
    if (isGenerating) return
    setIsGenerating(true)
    await InvoiceStatementService.startAllPdfGeneration({
      status: selectedFilters[InvoiceFilterEnum.STATUS] as InvoiceStatusEnum | undefined,
      type: undefined, // isClient ? InvoiceStatementTypeEnum.CLIENT : InvoiceStatementTypeEnum.PRODUCER,
      recipientId: selectedFilters[InvoiceFilterEnum.CLIENT] as string | undefined,
      withoutDocument: true,
      start,
      end,
      limit: 100,
      pageNumber: 1,
    })

    setTimeout(async () => {
      setIsGenerating(false)
      await refetchInvoiceStatements()
    }, 15000)
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle title="Relevés" />
        <DateFilter
          onClickMetricsChangeDate={onClickMetricsChangeDate}
          metricsFilters={metricsFilters}
        />
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        <FilterModule<InvoiceFilterEnum>
          filters={getFilters()}
          currentlyUpdating={currentlyUpdatingFilter}
          onFilterUpdate={handleFilterUpdate}
          onFilterDelete={handleFilterDelete}
          formatFilterLabel={formatFilterLabel}
        />
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        <CardListPaginated
          data={invoiceStatementList}
          pagination={{
            page: pagination.page,
            limit: pagination.pageSize,
            totalCount: pagination.totalCount,
            totalPages: pagination.totalPages,
          }}
          element={(invoiceStatement: InvoiceStatement) => (
            <AccountingDocumentCard
              key={invoiceStatement.id}
              item={invoiceStatement}
              isDownloading={isDownloading}
              onClick={() =>
                navigation.navigate('AdminBillingInvoiceStatement', {
                  invoiceStatementId: invoiceStatement.id,
                })
              }
              type="invoice-statement"
              isAdmin={true}
            />
          )}
          emptyMessage="Aucun relevé n'a été créé"
          isLoading={invoiceStatementsLoading || isDownloading}
          onChangePage={setPage}
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <SectionTitle title={`Actions`} />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <CardSettings
          title={`Générer les relevés sans document`}
          description={'Envoie automatique par mail de la mercuriale'}
          children={
            <Button
              fullWidth={true}
              colorName="color-grey"
              label={'Générer'}
              loading={isGenerating}
              onPress={() => generateAllDocuments()}
            />
          }
        />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default AdminBillingInvoiceStatementListScreen
