import { createStackNavigator, TransitionPresets } from '@react-navigation/stack'
import React from 'react'
import { Dimensions } from 'react-native'
import Header from '../../components/Header'
import BillingProducerNavigationStackParamList from './BillingAdminNavigation.model'
import HeaderHome from '../../components/HeaderHome'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'

import AdminBillingScreen from '../../../screens/Admin/AdminBilling'
import AdminBillingInvoiceStatementListScreen from '../../../screens/Admin/AdminBillingInvoiceStatements'
import AdminBillingInvoiceStatementScreen from '../../../screens/Admin/AdminBillingInvoiceStatement'

const Stack = createStackNavigator<BillingProducerNavigationStackParamList>()

const BillingAdminNavigation = () => {
  const { isCurrentUserRootAdmin } = useAuthenticationContext()

  return (
    <Stack.Navigator
      initialRouteName="AdminBilling"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="AdminBilling"
        component={AdminBillingScreen}
        options={{
          headerShown: true,
          header: () => <HeaderHome />,
          title: 'Admin - Gestion financière',
        }}
      />
      <Stack.Screen
        name="AdminBillingInvoiceStatementList"
        component={AdminBillingInvoiceStatementListScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" goBackFallback="AdminBilling" />,
          title: 'Admin - Relevés',
        }}
      />
      <Stack.Screen
        name="AdminBillingInvoiceStatement"
        component={AdminBillingInvoiceStatementScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" goBackFallback="AdminBillingInvoiceStatementList" />,
          title: 'Admin - Relevé',
        }}
      />
      {/*

      
      <Stack.Screen
        name="AdminBillingDeliveryNotes"
        component={AdminBillingDeliveryNotesScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="AdminBilling" />,
          title: 'Ajouter un relevé de prix',
        }}
      />
      <Stack.Screen
        name="ProducerClients"
        component={ProducerClientsScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="AdminBilling" />,
          title: '',
        }}
      />
      <Stack.Screen
        name="AdminBillingPaymentConfiguration"
        component={AdminBillingPaymentConfigurationScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="AdminBilling" />,
          title: 'Configuration des paiements',
        }}
      />
      <Stack.Screen
        name="ProducerClient"
        component={ProducerClientScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerClients" />,
          title: '',
        }}
      />
      <Stack.Screen
        name="InvoiceStatementList"
        component={InvoiceStatementListScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" goBackFallback="AdminBilling" />,
          title: 'Relevés',
        }}
      />

      <Stack.Screen
        name="EditInvoiceStatement"
        component={EditInvoiceStatementScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" goBackFallback="InvoiceStatementList" />,
          title: 'Modifier un relevé',
        }}
      />
      <Stack.Screen
        name="ProducerOrderList"
        component={ProducerOrderListScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" goBackFallback="AdminBilling" />,
          title: 'Commandes',
        }}
      />
      <Stack.Screen
        name="ProducerDirectOrder"
        component={ProducerDirectOrderScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerOrderList" />,
          title: 'Détails de la commande',
        }}
      />
      <Stack.Screen
        name="ProducerDirectCartAdd"
        component={ProducerDirectCartAddScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" goBackFallback="AdminBilling" />,
          title: 'Ajouter une commande',
        }}
      />
      <Stack.Screen
        name="ProducerDirectCartCatalog"
        component={ProducerDirectCartCatalogScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" goBackFallback="AdminBilling" />,
          title: 'Catalogue',
        }}
      />
      <Stack.Screen
        name="ProducerUpdateClient"
        component={ProducerUpdateClientScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerClient" />,
          title: 'Modifier un client',
        }}
      />
      <Stack.Screen
        name="ProducerAddClient"
        component={ProducerAddClientScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerClients" />,
          title: 'Ajouter un client',
        }}
      />

      <Stack.Screen
        name="ProducerDirectCartUpdate"
        component={ProducerDirectCartUpdateScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerDirectCartCatalog" />,
          title: 'Modifier la commande',
        }}
      />

      {isCurrentUserRootAdmin() && (
        <>
          <Stack.Screen
            name="EditInvoice"
            component={EditInvoiceScreen}
            options={{
              headerShown: true,
              header: () => <Header title="" goBackFallback="AdminBilling" />,
            }}
          />
          <Stack.Screen
            name="CreateInvoice"
            component={CreateInvoiceScreen}
            options={{
              headerShown: true,
              header: () => <Header title="" goBackFallback="AdminBilling" />,
            }}
          />
          <Stack.Screen
            name="ListInvoice"
            component={ListInvoiceScreen}
            options={{
              headerShown: true,
              header: () => <Header title="" goBackFallback="AdminBilling" />,
            }}
          />
        </>
      )}
        */}
    </Stack.Navigator>
  )
}

export default BillingAdminNavigation
