import { CommonActions, RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import {
  Button,
  Icon,
  Icons,
  PageTitle,
  SectionTitle,
  Spacer,
  Typography,
} from '../../../components'
import { PriceReporting, ProductType } from '../../../domain'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import CarrierPriceReportingTopBox from './components/CarrierPriceReportingTopBox'
import {
  StyledBackButtonWrapper,
  StyledButtonWrapper,
  StyledTouchableOpacity,
  ToursContentWrapper,
} from './CarrierPriceReporting.styles'
import { useQuery } from '@tanstack/react-query'
import { ScrollView } from 'react-native-gesture-handler'
import Loader from '../../../components/Loader'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import PriceReportingCarrierService from '../../../services/carrier/priceReporting'
import BatchCarrierService from '../../../services/carrier/batch'
import PriceStatementCard from '../../../modules/PriceReporting/PriceStatementCard'
import { queryClient } from '../../../utilities/queryClient'
import { View } from 'react-native'

const CarrierPriceReportingScreen = () => {
  const theme = useTheme()
  const { getAccessInfos } = useAuthenticationContext()

  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierPriceReporting'>>()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const { carrierHasRight } = useAuthenticationContext()
  const [productTypeInModification, setProductTypeInModification] = useState<
    ProductType | undefined
  >(undefined)
  const [modificationSaved, setModificationSaved] = useState<boolean>(false)
  const [displayHiddenStatements, setDisplayHiddenStatements] = useState<boolean>(false)

  const { id, productTypeId, batchId, tourId } = route?.params ?? { id: '' }

  const {
    data: priceReporting,
    refetch: refetchPriceReporting,
    isInitialLoading: priceReportingLoading,
  } = useQuery<PriceReporting, Error>(
    ['sf_price_reporting', id],
    () => PriceReportingCarrierService.getOne(id),
    {
      keepPreviousData: true,
      enabled: id !== '',
    },
  )

  const {
    data: productTypesToStatement,
    refetch: refetchProductTypesToStatement,
    isInitialLoading: productTypesToStatementLoading,
  } = useQuery<ProductType[], Error>(
    ['sf_product_types_to_statement', id],
    () =>
      BatchCarrierService.getProductTypesFromPropositionsBatchs(
        getAccessInfos().carrierGroupId,
        priceReporting?.validityStart ? new Date(priceReporting?.validityStart) : undefined,
        priceReporting?.validityEnd ? new Date(priceReporting?.validityEnd) : undefined,
        id,
      ),
    {
      keepPreviousData: true,
      enabled: id !== '' && priceReporting?.id !== undefined,
    },
  )

  useEffect(() => {
    if (productTypeId) {
      const productTypeFound = (productTypesToStatement || []).find((pt) => pt.id === productTypeId)
      setProductTypeInModification(productTypeFound)
    }
  }, [productTypeId, productTypesToStatement])

  const getIdFormatted = () => {
    if (priceReporting && priceReporting.id && priceReporting.id.length > 5) {
      return priceReporting.id.slice(-5)
    }
    return 'NA'
  }

  if (priceReportingLoading) {
    return <Loader isLoading pageLoading paddingLeft />
  }

  if (!priceReporting) return null

  const onChangeStatement = () => {
    refetchPriceReporting()
    setModificationSaved(true)
  }

  const statementsToComplete = (productTypesToStatement || []).filter(
    (pt) => !(priceReporting.priceStatements || []).find((ps) => ps.productType.id === pt.id),
  )

  const onFinishModification = () => {
    if (!(batchId && tourId)) return

    queryClient.invalidateQueries(['sf_batch', batchId])
    queryClient.invalidateQueries(['sf_tour', tourId])

    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [
          {
            name: 'CarrierTours',
            state: {
              index: 2,
              routes: [
                {
                  name: 'CarrierTour',
                  params: { id: tourId },
                },
                {
                  name: 'CarrierTourBatchs',
                  params: { id: tourId },
                },
                {
                  name: 'CarrierUpdateBatch',
                  params: { batchId: batchId, tourId: tourId },
                },
              ],
            },
          },
        ],
      }),
    )
  }

  const goBack = () => {
    if (!navigation) return
    if (batchId && tourId) {
      onFinishModification()
      return
    }
    if (navigation.canGoBack()) {
      navigation.goBack()
    }
  }

  const getPriceStatementNotHidden = () => {
    const priceStatements = priceReporting.priceStatements
    if (!priceStatements || priceStatements.length < 1) {
      return []
    }

    return priceStatements.filter((ps) => !ps.hidden)
  }

  const renderStatementsToComplete = () => {
    const filteredStatementsToComplete = statementsToComplete.filter(
      (pt) => pt.id !== productTypeId,
    )
    return filteredStatementsToComplete.map((productType) => (
      <PriceStatementCard
        key={productType.id}
        priceReporting={priceReporting}
        productTypePropositions={productType}
        onChangePriceStatement={() => onChangeStatement()}
      />
    ))
  }

  const getPriceStatementHidden = () => {
    const priceStatements = priceReporting.priceStatements
    if (!priceStatements || priceStatements.length < 1) {
      return []
    }

    return priceStatements.filter((ps) => ps.hidden)
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <StyledBackButtonWrapper>
          <StyledTouchableOpacity onPress={goBack}>
            <Icon
              type={Icons.Ionicons}
              name={'chevron-back-outline'}
              color={theme.colors['color-primary']}
              size={32}
            />
            <View>
              <Spacer size={1.4} axis={Spacer.AxisEnum.VERTICAL} />
              <Typography.BodySmall style={{ textAlign: 'center' }} colorName="text-dark-3">
                retour
              </Typography.BodySmall>
            </View>
          </StyledTouchableOpacity>
        </StyledBackButtonWrapper>
        <ToursContentWrapper>
          <PageTitle title={`Relevé de prix #${getIdFormatted()}`} subtitle={``} />
          <CarrierPriceReportingTopBox priceReporting={priceReporting} />
          {productTypeId ? (
            <>
              <SectionTitle title={`Produit en cours de modification`} />
              <PriceStatementCard
                key={productTypeInModification?.id}
                priceReporting={priceReporting}
                productTypePropositions={productTypeInModification}
                onChangePriceStatement={() => onChangeStatement()}
              />
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
              {modificationSaved && (
                <StyledButtonWrapper>
                  <Button
                    label={'Terminer la modification'}
                    onPress={() => onFinishModification()}
                    fullWidth
                  />
                </StyledButtonWrapper>
              )}
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
              <SectionTitle
                title={`Autres Produits à relever (${statementsToComplete?.length || 0})`}
              />
              {renderStatementsToComplete()}
            </>
          ) : (
            <>
              <SectionTitle
                title={`Produits avec un relevé de prix (${
                  getPriceStatementNotHidden().length || 0
                })`}
              />
              {getPriceStatementNotHidden().map((priceStatement) => {
                const productTypeFound = (productTypesToStatement || []).find(
                  (pt) => pt.id === priceStatement.productType.id,
                )
                const productType = productTypeFound || priceStatement.productType

                return (
                  <PriceStatementCard
                    key={priceStatement.id}
                    priceReporting={priceReporting}
                    priceStatement={priceStatement}
                    productTypePropositions={productType}
                    onChangePriceStatement={() => onChangeStatement()}
                  />
                )
              })}
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
              <SectionTitle
                title={`Produits à relever (${statementsToComplete?.length || 0})`}
                buttonRight={
                  <Button
                    small
                    colorName={'color-secondary'}
                    label={'Ajouter un type de produit'}
                    onPress={() => {
                      navigation.navigate('CarrierPriceReportingAddProductType', {
                        id: priceReporting.id,
                      })
                    }}
                  />
                }
              />
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />

              {renderStatementsToComplete()}

              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={5} />
              <SectionTitle
                title={`Produits à relever exclus (${getPriceStatementHidden().length || 0})`}
                buttonRight={
                  <Button
                    small
                    colorName={displayHiddenStatements ? 'color-grey' : 'color-secondary'}
                    label={displayHiddenStatements ? 'Cacher' : 'Afficher'}
                    onPress={() => setDisplayHiddenStatements(!displayHiddenStatements)}
                  />
                }
              />
              {displayHiddenStatements &&
                getPriceStatementHidden().map((priceStatement) => {
                  const productTypeFound = (productTypesToStatement || []).find(
                    (pt) => pt.id === priceStatement.productType.id,
                  )
                  const productType = productTypeFound || priceStatement.productType

                  return (
                    <PriceStatementCard
                      key={priceStatement.id}
                      priceReporting={priceReporting}
                      priceStatement={priceStatement}
                      productTypePropositions={productType}
                      onChangePriceStatement={() => onChangeStatement()}
                    />
                  )
                })}
            </>
          )}

          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={5} />
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierPriceReportingScreen
