import { ClientTypeEnum } from '../../../enums'
import { ClientStatusEnum } from '../../../enums/client'
import { Account } from '../Account'
import { Address } from '../Address'
import { CartBatch } from '../Cart'
import { ClientGroup } from '../Group'
import { Order } from '../Order'
import { User, UserClient } from '../User'

export type MailsObject = {
  mails: { label: number; value: number }[]
}

export type Client = {
  id: string
  label: string
  owners?: string
  createdDate?: string
  updatedDate?: string
  phone1?: string
  phone2?: string
  mail?: string
  mails?: MailsObject
  siret?: string
  addressLine1?: string
  addressLine2?: string
  postalCode?: string
  city?: string

  user?: User
  dateCreation?: string
  dateUpdate?: string
  nbOrders?: number
  sumCA?: number
  clientType?: ClientTypeEnum
  status?: ClientStatusEnum

  userClients?: UserClient[]
  labels?: string[]
  orders?: (Order | undefined)[]
  cartBatchs?: (CartBatch | undefined)[]

  account?: Account

  clientGroups?: ClientGroup[]
  deliveryAddress?: Address
  billingAddress?: Address
}

export type AddClient = {
  clientType?: ClientTypeEnum
  label?: string
  owners?: string
  phone1?: string
  phone2?: string
  mail?: string
  addressLine1?: string
  addressLine2?: string
  postalCode?: string
  city?: string
  labels?: string[]
}

export type UpdateClient = {
  id?: string
  clientType?: ClientTypeEnum
  label?: string
  owners?: string
  phone1?: string
  phone2?: string
  mail?: string
  addressLine1?: string
  addressLine2?: string
  postalCode?: string
  city?: string
  labels?: string[]
  deliveryAddress?: Address
  billingAddress?: Address
  account?: Account
  carrierFees?: string
}

export type AddClientTourInvite = {
  label: string
  email: string
  firstName?: string
  lastName?: string
}

export const ClientsFake: Client[] = []
