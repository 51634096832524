import { Batch, Group } from '../../domain'
import FormatUtils from './format'
import { InterfaceColors } from '../styling/theme/theme'
import { GroupEnums } from '../../../enums/group'
import { CarrierFeesEnums } from '../../../enums/carrierFees'

const getCarrierLabelFromGroupType = (
  groupType: GroupEnums.GroupTypeEnum | undefined,
): { label: string; value: CarrierFeesEnums.FeeSharingRuleEnum; description: string }[] => {
  switch (groupType) {
    case GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS:
      return [
        {
          label: `Les ${FormatUtils.getCarrierLabel(
            GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS,
            false,
            2,
          )} prennent le risque`,
          value: CarrierFeesEnums.FeeSharingRuleEnum.CARRIER_RESPONSABILITY,
          description: `Les ${FormatUtils.getCarrierLabel(
            GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS,
            false,
            2,
          )} prennent le risque, ils prélèvent un commission logistique qui couvrent leurs frais à partir d'un certain montant transporté.`,
        },
        {
          label: 'Les producteurs prennent le risque',
          value: CarrierFeesEnums.FeeSharingRuleEnum.PRODUCER_SHARED_RESPONSABILITY,
          description: `Les producteurs prennent le risque, ils se répartissent les frais réels de la tournée en fonction du chiffre d'affaire réalisé.`,
        },
      ]
    case GroupEnums.GroupTypeEnum.SUPER_PRODUCER:
      return [
        {
          label: 'Le super-producteur prend le risque',
          value: CarrierFeesEnums.FeeSharingRuleEnum.CARRIER_RESPONSABILITY,
          description: `Le ${FormatUtils.getCarrierLabel(
            GroupEnums.GroupTypeEnum.SUPER_PRODUCER,
            true,
          )} prennent le risque, ils prélèvent un commission logistique qui couvrent leurs frais à partir d'un certain montant transporté.`,
        },
        {
          label: 'Les producteurs prennent le risque',
          value: CarrierFeesEnums.FeeSharingRuleEnum.PRODUCER_SHARED_RESPONSABILITY,
          description: `Les producteurs prennent le risque, ils se répartissent les frais réels de la tournée en fonction du chiffre d'affaire réalisé.`,
        },
      ]
    default:
      return [
        {
          label: `Les ${FormatUtils.getCarrierLabel(
            GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS,
            false,
            2,
          )} prennent le risque`,
          value: CarrierFeesEnums.FeeSharingRuleEnum.CARRIER_RESPONSABILITY,
          description: `Les ${FormatUtils.getCarrierLabel(
            GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS,
            false,
            2,
          )} prennent le risque, ils prélèvent un commission logistique qui couvrent leurs frais à partir d'un certain montant transporté.`,
        },
        {
          label: 'Les producteurs prennent le risque',
          value: CarrierFeesEnums.FeeSharingRuleEnum.PRODUCER_SHARED_RESPONSABILITY,
          description: `Les producteurs prennent le risque, ils se répartissent les frais réels de la tournée en fonction du chiffre d'affaire réalisé.`,
        },
      ]
  }
}

const EnumLabelUtil = {
  getCarrierLabelFromGroupType,
}

export default EnumLabelUtil
