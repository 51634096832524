import React from 'react'
import { useTheme } from 'styled-components/native'
import { Icon, Icons, Spacer, TouchableCard, Typography } from '..'
import { CardPaymentMethodProps } from './CardPaymentMethod.model'
import {
  StyledCardContent,
  StyledCardWrapper,
  StyledLeftBox,
  StyledMiddleBox,
  StyledRightBox,
} from './CardPaymentMethod.styles'
import { View } from 'react-native'

const CardPaymentMethod = ({
  label,
  isFavorite,
  onClickFavorite,
  canEdit,
  onClickEdit,
  subtitle,
}: CardPaymentMethodProps) => {
  const theme = useTheme()

  const OnClickFavorite = () => {
    if (onClickFavorite && !isFavorite) {
      onClickFavorite()
    }
  }

  const OnClickEdit = () => {
    if (onClickEdit) {
      onClickEdit()
    }
  }

  return (
    <StyledCardWrapper activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent>
          <StyledMiddleBox>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Typography.CardTitle>{label}</Typography.CardTitle>
              <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
              <Icon
                type={Icons.Ionicons}
                name={isFavorite ? 'heart' : 'heart-outline'}
                size={theme.spacingUnit * 2}
                color={isFavorite ? theme.colors['color-primary'] : theme.colors['text-dark-3']}
                onPress={OnClickFavorite}
              />
            </View>

            {subtitle && (
              <>
                <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.BodySmall colorName="text-dark-3">{subtitle}</Typography.BodySmall>
              </>
            )}
          </StyledMiddleBox>
          <StyledRightBox>
            {canEdit ? (
              <Icon
                type={Icons.Ionicons}
                name={'pencil'}
                size={theme.spacingUnit * 2}
                color={theme.colors['text-dark-3']}
                onPress={OnClickEdit}
              />
            ) : null}
          </StyledRightBox>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default CardPaymentMethod
